import React, { useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import { useFeatureFlagEnabled } from "posthog-js/react";
import EnvironmentCreate from "./EnvironmentCreate";

const EnvironmentCreateWrapper: React.FC = () => {
  console.log('Route: /environment/new"');
  const isNewCreateInstancePage = useFeatureFlagEnabled(
    "new-create-instance-page"
  );

  return (
    // <>
    //   {isNewCreateInstancePage ? (
    <>
      <EnvironmentCreate />
    </>
    //   ) : (
    //     <>
    //       <LegacyCreate />
    //     </>
    //   )}
    // </>
  );
};

export default EnvironmentCreateWrapper;
