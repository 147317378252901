import React, { useState, useCallback } from "react";
import { ChevronDown, ChevronUp, MinusCircle } from "lucide-react";
import { Badge } from "components/Graphs/Badge";
import Spinner from "components/UI-lib/Spinner";
import { DeploymentLog } from "server";
import { useWindupString } from "windups";
import { FlatCard } from "components/UI-lib";

const MAX_MESSAGE_LENGTH = 100; // Adjust this value as needed

interface LogEntryProps {
  timestamp: string;
  message: string;
  isExpanded: boolean;
  onToggle: () => void;
}

const LogEntry: React.FC<LogEntryProps> = ({
  timestamp,
  message,
  isExpanded,
  onToggle,
}) => {
  const [isMessageExpanded, setIsMessageExpanded] = useState(false);

  const toggleMessage = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsMessageExpanded((prev) => !prev);
  }, []);

  const truncatedMessage =
    message.length > MAX_MESSAGE_LENGTH
      ? `${message.slice(0, MAX_MESSAGE_LENGTH)}...`
      : message;

  return (
    <div
      className="bg-secondaryBg text-secondary rounded-md border border-zinc-800 hover:border-highlight w-full cursor-pointer px-4 py-2 mb-2 transition-all duration-300 ease-in-out"
      onClick={onToggle}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <span className="text-sm">{timestamp}</span>
          <div className="ml-4 text-xs">{truncatedMessage}</div>
        </div>
        <div className="flex items-center space-x-2">
          {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </div>
      </div>
      <div className={`mt-2 ${isExpanded ? "" : "hidden"}`}>
        {isExpanded && (
          <div className="mt-2 text-xs text-secondary">{message}</div>
        )}
      </div>
    </div>
  );
};

interface LogsProps {
  deploymentLogs?: DeploymentLog[];
  isLoading: boolean;
}

const Logs: React.FC<LogsProps> = ({ deploymentLogs, isLoading }) => {
  const [expandedLogs, setExpandedLogs] = useState({});

  const toggleLog = useCallback((index) => {
    setExpandedLogs((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }, []);

  const collapseAll = useCallback(() => {
    setExpandedLogs({});
  }, []);

  const expandedCount = Object.values(expandedLogs).filter(Boolean).length;
  const parseLogOutput = (logEntry: string): string => {
    const stdoutIndex = logEntry.indexOf("stdout");

    if (stdoutIndex !== -1) {
      // If "stdout" is found, return everything after it (trimming any leading whitespace)
      return logEntry.slice(stdoutIndex + 6).trim();
    } else {
      // If "stdout" is not found, return the full log entry
      return logEntry.trim();
    }
  };
  const [text] = useWindupString("Loading Logs...");

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <h2 className="text-xl font-semibold text-white">Logs</h2>
        </div>
        {expandedCount > 1 && (
          <button
            onClick={collapseAll}
            className="flex items-center space-x-2 text-highlight hover:text-cyan-400 transition-colors duration-200"
          >
            <MinusCircle size={16} />
            <span>Collapse All</span>
          </button>
        )}
      </div>
      {isLoading && (
        <div className="flex flex-row text-highlight">
          <Spinner type="primary" />
          <div>{text}</div>
        </div>
      )}
      {!isLoading && deploymentLogs && deploymentLogs.length <= 0 && (
        <FlatCard className="mt-2 mb-2" isLoading={false}>
          <p className="text-sm text-amber-700 dark:text-amber-500 font-mono text-center">
            No logs found
          </p>
        </FlatCard>
      )}
      <div className="space-y-2">
        {deploymentLogs &&
          deploymentLogs.map((log, index) => (
            <LogEntry
              key={index}
              timestamp={log.timestamp || ""}
              message={parseLogOutput(log.message || "")}
              isExpanded={!!expandedLogs[index]}
              onToggle={() => toggleLog(index)}
            />
          ))}
      </div>
    </>
  );
};

export default Logs;
