import { cx, focusRing } from "components/Graphs/utils";
import { useHistory, useLocation } from "react-router-dom"; // Add this import at the top

import React from "react";
import {
  HomeIcon,
  UserIcon,
  BuildingOfficeIcon,
  CpuChipIcon,
  CreditCardIcon,
  LinkIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";

const navigation = [
  { name: "Overview", href: "/admin", icon: HomeIcon },
  { name: "Users", href: "/admin/users", icon: UserIcon },
  {
    name: "Organizations",
    href: "/admin/organizations",
    icon: BuildingOfficeIcon,
  },
  {
    name: "Instances",
    href: "/admin/instances",
    icon: CpuChipIcon,
  },
  {
    name: "Launchables",
    href: "/admin/launchables",
    icon: RocketLaunchIcon,
  },
  {
    name: "Credits",
    href: "/admin/credits",
    icon: CreditCardIcon,
  },
] as const;

const shortcuts = [
  {
    name: "Add Credits to an Org",
    href: "/admin/credits#add-credits-to-org",
    icon: LinkIcon,
  },
  {
    name: "Add DGXC to an Org",
    href: "/admin/organizations#add-dgxc-to-org",
    icon: LinkIcon,
  },
  // {
  //   name: "Stop an Instance By ID",
  //   href: "#",
  //   icon: LinkIcon,
  // },
  // {
  //   name: "Add DGX Cloud to an Org",
  //   href: "#",
  //   icon: LinkIcon,
  // },
] as const;

const Sidebar: React.FC = () => {
  const { pathname } = useLocation(); // Add this hook
  const history = useHistory();

  const isActive = (href: string) => {
    if (href === "/admin") {
      return pathname === href;
    }
    // For other paths, check if the pathname starts with href
    return pathname.startsWith(href);
  };
  return (
    <>
      {/* sidebar (lg+) */}
      <nav className=" lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col lg:top-[64px]">
        <aside className="flex grow flex-col gap-y-6 overflow-y-auto border-r border-t border-zinc-800 bg-black p-4 ">
          <nav
            aria-label="core navigation links"
            className="flex flex-1 flex-col space-y-10"
          >
            <ul role="list" className="space-y-0.5">
              {navigation.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => history.push(item.href)}
                    className={cx(
                      isActive(item.href)
                        ? "text-highlight"
                        : "text-white hover:text-highlight",
                      "flex w-full items-center gap-x-2.5 rounded-md px-2 py-1.5 text-sm font-medium transition hover:bg-zinc-800",
                      focusRing
                    )}
                  >
                    <item.icon className="size-4 shrink-0" aria-hidden="true" />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
            <div>
              <span className="text-xs font-medium leading-6 text-secondary">
                Shortcuts
              </span>
              <ul aria-label="shortcuts" role="list" className="space-y-0.5">
                {shortcuts.map((item) => (
                  <li key={item.name}>
                    <button
                      onClick={() => history.push(item.href)}
                      className={cx(
                        isActive(item.href)
                          ? "text-highlight"
                          : "text-[#8f8f8f]",
                        "flex w-full items-center gap-x-2.5 rounded-md px-2 py-1.5 text-sm font-medium transition hover:bg-zinc-800",
                        focusRing
                      )}
                    >
                      <item.icon
                        className="size-4 shrink-0"
                        aria-hidden="true"
                      />
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </aside>
      </nav>
    </>
  );
};

export default Sidebar;
