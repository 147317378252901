import { Input } from "components/Graphs/Input";
import React, { useState } from "react";
import { SearchRes, SearchResponseStruct } from "server";
import agent from "server";
import { Spinner } from "@kui-react/spinner";
import { Button, FlatCard } from "components/UI-lib";
import {
  BuildingOfficeIcon,
  CpuChipIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { or } from "fp-ts/lib/Predicate";
import { useHistory } from "react-router";

const Search: React.FC = () => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<SearchResponseStruct | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    const res = await agent.Admin.search({
      query: search.trim(),
    });
    if (res.success) {
      setResults(res.data);
    } else {
      setResults(null);
      setError(res.message);
    }
    setLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (search.trim() !== "") {
        handleSearch();
      }
    }
  };

  return (
    <div className="flex flex-col w-full pt-5 px-24">
      <Input
        placeholder="Search orgs, users, or instances"
        id="search"
        name="search"
        type="search"
        className="mt-2"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onKeyDown={handleKeyDown}
      />
      {loading && (
        <FlatCard className="mt-5">
          <div className="flex justify-center items-center w-full">
            <Spinner className="w-12 h-12" />
          </div>
        </FlatCard>
      )}
      {error && (
        <FlatCard className="mt-5">
          <div className="flex justify-center items-center w-full">
            <p className="text-red-500">{error}</p>
          </div>
        </FlatCard>
      )}

      {results && !loading && (
        <FlatCard className="mt-5">
          <>
            <div className="flex flex-row justify-between">
              <div className="mb-6 text-white">
                <h1 className="text-xl font-bold">Search Results:</h1>
              </div>
              <div className="mb-6">
                <XMarkIcon
                  className="w-6 h-6 text-red-500 hover:text-red-700 cursor-pointer"
                  onClick={() => setResults(null)}
                />
              </div>
            </div>
            <div className="space-y-4">
              {/* Users Section */}
              {results.users && results.users.length > 0 && (
                <div>
                  <div className="flex flex-row items-center mb-2 text-white">
                    <UserIcon className="w-4 h-4 mr-1" />
                    <h3 className="text-white text-md font-semibold">Users</h3>
                  </div>
                  <div className="mt-2">
                    <TableRoot>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeaderCell>ID</TableHeaderCell>
                            <TableHeaderCell>Email</TableHeaderCell>
                            <TableHeaderCell>ExternalAuthId</TableHeaderCell>
                            <TableHeaderCell>Orgs</TableHeaderCell>
                            <TableHeaderCell>Instances</TableHeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {results.users.map((user, index) => (
                            <TableRow
                              key={index}
                              className="cursor-pointer hover:bg-zinc-800"
                              onClick={() => {
                                history.push(`/admin/users/id/${user.id}`);
                              }}
                            >
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary ">
                                  {user.id}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {user.Email}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {user.ExternalAuthID}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  className="text-sm text-highlight underline cursor-pointer hover:text-highlightLighter"
                                  onClick={() => {
                                    history.push(`/admin/users/id/${user.id}`);
                                  }}
                                >
                                  View Orgs
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  className="text-sm text-highlight underline cursor-pointer hover:text-highlightLighter"
                                  onClick={() => {
                                    history.push(`/admin/users/id/${user.id}`);
                                  }}
                                >
                                  View Instances
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableRoot>
                  </div>
                </div>
              )}
              {/* Organizations Section */}
              {results.organizations && results.organizations.length > 0 && (
                <div>
                  <div className="flex flex-row items-center mb-2 text-white">
                    <BuildingOfficeIcon className="w-4 h-4 mr-1" />
                    <h3 className="text-white text-md font-semibold">
                      Organizations
                    </h3>
                  </div>
                  <div className="mt-2">
                    <TableRoot>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeaderCell>ID</TableHeaderCell>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Members</TableHeaderCell>
                            <TableHeaderCell>Instances</TableHeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {results.organizations.map((organization, index) => (
                            <TableRow
                              key={index}
                              className="cursor-pointer hover:bg-zinc-800"
                              onClick={() => {
                                history.push(
                                  `/admin/organizations/id/${organization.id}`
                                );
                              }}
                            >
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary ">
                                  {organization.id}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {organization.Name}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  className="text-sm text-highlight underline cursor-pointer hover:text-highlightLighter"
                                  onClick={() => {
                                    history.push(
                                      `/admin/organizations/id/${organization.id}`
                                    );
                                  }}
                                >
                                  View Members
                                </span>
                              </TableCell>
                              <TableCell>
                                <span
                                  className="text-sm text-highlight underline cursor-pointer hover:text-highlightLighter"
                                  onClick={() => {
                                    history.push(
                                      `/admin/organizations/id/${organization.id}`
                                    );
                                  }}
                                >
                                  View Instances
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableRoot>
                  </div>
                </div>
              )}
              {results.workspaces && results.workspaces.length > 0 && (
                <div>
                  <div className="flex flex-row items-center mb-2 text-white">
                    <CpuChipIcon className="w-4 h-4 mr-1" />
                    <h3 className="text-white text-md font-semibold">
                      Instances
                    </h3>
                  </div>
                  <div className="mt-2">
                    <TableRoot>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeaderCell>ID</TableHeaderCell>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Compute</TableHeaderCell>
                            <TableHeaderCell>Container</TableHeaderCell>
                            <TableHeaderCell>Compute Status</TableHeaderCell>
                            <TableHeaderCell>Container Status</TableHeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {results.workspaces.map((instance, index) => (
                            <TableRow
                              key={index}
                              className="cursor-pointer hover:bg-zinc-800"
                              onClick={() => {
                                history.push(
                                  `/admin/instances/id/${instance.id}`
                                );
                              }}
                            >
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary ">
                                  {instance.id}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {instance.name}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {instance.instanceType}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {instance.baseImage ||
                                    instance.customContainer?.containerUrl ||
                                    (instance.vmOnlyMode ? "VM" : "Verb")}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {instance.status}
                                </span>
                              </TableCell>
                              <TableCell>
                                <span className="text-sm text-gray-500 dark:text-secondary">
                                  {instance.verbBuildStatus}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableRoot>
                  </div>
                </div>
              )}

              {/* {results.errors.length > 0 && (
                <div>
                  <h3 className="text-md font-semibold text-red-600 mb-2">
                    Errors
                  </h3>
                  <div className="space-y-2">
                    {results.errors.map((error, index) => (
                      <div
                        key={index}
                        className="p-3 bg-red-50 text-red-700 rounded"
                      >
                        {error}
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
              {/* No Results Message */}
              {results.users.length === 0 &&
                results.organizations.length === 0 &&
                (!results.workspaces ||
                  (results.workspaces && results.workspaces.length === 0)) &&
                (!results.errors || results.errors.length === 0) && (
                  <div className="text-center text-gray-500">
                    No results found
                  </div>
                )}
            </div>
          </>
        </FlatCard>
      )}
    </div>
  );
};

export default Search;
