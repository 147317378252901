import React, { KeyboardEvent } from "react";
import { motion } from "framer-motion";
import { Button } from "components/Graphs/Button";
import { ArrowUpIcon } from "@heroicons/react/24/solid";

interface ChatInputProps {
  input: string;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  disabled: boolean;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  input,
  handleInputChange,
  handleSubmit,
  disabled,
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const form = e.currentTarget.form;
      if (form) {
        handleSubmit(
          new Event("submit") as unknown as React.FormEvent<HTMLFormElement>
        );
      }
    }
  };
  const handleInput = (e) => {
    if (disabled) return;
    // Reset height to auto to get the correct scrollHeight
    e.target.style.height = "auto";
    // Set the height to match the content
    e.target.style.height = e.target.scrollHeight + "px";
  };

  return (
    <>
      <motion.div
        initial={{ y: 100 }}
        animate={{ y: 0 }}
        className={`border-zinc-800 bg-white border shadow-md p-4 rounded-lg max-w-3xl mx-auto ${
          disabled ? "opacity-50" : ""
        }`}
      >
        <form onSubmit={handleSubmit} className="flex items-center">
          <textarea
            className="flex min-h-[60px] max-h-[800px] max-w-[800px] w-full bg-background px-3 py-2 text-base placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 md:text-sm border-none outline-none focus:outline-none focus:ring-0 resize-none"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onInput={handleInput}
            placeholder="Type your message..."
            disabled={disabled}
            aria-disabled={disabled}
          />
          <Button
            type="submit"
            disabled={disabled || !input.trim()}
            className="bg-highlight ml-1"
            variant="primary"
          >
            <ArrowUpIcon className="h-4 w-4" />
          </Button>
        </form>
      </motion.div>
    </>
  );
};
