import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import "../style.scss";
import { isStoppableInstance } from "../utils";
import { BackendSelected, ModelSelected } from "../DeploymentCreate";
import { DeploymentInstanceType, Gpu } from "server";
import { FlatCard } from "components/UI-lib";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import Chip from "components/Environment/Create/chips";
import { classNames } from "components/utils";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/Graphs/Tabs";

export interface BackendSelectorProps {
  setSelectedBackend(selectedBackend: BackendSelected | undefined): void;
  selectedBackend: BackendSelected | undefined;
  instanceTypes: DeploymentInstanceType[];
  allInstanceTypes: DeploymentInstanceType[];
}

const BackendSelector: React.FC<BackendSelectorProps> = ({
  selectedBackend,
  setSelectedBackend,
  instanceTypes,
  allInstanceTypes,
}) => {
  const preferenceList = ["H100", "A100", "L40G"];

  const groupInstancesByGpuWithPreference = (
    instances: DeploymentInstanceType[],
    preferenceList: string[]
  ): DeploymentInstanceType[][] => {
    // Create a Map to store instances grouped by GPU name
    const gpuGroups = new Map();

    // Group instances by GPU name
    instances.forEach((instance) => {
      let gpu: Gpu | null = null;
      if (instance.supported_gpus) {
        gpu = instance.supported_gpus[0];
      }
      const gpuName = gpu?.name || "Unknown";
      if (!gpuGroups.has(gpuName)) {
        gpuGroups.set(gpuName, []);
      }
      gpuGroups.get(gpuName).push(instance);
    });

    // Convert the Map to an array of arrays
    let result = Array.from(gpuGroups.entries()).map(([gpuName, instances]) => [
      ...instances,
    ]);

    // Sort the groups based on the preference list
    result.sort((a: DeploymentInstanceType[], b: DeploymentInstanceType[]) => {
      const indexA = preferenceList.indexOf(
        a[0].supported_gpus ? a[0].supported_gpus[0]?.name || "" : ""
      );
      const indexB = preferenceList.indexOf(
        b[0].supported_gpus ? b[0].supported_gpus[0]?.name || "" : ""
      );

      if (indexA === -1 && indexB === -1) return 0; // Both not in preference list
      if (indexA === -1) return 1; // A not in preference list, B is
      if (indexB === -1) return -1; // B not in preference list, A is
      return indexA - indexB; // Both in preference list, sort by index
    });
    console.log(result);
    return result;
  };

  // Group all instance types for showing all tabs
  const allInstanceTypeGrouping = useMemo(() => {
    if (!allInstanceTypes || allInstanceTypes.length <= 0) {
      return [];
    }
    return groupInstancesByGpuWithPreference(allInstanceTypes, preferenceList);
  }, [allInstanceTypes]);

  // Group filtered instances for content
  const filteredInstanceTypeGrouping = useMemo(() => {
    if (!instanceTypes || instanceTypes.length <= 0) {
      return [];
    }
    return groupInstancesByGpuWithPreference(instanceTypes, preferenceList);
  }, [instanceTypes]);

  // Create a map of GPU names to their filtered status
  const gpuCompatibilityMap = useMemo(() => {
    const map = new Map<string, boolean>();

    allInstanceTypeGrouping.forEach((group) => {
      const gpuName = group[0]?.supported_gpus?.[0]?.name || "default";
      const hasCompatibleInstances = filteredInstanceTypeGrouping.some(
        (filteredGroup) =>
          filteredGroup[0]?.supported_gpus?.[0]?.name === gpuName
      );
      map.set(gpuName, hasCompatibleInstances);
    });

    return map;
  }, [allInstanceTypeGrouping, filteredInstanceTypeGrouping]);

  const [activeTab, setActiveTab] = useState<string>(() => {
    // Find first compatible GPU to set as initial active tab
    const firstCompatibleGpu = Array.from(gpuCompatibilityMap.entries()).find(
      ([_, isCompatible]) => isCompatible
    );
    return (
      firstCompatibleGpu?.[0] ||
      allInstanceTypeGrouping[0]?.[0]?.supported_gpus?.[0]?.name ||
      "default"
    );
  });

  return (
    <>
      <div className="flex flex-col">
        <AnimatePresence initial={false}>
          {!selectedBackend && (
            <motion.section
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 0.8,
                ease: [0.04, 0.62, 0.23, 0.98],
              }}
            >
              <motion.div
                variants={{
                  collapsed: { scale: 0.8 },
                  open: { scale: 1 },
                }}
                transition={{ duration: 0.8 }}
                className="content-placeholder"
              >
                <>
                  <div className="flex flex-col">
                    <h1 className="text-xl font-bold text-gray-900 dark:text-slate-100">
                      Select your Backend
                    </h1>
                    <p className="text-secondary text-xs">
                      (Filtered for Model Compatibile GPUs)
                    </p>
                  </div>

                  <div className="mt-6">
                    <Tabs
                      value={activeTab}
                      onValueChange={(val) => setActiveTab(val)}
                    >
                      <TabsList variant="line" className="mb-4">
                        {allInstanceTypeGrouping.map((group) => {
                          const gpuName =
                            group[0]?.supported_gpus?.[0]?.name || "default";
                          const isCompatible = gpuCompatibilityMap.get(gpuName);

                          return (
                            <TabsTrigger
                              key={gpuName}
                              value={gpuName}
                              disabled={!isCompatible}
                              className={
                                !isCompatible
                                  ? "opacity-50 cursor-not-allowed"
                                  : ""
                              }
                            >
                              <div className="flex flex-row items-center">
                                <svg
                                  width="24"
                                  height="16"
                                  viewBox="0 0 249 165"
                                  className="mr-1"
                                  fill="#76B900"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
                                </svg>
                                <div className="flex items-center">
                                  NVIDIA {gpuName}
                                  {!isCompatible && (
                                    <span className="ml-2 text-xs text-red-500">
                                      (Not Compatible)
                                    </span>
                                  )}
                                </div>
                              </div>
                            </TabsTrigger>
                          );
                        })}
                      </TabsList>

                      {filteredInstanceTypeGrouping.map((instanceTypeList) => (
                        <TabsContent
                          key={instanceTypeList[0]?.supported_gpus?.[0]?.name}
                          value={
                            instanceTypeList[0]?.supported_gpus?.[0]?.name ||
                            "default"
                          }
                        >
                          <div className="flex flex-col space-y-4">
                            {instanceTypeList.map(
                              (instanceType: DeploymentInstanceType) => (
                                <div className="flex" key={instanceType.type}>
                                  <FlatCard
                                    className="animate-border cursor-pointer w-full"
                                    onClick={() => {
                                      setSelectedBackend({
                                        instanceType: instanceType,
                                      });
                                    }}
                                  >
                                    <div className="flex flex-col">
                                      <div className="flex flex-row items-center justify-between">
                                        <div className="flex">
                                          <div className="flex flex-row">
                                            <Chip
                                              selected={true}
                                              chipName={
                                                (instanceTypeList[0]
                                                  .supported_gpus &&
                                                  instanceTypeList[0]
                                                    .supported_gpus[0].name) ||
                                                ""
                                              }
                                              manufacturerName={
                                                (instanceTypeList[0]
                                                  .supported_gpus &&
                                                  instanceTypeList[0]
                                                    .supported_gpus[0]
                                                    .manufacturer) ||
                                                ""
                                              }
                                              onClick={() => {}}
                                              className={classNames(
                                                "h-[45px] mr-2 flex-shrink-0"
                                              )}
                                            />
                                            <div className="flex items-center">
                                              {instanceType.supported_gpus && (
                                                <div className="text-md text-gray-900 dark:text-slate-100">
                                                  {
                                                    instanceType
                                                      .supported_gpus[0].count
                                                  }
                                                  x{" "}
                                                  {
                                                    instanceType
                                                      .supported_gpus[0]
                                                      .manufacturer
                                                  }{" "}
                                                  {
                                                    instanceType
                                                      .supported_gpus[0].name
                                                  }
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="flex flex-row items-center text-gray-900 dark:text-secondary mt-1">
                                          <div className="flex flex-row items-center">
                                            {instanceType.location}
                                          </div>
                                          <div className="flex flex-row items-center ml-2">
                                            <PlayCircleIcon className="h-6 w-6 mr-1" />
                                            $0.04/per invocation
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <span className="btnBefore"></span>
                                    <span className="btnAfter"></span>
                                  </FlatCard>
                                </div>
                              )
                            )}
                          </div>
                        </TabsContent>
                      ))}
                    </Tabs>
                  </div>
                </>
              </motion.div>
            </motion.section>
          )}
          {!!selectedBackend && (
            <>
              <BackendPreview
                backend={selectedBackend}
                setSelectedBackend={setSelectedBackend}
              />
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default BackendSelector;

interface BackendPreviewProps {
  backend: BackendSelected;
  setSelectedBackend(selectedBackend: BackendSelected | undefined): void;
}
export const BackendPreview: React.FC<BackendPreviewProps> = ({
  backend,
  setSelectedBackend,
}) => {
  return (
    <div className="flex">
      <FlatCard
        key={backend.instanceType.type}
        className={`animate-border cursor-pointer`}
        onClick={() => {
          setSelectedBackend(undefined);
        }}
      >
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <div className="flex">
              <div className="flex flex-row">
                <Chip
                  selected={true}
                  chipName={
                    (backend.instanceType.supported_gpus &&
                      backend.instanceType.supported_gpus[0].name) ||
                    ""
                  }
                  manufacturerName={
                    (backend.instanceType.supported_gpus &&
                      backend.instanceType.supported_gpus[0].manufacturer) ||
                    ""
                  }
                  onClick={() => {}}
                  className={classNames("h-[50px] mr-2 flex-shrink-0")}
                />
                <div className="flex items-center">
                  {backend.instanceType.supported_gpus && (
                    <div className="text-md text-gray-900 dark:text-slate-100">
                      {backend.instanceType.supported_gpus[0].count}x{" "}
                      {backend.instanceType.supported_gpus[0].manufacturer}{" "}
                      {backend.instanceType.supported_gpus[0].name}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center text-gray-900 dark:text-secondary mt-1">
              <div className="flex flex-row items-center">
                {backend.instanceType.location}
              </div>
              <div className="flex flex-row items-center ml-2">
                <PlayCircleIcon className="h-6 w-6 mr-1" />
                $0.04/per invocation
              </div>
            </div>
          </div>
        </div>
        <span className="btnBefore"></span>
        <span className="btnAfter"></span>
      </FlatCard>
    </div>
  );
};
