import InputField from "components/UI-lib/InputField";
import { OrgContext } from "contexts/OrgContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Collapse } from "@mui/material";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { isValidFileUrl } from "components/utils";

interface NotebookUploaderProps {
  file: string;
  setFile: (file: string) => void;
  isFileValid: boolean;
  setIsFileValid: (isFileValid: boolean) => void;
}

const NotebookUploader: React.FC<NotebookUploaderProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showExamples, setShowExamples] = useState<boolean>(false);

  const handleInputChange = async (value: string) => {
    props.setIsFileValid(true);
    props.setFile(value);
    if (value.trim() === "") {
      setErrorMessage("");
      return;
    }
    const validationError = await isValidFileUrl(value);
    setErrorMessage(validationError || "");
    props.setIsFileValid(!validationError);
  };

  return (
    <>
      <div className="mb-3">
        <h1
          className="text-xl font-semibold text-gray-900 dark:text-white mb-1"
          style={{
            fontSize: "1.875rem",
          }}
        >
          Add files
        </h1>
        <p className="text-md mt-2 text-gray-700 dark:text-secondary">
          Add a Github/Gitlab repository or jupyter notebook to your launchable
        </p>
        <p className="text-sm text-gray-700 dark:text-secondary mt-2">
          (You'll be able to access a JupyterLab regardless if you upload a file
          or not)
        </p>
        <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
        <div className="mt-5">
          <InputField
            errorMessage={errorMessage}
            label="Enter Github/Gitlab URL"
            placeholder="https://..."
            value={props.file}
            onChange={(value) => handleInputChange(value)}
          />
        </div>
        <div>
          <div
            className="flex flex-row cursor-pointer pt-2"
            onClick={() => setShowExamples(!showExamples)}
          >
            <span className="text-sm text-highlight font-semibold hover:text-highlightLighter mr-1">
              Show Examples
            </span>
            {showExamples ? (
              <ChevronDownIcon width={18} />
            ) : (
              <ChevronRightIcon width={18} />
            )}
          </div>
          <div>
            <Collapse
              in={showExamples}
              sx={{
                maxHeight: "500px",
              }}
            >
              <div className="pt-0 pb-0">
                <div className="flex flex-row items-center mt-2">
                  <ul>
                    <li>
                      <span className="text-sm text-gray-700 dark:text-secondary">
                        Github Repo:
                        <a
                          href="https://github.com/brevdev/notebooks.git"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleInputChange(e.currentTarget.href);
                          }}
                        >
                          https://github.com/brevdev/notebooks.git
                        </a>
                      </span>
                    </li>
                    <li>
                      <span className="text-sm text-gray-700 dark:text-secondary">
                        Notebook URL:{" "}
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleInputChange(e.currentTarget.href);
                          }}
                          href="https://github.com/brevdev/notebooks/blob/main/llama3_finetune_inference.ipynb"
                        >
                          https://github.com/brevdev/notebooks/blob/main/llama3_finetune_inference.ipynb
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotebookUploader;
