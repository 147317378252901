import React, { useContext, useEffect, useState } from "react";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import FlatCard from "components/UI-lib/FlatCard";
import VRamFilter from "./VramFilter";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { OrgContext } from "contexts/OrgContext";
import { memoryToGiB } from "components/utils";
import { FilterModal } from "./Modal/FilterModal";
import CloudFilter from "./CloudFilter";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";
import { UserContext } from "contexts/UserContext";
import { isNvidiaEmail } from "utils";

interface FilterBarProps {
  displayGPUInstances: GPUInstanceType[];
  setDisplayGPUInstances: (instances: GPUInstanceType[]) => void;
  containerSelected: ContainerSelected;
  setSelectedCloud: (cloudName: string) => void;
  selectedCloud: string;
}

const FilterBar: React.FC<FilterBarProps> = ({
  displayGPUInstances,
  setDisplayGPUInstances,
  containerSelected,
  selectedCloud,
  setSelectedCloud,
}) => {
  const orgContext = useContext(OrgContext);
  const userContext = useContext(UserContext);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  // Attributes
  const [selectedVRam, setSelectedVRam] = useState<string>("any");
  const [selectedGPUCount, setSelectedGPUCount] = useState<string>("any");
  const [selectMinRam, setSelectMinRam] = useState<string>("any");
  const [selectedMinCPUCount, setSelectedMinCPUCount] = useState<number>(0);
  const [priceRange, setPriceRange] = useState<number[]>([0, 100]);
  const [filterCount, setFilterCount] = useState<number>(0);
  const [selectedProvider, setSelectedProvider] = useState<string>("any");

  useEffect(() => {
    let filterCount = 0;
    if (!orgContext.GPUInstancesAvailable) return;
    let filteredInstances = orgContext.GPUInstancesAvailable;
    if (selectedVRam !== "any") {
      filteredInstances = filteredInstances.filter(
        (instance) =>
          memoryToGiB(instance.supported_gpus[0].memory) >=
          memoryToGiB(selectedVRam)
      );
    }
    if (selectedGPUCount != "any") {
      filteredInstances = filteredInstances.filter((instance) => {
        if (selectedGPUCount !== "8+") {
          return instance.supported_gpus[0].count === Number(selectedGPUCount);
        } else {
          return instance.supported_gpus[0].count >= 8;
        }
      });
      filterCount++;
    }
    if (selectMinRam !== "any") {
      filteredInstances = filteredInstances.filter(
        (instance) =>
          memoryToGiB(String(instance.memory)) >= memoryToGiB(selectMinRam)
      );
      filterCount++;
    }
    if (selectedMinCPUCount > 0) {
      filteredInstances = filteredInstances.filter(
        (instance) => instance.vcpu >= selectedMinCPUCount
      );
      filterCount++;
    }
    if (priceRange[0] !== 0 || priceRange[1] !== 100) {
      filteredInstances = filteredInstances.filter(
        (instance) =>
          Number(instance.base_price?.amount || 100) <= priceRange[1] &&
          Number(instance.base_price?.amount || 0) >= priceRange[0]
      );
      filterCount++;
    }
    if (selectedCloud === "Brev Cloud") {
      filteredInstances = filteredInstances.filter((instance) =>
        instance.workspace_groups.some((group) => group.tenantType === "shared")
      );
      if (selectedProvider !== "any") {
        filteredInstances = filteredInstances.filter((instance) =>
          instance.workspace_groups.some(
            (group) => group.platformType === selectedProvider
          )
        );
      }
    } else {
      filteredInstances = filteredInstances.filter((instance) =>
        instance.workspace_groups.some((group) => group.id === selectedCloud)
      );
    }

    //Filter out akash
    filteredInstances = filteredInstances.filter(
      (instance) => instance.workspace_groups[0].platformType !== "akash"
    );

    setFilterCount(filterCount);
    setDisplayGPUInstances(filteredInstances);
  }, [
    selectedVRam,
    selectedGPUCount,
    selectMinRam,
    selectedMinCPUCount,
    priceRange,
    selectedCloud,
    selectedProvider,
    orgContext.GPUInstancesAvailable,
    userContext.me,
  ]);

  return (
    <div className="overflow-auto">
      <FilterModal
        show={openFiltersModal}
        setShow={setOpenFiltersModal}
        selectedNumberOfGPUs={selectedGPUCount}
        setSelectedNumberOfGPUs={setSelectedGPUCount}
        selectedMinimumNumberOfCPUs={selectedMinCPUCount}
        setSelectedMinimumNumberOfCPUs={setSelectedMinCPUCount}
        selectMinRam={selectMinRam}
        setSelectMinRam={setSelectMinRam}
        priceRange={priceRange}
        setPriceRange={setPriceRange}
      />
      <div className="flex flex-row justify-center">
        <VRamFilter
          selectedVRam={selectedVRam}
          setSelectedVRam={setSelectedVRam}
        />
        <CloudFilter
          selectedCloud={selectedCloud}
          setSelectedCloud={setSelectedCloud}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          containerSelected={containerSelected}
        />
        <div className="flex ml-2 relative mr-2 animate-border rounded-md">
          <span className="absolute top-[-6px] right-[-6px] text-white z-50">
            {filterCount !== 0 && (
              <div className="bg-highlight w-5 h-5 rounded-md flex flex-col justify-center items-center">
                <span>{filterCount}</span>
              </div>
            )}
          </span>
          <div
            className="flex relative bg-primaryBg py-2 px-6 min-h-[46px] max-h-[46px] items-center rounded-md border border-zinc-800 cursor-pointer"
            onClick={() => setOpenFiltersModal(true)}
          >
            <div className="flex flex-row items-center w-full h-full">
              <div className="flex flex-col">
                <div className="flex flex-row items-center">
                  <AdjustmentsHorizontalIcon className="h-6 w-6 text-gray-900 dark:text-slate-100 mr-1" />
                  <h1 className="text-sm text-gray-900 dark:text-slate-100">
                    Filters
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <span className="btnBefore"></span>
          <span className="btnAfter"></span>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
