import React, { useContext, useEffect, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import { allowedModelList } from "components/Deployments/Create/utils";
import AllowedModelListSelector from "./AllowedModelListSelector";
import { ModelSelected } from "../DeploymentCreate";
import { Button, InputField, FlatCard } from "components/UI-lib";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import agent, {
  Deployment,
  DeploymentInstanceType,
  DeploymentModelProvider,
  Model,
} from "server";
import hfLogo from "assets/img/svg/hf-logo.svg";
import ModelList from "./ModelList";
import { Spinner } from "@kui-react/spinner";

export interface ModelSelectorProps {
  allowedModelList: Model[];
  isLoadingModels: boolean;
  setModel: (modelSelected: ModelSelected) => void;
  setReset: (isReset: boolean) => void;
}

const ModelSelector: React.FC<ModelSelectorProps> = ({
  allowedModelList,
  isLoadingModels,
  setModel,
  setReset,
}) => {
  const orgContext = useContext(OrgContext);

  useEffect(() => {
    setReset(false);
  }, []);

  return (
    <div className="w-full">
      <h2 className="text-white text-xl font-semibold mb-4">Select a Model</h2>
      {isLoadingModels ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <>
          <ModelList
            models={allowedModelList}
            onModelSelect={(model) => {
              setModel({
                modelName: model.model_name,
                modelProvider: DeploymentModelProvider.UNSPECIFIED,
                modelId: model.model_id,
                modelHardwareRequirements: model.hardware_requirements,
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default ModelSelector;
