import {
  getCustomDirectoryFromAbsolutePathName,
  getRepoProjectName,
  parseGitUrlToCloneFormat,
} from "components/utils";
import { JUPYTER_TEMPLATE_URL, RSTUDIO_TEMPLATE_URL } from "constants/index";
import { Capability } from "contexts/OrgContext";
import { IExec, IExecOptions, IExecV0 } from "models/Exec.model";
import { IGitRepo, IRepo, IRepoV0 } from "models/Repo.model";
import { IdeConfig } from "models/User.model";
import IWorkspace, {
  HealthStatus,
  IFileObject,
  ITunnel,
  VerbBuildStatus,
  WorkspaceClassId,
  WorkspaceStatus,
  HealthCheck,
} from "models/Workspace.model";
import IWorkspaceTemplate from "models/WorkspaceTemplate.model";
import PathExec from "./PathExec.entity";
import StringExec from "./StringExec.entity";
import {
  CustomContainer,
  DockerCompose,
} from "components/Environment/shared/BuildTypes";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";

export default class Workspace implements IWorkspace {
  public id: string;

  public workspaceGroupId: string;

  public workspaceTemplate: IWorkspaceTemplate;

  public organizationId: string;

  public workspaceClassId: WorkspaceClassId;

  public statusMessage: string;

  public name: string;

  public createdByUserId: string;

  public status: WorkspaceStatus;

  public dns: string;

  public password: string;

  public version: string;

  public isStoppable: boolean;

  public instanceType?: string;

  public instanceTypeInfo?: GPUInstanceType;

  public healthStatus: HealthStatus;

  public createdAt: string;

  public updatedAt: string;

  public lastOnlineAt: string;

  public gitRepo?: string;

  public diskStorage?: string;

  public architecture?: string;

  public image?: string;

  public region?: string;

  public startupScriptPath?: string;

  public ideConfig?: IdeConfig;

  public repos?: { [key: string]: IRepoV0 };

  public execs?: { [key: string]: IExecV0 };

  public reposV1?: { [key: string]: IRepo };

  public execsV1?: { [key: string]: IExec };

  public verbYaml?: string;

  public verbBuildStatus?: VerbBuildStatus;

  public exposedPorts?: string[];

  public spot?: boolean;

  public workspaceCapabilities?: Capability[];

  public onContainer?: boolean | undefined;

  public sshPort?: number;

  public healthCheck?: HealthCheck[];

  public tunnel?: ITunnel | undefined;

  public fileObjects?: { [key: string]: IFileObject };

  public additionalUsers?: string[];

  public baseImage?: string;

  public vmOnlyMode?: boolean;

  public customContainer?: CustomContainer;

  public portMappings: Record<string, string> | null;

  public lastStartStatus?: string;

  public lastStartStatusMessage?: string;

  public dockerCompose?: DockerCompose;

  public get projectFolderName(): string {
    if (this.name) return this.name;
    if (this.gitRepo) return getRepoProjectName(this.gitRepo);
    return this.dns.split(".")[0].split("-")[0];
  }

  public get isOutOfDate(): boolean {
    let outdatedStuff = 0;

    if (this.gitRepo) {
      outdatedStuff += 1;
    }

    for (const k in this.repos) {
      outdatedStuff += 1;
    }

    for (const k in this.execs) {
      outdatedStuff += 1;
    }

    if (outdatedStuff > 0) {
      return true;
    }
    return false;
  }

  public get projectName(): string {
    if (this.ideConfig?.defaultWorkingDir) {
      return getCustomDirectoryFromAbsolutePathName(
        this.ideConfig?.defaultWorkingDir
      );
    }
    if (
      !this.gitRepo ||
      this.gitRepo === parseGitUrlToCloneFormat(RSTUDIO_TEMPLATE_URL) ||
      this.gitRepo === parseGitUrlToCloneFormat(JUPYTER_TEMPLATE_URL)
    ) {
      return this.name;
    }
    return getRepoProjectName(this.gitRepo);
  }

  get allRepoValues(): IGitRepo[] {
    const repos: IGitRepo[] = [];

    if (this.gitRepo) {
      const projectDirectory = getRepoProjectName(this.gitRepo);
      const newRepo: IGitRepo = {
        type: "git",
        repository: this.gitRepo,
        gitRepoDirectory: projectDirectory,
      };
      repos.push(newRepo);
    }

    for (const k in this.repos) {
      const gitUrl = this.repos[k].repository;
      const projectDirectory = getRepoProjectName(
        parseGitUrlToCloneFormat(gitUrl)
      );
      const newRepo: IGitRepo = {
        type: "git",
        repository: gitUrl,
        gitRepoDirectory: projectDirectory,
      };
      repos.push(newRepo);
    }

    for (const k in this.reposV1) {
      if (this.reposV1[k].type === "git") {
        repos.push(this.reposV1[k] as IGitRepo);
      }
    }
    return repos;
  }

  get allExecValues(): (StringExec | PathExec)[] {
    const execs: (StringExec | PathExec)[] = [];

    // string execs
    for (const k in this.execs) {
      const execStr = this.execs[k].exec;
      const { execWorkDir } = this.execs[k];
      const { dependsOn } = this.execs[k];

      const options: IExecOptions = {};
      if (execWorkDir) options.execWorkDir = execWorkDir;
      if (dependsOn) options.dependsOn = dependsOn;
      const newStringExec = new StringExec(k, execStr, options);
      execs.push(newStringExec);
    }

    // path execs
    for (const k in this.repos) {
      const { repository, setupExecPath } = this.repos[k];
      if (setupExecPath) {
        const repoName = getRepoProjectName(
          parseGitUrlToCloneFormat(repository)
        );
        const options: IExecOptions = {};
        if (this.repos[k].dependsOn)
          options.dependsOn = this.repos[k].dependsOn;
        const newPathExec = new PathExec(setupExecPath, repoName, options);
        execs.push(newPathExec);
      }
    }

    // normal execs
    for (const k in this.execsV1) {
      const exec = this.execsV1[k];
      let execObj: PathExec | StringExec | null = null;
      if (exec.type === "path") {
        execObj = new PathExec(null, null, null, exec, k);
      }
      if (exec.type === "string") {
        execObj = new StringExec(null, null, null, exec, k);
      }
      if (execObj) execs.push(execObj);
    }
    return execs;
  }

  get browserOpenLink(): string {
    if (this.ideConfig?.defaultWorkingDir) {
      // latest
      return `https://${this.dns}/?folder=${this.ideConfig.defaultWorkingDir}`;
    }
    if (this.gitRepo) {
      // if they're using a main gitRepo
      return `https://${this.dns}/?folder=/home/ubuntu/${getRepoProjectName(
        this.gitRepo
      )}`;
    }
    if (this.reposV1) {
      // on reposV1 but have no default working dir
      const keys = Object.keys(this.reposV1);
      if (keys.length === 1) {
        const repo = this.reposV1[keys[0]];
        if (repo.type === "git") {
          return `https://${this.dns}/?folder=/home/ubuntu/${repo.gitRepoDirectory}`;
        }
        return `https://${this.dns}/?folder=/home/ubuntu/${repo.emptyRepoDirectory}`;
      }
      return `https://${this.dns}/?folder=/home/ubuntu`;
    }
    return `https://${this.dns}/?folder=/home/ubuntu`;
  }

  public constructor(w: IWorkspace) {
    this.id = w.id;
    this.workspaceGroupId = w.workspaceGroupId;
    this.workspaceTemplate = w.workspaceTemplate;
    this.organizationId = w.organizationId;
    this.workspaceClassId = w.workspaceClassId;
    this.statusMessage = w.statusMessage;
    this.name = w.name;
    this.createdByUserId = w.createdByUserId;
    this.status = w.status;
    this.dns = w.dns;
    this.password = w.password;
    if (w.image) this.image = w.image;
    if (w.architecture) this.architecture = w.architecture;
    if (w.region) this.region = w.region;
    if (w.diskStorage) this.diskStorage = w.diskStorage;
    if (w.gitRepo) this.gitRepo = w.gitRepo;
    if (w.startupScriptPath) this.startupScriptPath = w.startupScriptPath;
    this.version = w.version;
    this.isStoppable = w.isStoppable;
    this.healthStatus = w.healthStatus;
    this.createdAt = w.createdAt;
    this.updatedAt = w.updatedAt;
    if (w.instanceType) this.instanceType = w.instanceType;
    if (w.instanceTypeInfo) this.instanceTypeInfo = w.instanceTypeInfo;
    this.lastOnlineAt = w.lastOnlineAt;
    this.ideConfig = w.ideConfig || ({} as IdeConfig);
    this.repos = w.repos;
    this.execs = w.execs;
    this.reposV1 = w.reposV1;
    this.execsV1 = w.execsV1;
    this.verbYaml = w.verbYaml;
    this.verbBuildStatus = w.verbBuildStatus;
    this.exposedPorts = w.exposedPorts;
    this.spot = w.spot;
    this.workspaceCapabilities = w.workspaceCapabilities;
    this.onContainer = w.onContainer;
    this.sshPort = w.sshPort;
    this.healthCheck = w.healthCheck;
    this.tunnel = w.tunnel;
    this.additionalUsers = w.additionalUsers;
    this.vmOnlyMode = w.vmOnlyMode;
    this.baseImage = w.baseImage;
    this.customContainer = w.customContainer;
    this.dockerCompose = w.dockerCompose;
    this.portMappings = w.portMappings;
    this.lastStartStatus = w.lastStartStatus;
    this.lastStartStatusMessage = w.lastStartStatusMessage;
    if (w.fileObjects) this.fileObjects = w.fileObjects;
  }

  setIsStoppable = (newIsStoppable: boolean): void => {
    this.isStoppable = newIsStoppable;
  };

  setStatus = (s: WorkspaceStatus) => {
    this.status = s;
  };

  // optional, but we don't really modify this object alone, just let it stay reflected from the server
  addFile = (file: IFileObject): void => {
    if (!this.fileObjects) {
      this.fileObjects = {};
    }
    this.fileObjects[file.path] = file;
  };

  deleteFile = (path: string): void => {
    if (this.fileObjects) {
      delete this.fileObjects[path];
    }
  };
}
