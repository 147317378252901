import React, { useState } from "react";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import { SimpleContainerOverview } from "components/Environment/shared/ContainerOverview";
import ContainerSelector from "components/Environment/Create/ContainerSelector";
import { AnimatePresence, motion } from "framer-motion";

export interface ContainerSelectorWrapperProps {
  container?: ContainerSelected;
  setContainer: (containerSelected: ContainerSelected) => void;
  pythonVersion: string;
  setPythonVersion: (pythonVersion: string) => void;
  cudaVersion: string;
  setCudaVersion: (cudaVersion: string) => void;
  hideCustomCredentials?: boolean;
}

const ContainerSelectorWrapper: React.FC<ContainerSelectorWrapperProps> = ({
  setContainer,
  container,
  pythonVersion,
  setPythonVersion,
  cudaVersion,
  setCudaVersion,
  hideCustomCredentials,
}) => {
  const [openSelector, setOpenSelector] = useState(true);
  return (
    <>
      <div className="mb-5">
        <h1
          className="text-xl font-semibold text-gray-900 dark:text-white mb-1"
          style={{
            fontSize: "1.875rem",
          }}
        >
          Customize your Container
        </h1>
        <p className="text-md mt-2 text-gray-700 dark:text-secondary">
          Specify a base image for your container or choose a custom container
        </p>
        <p className="text-sm text-gray-700 dark:text-secondary">
          (A default Python and CUDA container will be used if you don't)
        </p>
      </div>
      <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
      <div className="flex flex-row mb-3 items-center text-gray-400 dark:text-secondary">
        <RectangleGroupIcon className="h-6 w-6 mr-1.5" />
        <SimpleContainerOverview
          containerSelected={container}
          pythonVersion={pythonVersion}
          cudaVersion={cudaVersion}
          className={"dark:border-secondary"}
        />
        {(container?.verbBuild?.containerUrl === "Default" || !container) &&
          pythonVersion === "3.10" &&
          cudaVersion === "12.0.1" && (
            <span className="text-sm ml-1">(Default)</span>
          )}
        {container && (
          <div
            className="flex flex-row ml-5 text-highlight dark:text-highlight items-center cursor-pointer"
            onClick={() => setOpenSelector(!openSelector)}
          >
            <span className="text-sm">View Selection</span>
            {!openSelector ? (
              <ChevronRightIcon className="h-5 w-5 ml-1" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 ml-1" />
            )}
          </div>
        )}
      </div>
      <div className="flex flex-col">
        <AnimatePresence initial={false}>
          {(!container || openSelector) && (
            <motion.section
              key="container"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 0.8,
                ease: [0.04, 0.62, 0.23, 0.98],
              }}
            >
              <motion.div
                variants={{
                  collapsed: { scale: 0.8 },
                  open: { scale: 1 },
                }}
                transition={{ duration: 0.8 }}
                className="content-placeholder"
              >
                <ContainerSelector
                  containerSelected={container}
                  hideTitle={true}
                  onSelectContainer={(container) => {
                    setContainer(container);
                    setOpenSelector(false);
                  }}
                  pythonVersion={pythonVersion}
                  setPythonVersion={setPythonVersion}
                  cudaVersion={cudaVersion}
                  setCudaVersion={setCudaVersion}
                  hideCustomCredentials={hideCustomCredentials}
                />
              </motion.div>
            </motion.section>
          )}
          {(container || !openSelector) && <></>}
        </AnimatePresence>
      </div>
    </>
  );
};

export default ContainerSelectorWrapper;
