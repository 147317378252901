import React, { useContext, useEffect, useState } from "react";
import Button from "components/UI-lib/Button";
import {
  IApplication,
  VerbBuildStatus,
  WorkspaceStatus,
} from "models/Workspace.model";
import {
  buildDefaultVerbYamlConfig,
  getVerbBuildConfigFromWorkspace,
} from "./utils";
import LogDisplay from "components/Verb/LogDisplay";
import Spinner from "components/UI-lib/Spinner";
import FlatCard from "components/UI-lib/FlatCard";
import { WaitingOnBuildStep } from "../Environment/Settings/DeprecatedSettings/InstanceSettings/WaitingOnBuildStep";
import {
  VERB_FINISHED_PHRASE,
  VERB_FAILED_PHRASE,
} from "components/Environment/Settings/utils";
import InputField from "components/UI-lib/InputField";
import Dropdown, { DropdownItem } from "components/UI-lib/Dropdown";
import DevToggle from "components/DevToggle";
import InlineNotification from "contexts/Notifications/InlineNotifications";
import Deployments from "../Environment/Settings/Tabs/Access/Tunnels";
import JupyterNotebookFetcher from "../Environment/Settings/DeprecatedSettings/InstanceSettings/JupyterNotebookFetcher";
import Workspace from "../../entities/Workspace.entity";
import { Capability } from "contexts/OrgContext";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import { WorkspaceCreateContext } from "contexts/WorkspaceCreateContext";
import ShareSettings from "../Environment/Settings/Tabs/Access/Share";
import { UserContext } from "contexts/UserContext";
import {
  classNames,
  isCPU_techdebt,
  isSharedWorkspace,
} from "components/utils";
import { Loader1, Loader2 } from "components/FullScreenLoader";
import { OneClickDeployContext } from "contexts/OneClickDeployContext";

interface IVerbBuildComponentProps {
  workspaceId: string;
  setShowButton: (b: boolean) => void;
  workspace: Workspace | null;
  initialVerbLoad: boolean;
}
export const VerbBuildComponent: React.FC<IVerbBuildComponentProps> = ({
  workspaceId,
  setShowButton,
  workspace,
  initialVerbLoad,
}) => {
  // const [jupyterUrl, setJupyterUrl] = useState("");
  const createContext = useContext(WorkspaceCreateContext);
  const [showAddNotebookForm, setShowAddNotebookForm] = useState(false);
  const [canAddNotebooks, setCanAddNotebooks] = useState(false);

  const verbBuild = getVerbBuildConfigFromWorkspace(workspace?.verbYaml || "");

  useEffect(() => {
    if (workspace && workspace.status && workspace.verbBuildStatus) {
      if (
        workspace.status === WorkspaceStatus.Running &&
        workspace.verbBuildStatus === VerbBuildStatus.Completed
      ) {
        setCanAddNotebooks(true);
      } else {
        setCanAddNotebooks(false);
      }
    }
  }, [workspace?.status, workspace?.verbBuildStatus]);

  useEffect(() => {
    if (createContext.logFileContent.includes(VERB_FINISHED_PHRASE)) {
      createContext.setIsVerbBuilding(false);
      // if (!createContext.logFileContent.includes(SHAKA)) {
      //   createContext.setLogFileContent(createContext.logFileContent + SHAKA);
      // }
    }
  }, [createContext.logFileContent]);

  useEffect(() => {
    if (
      !(
        createContext.logFileContent.includes(VERB_FAILED_PHRASE) ||
        workspace?.verbBuildStatus === VerbBuildStatus.CreateFailed
      ) &&
      (createContext.verbYaml === "" ||
        createContext.isVerbBuilding === true ||
        workspace?.status === WorkspaceStatus.Deleting ||
        workspace?.status === WorkspaceStatus.Stopping ||
        workspace?.status === WorkspaceStatus.Stopped)
    ) {
      createContext.setBuildButtonDisabled(true);
    } else {
      createContext.setBuildButtonDisabled(false);
    }
  }, [createContext.verbYaml, createContext.isVerbBuilding, workspace?.status]);

  const determineContentToRender = () => {
    // console.log(`
    // -> hitBuild ${createContext.hitBuildButton}
    // -> createContext.logFileContent==="" ${createContext.logFileContent === ""}
    // -> workspace?.status ${workspace?.status}
    // -> workspace?.verbBuildStatus ${workspace?.verbBuildStatus}
    // `);

    if (
      createContext.hitBuildButton &&
      createContext.logFileContent === "" &&
      workspace &&
      workspace.verbBuildStatus !== VerbBuildStatus.CreateFailed &&
      workspace?.workspaceGroupId !== "akash-brev-wg"
    ) {
      return (
        <>
          <Spinner type="secondary" />
          <WaitingOnBuildStep
            onComplete={() => {
              setShowButton(true);
            }}
          />
        </>
      );
    } else if (
      workspace &&
      (workspace?.status === WorkspaceStatus.Starting ||
        workspace?.status === WorkspaceStatus.Deploying) &&
      workspace?.verbBuildStatus === VerbBuildStatus.Pending
    ) {
      return (
        <>
          <FlatCard className="mt-2 mb-2" isLoading={false}>
            <p className="text-sm text-gray-700 dark:text-secondary font-mono">
              Logs will appear here when the container starts building.
              <span className="text-gray-700 dark:text-slate-300">
                {" Please wait until the instance is in Running state."}
              </span>
            </p>
          </FlatCard>
        </>
      );
    } else if (
      workspace &&
      workspace.status === WorkspaceStatus.Running &&
      (workspace.verbBuildStatus === VerbBuildStatus.Building ||
        workspace.verbBuildStatus === VerbBuildStatus.Completed ||
        workspace.verbBuildStatus === VerbBuildStatus.Pending ||
        workspace.verbBuildStatus === VerbBuildStatus.CreateFailed) &&
      createContext.logFileContent !== ""
    ) {
      return (
        <>
          <LogDisplay
            workspace={workspace}
            logFileContent={createContext.logFileContent}
            retroMode={false}
          />
        </>
      );
    }
  };

  if (workspace && workspace.status !== WorkspaceStatus.Running) {
    return (
      <div className="flex w-full mt-5">
        <FlatCard isLoading={false}>
          <p className="text-sm text-gray-700 dark:text-secondary font-mono text-center">
            Logs will appear here when the instance is running.
          </p>
        </FlatCard>
      </div>
    );
  }

  return (
    <div>
      <div className="dark:text-secondary">
        <div className="flex flex-col">
          {workspace &&
            ((workspace.status === WorkspaceStatus.Running &&
              workspace.verbBuildStatus === VerbBuildStatus.Building) ||
              (workspace.workspaceGroupId !== "akash-brev-wg" &&
                workspace.status !== WorkspaceStatus.Starting &&
                workspace.status !== WorkspaceStatus.Deploying &&
                workspace.status !== WorkspaceStatus.Stopped &&
                workspace.status !== WorkspaceStatus.Stopping &&
                workspace.verbBuildStatus !== VerbBuildStatus.Completed &&
                !createContext.logFileContent.includes(VERB_FINISHED_PHRASE) &&
                !createContext.logFileContent.includes(VERB_FAILED_PHRASE) &&
                workspace.verbBuildStatus !==
                  VerbBuildStatus.CreateFailed)) && (
              <FlatCard className="mt-3" isLoading={false}>
                <p className="text-sm text-amber-700 dark:text-amber-500 font-mono text-center">
                  {!!verbBuild?.base_image ||
                  workspace.customContainer ||
                  workspace.dockerCompose
                    ? `Building ${
                        verbBuild?.base_image ||
                        workspace.customContainer?.containerUrl ||
                        workspace.dockerCompose?.fileUrl
                          ? "Docker Compose YAML"
                          : "" || "Unknown Container"
                      }`
                    : "Python and CUDA will be installed once the container is ready"}
                </p>
              </FlatCard>
            )}

          {workspace &&
            workspace.workspaceGroupId === "akash-brev-wg" &&
            workspace.verbBuildStatus === VerbBuildStatus.Completed &&
            workspace.status === WorkspaceStatus.Running && (
              <FlatCard className="mt-3" isLoading={false}>
                <p className="text-sm text-amber-700 dark:text-amber-500 font-mono">
                  Container running on instance.
                </p>
              </FlatCard>
            )}

          {workspace &&
            (createContext.logFileContent.includes(VERB_FAILED_PHRASE) ||
              workspace.verbBuildStatus === VerbBuildStatus.CreateFailed) &&
            workspace.status === WorkspaceStatus.Running && (
              <FlatCard className="mt-3" isLoading={false}>
                <p className="text-sm text-amber-700 dark:text-amber-500 font-mono text-center">
                  The container failed to build. Please reach out to support or{" "}
                  {workspace.customContainer?.containerUrl !== ""
                    ? "try setting up the container manually in VM mode"
                    : "rebuild it"}
                </p>
              </FlatCard>
            )}

          {workspace &&
            (workspace?.status === WorkspaceStatus.Running ||
              workspace?.status === WorkspaceStatus.Starting ||
              workspace?.status === WorkspaceStatus.Deploying) &&
            initialVerbLoad &&
            createContext.logFileContent === "" &&
            !createContext.hitBuildButton && (
              <FlatCard className="mt-3" isLoading={true}>
                <p className="text-sm text-gray-700 dark:text-secondary font-mono text-center">
                  Checking for Container Logs...
                </p>
              </FlatCard>
            )}
          {workspace &&
          (workspace.status === WorkspaceStatus.Deploying ||
            workspace.status === WorkspaceStatus.Starting) &&
          workspace.verbBuildStatus !== VerbBuildStatus.Completed &&
          workspace.verbBuildStatus !== VerbBuildStatus.CreateFailed ? (
            <FlatCard className="mt-3" isLoading={false}>
              <p className="text-sm text-amber-700 dark:text-amber-500 font-mono">
                Waiting for your instance to be running before{" "}
                {!!verbBuild?.base_image
                  ? `building ${verbBuild.base_image}`
                  : "installing Python and CUDA"}
                <span>
                  <Loader1 />
                </span>
              </p>
            </FlatCard>
          ) : (
            <>
              <div className="mt-5">{determineContentToRender()}</div>
              {workspace &&
                !workspace.customContainer?.containerUrl &&
                (createContext.logFileContent.includes(VERB_FAILED_PHRASE) ||
                  workspace.verbBuildStatus === VerbBuildStatus.CreateFailed) &&
                workspace.status === WorkspaceStatus.Running && (
                  <span>
                    <Button
                      className="mt-8 justify-center"
                      type={
                        createContext.buildButtonDisabled
                          ? "secondary"
                          : "primary"
                      }
                      label="Rebuild"
                      disabled={createContext.buildButtonDisabled}
                      loading={createContext.isBuilding}
                      onClick={() => {
                        buildDefaultVerbYamlConfig(
                          verbBuild?.base_image || "",
                          workspace?.portMappings || null
                        );
                      }}
                    />
                  </span>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
