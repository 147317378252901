import { OrgContext } from "contexts/OrgContext";
// import { ToastContext } from "contexts/ToastContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import { BillingContext } from "contexts/BillingContext";
import { AreaChart } from "../../../Graphs/AreaGraph";
import { classNames, monthNames } from "components/utils";

const chartdata = [
  {
    date: "May 1",
    "Compute Costs": 2890,
    "Storage Costs": 2338,
  },
  {
    date: "May 2",
    "Compute Costs": 2756,
    "Storage Costs": 2103,
  },
  {
    date: "May 3",
    "Compute Costs": 3322,
    "Storage Costs": 2194,
  },
  {
    date: "May 4",
    "Compute Costs": 3470,
    "Storage Costs": 2108,
  },
  {
    date: "May 5",
    "Compute Costs": 3475,
    "Storage Costs": 1812,
  },
  {
    date: "May 6",
    "Compute Costs": 3129,
    "Storage Costs": 1726,
  },
  {
    date: "May 7",
    "Compute Costs": 3490,
    "Storage Costs": 1982,
  },
  {
    date: "May 8",
    "Compute Costs": 2903,
    "Storage Costs": 2012,
  },
  {
    date: "May 9",
    "Compute Costs": 2643,
    "Storage Costs": 2342,
  },
  {
    date: "May 10",
    "Compute Costs": 2837,
    "Storage Costs": 2473,
  },
  {
    date: "May 11",
    "Compute Costs": 2954,
    "Storage Costs": 3848,
  },
  {
    date: "May 12",
    "Compute Costs": 3239,
    "Storage Costs": 3736,
  },
  {
    date: "May 13",
    "Compute Costs": 2324,
    "Storage Costs": 1800,
  },
  {
    date: "May 14",
    "Compute Costs": 4343,
    "Storage Costs": 1920,
  },
  {
    date: "May 15",
    "Compute Costs": 3200,
    "Storage Costs": 1234,
  },
  {
    date: "May 16",
    "Compute Costs": 2303,
    "Storage Costs": 2100,
  },
  {
    date: "May 17",
    "Compute Costs": 3300,
    "Storage Costs": 2500,
  },
  {
    date: "May 18",
    "Compute Costs": 3230,
    "Storage Costs": 1800,
  },
  {
    date: "May 19",
    "Compute Costs": 3004,
    "Storage Costs": 1750,
  },
  {
    date: "May 20",
    "Compute Costs": 3600,
    "Storage Costs": 2200,
  },
  {
    date: "May 21",
    "Compute Costs": 2543,
    "Storage Costs": 2100,
  },
  {
    date: "May 22",
    "Compute Costs": 3232,
    "Storage Costs": 2000,
  },
  {
    date: "May 23",
    "Compute Costs": 2000,
    "Storage Costs": 1400,
  },
  {
    date: "May 24",
    "Compute Costs": 3234,
    "Storage Costs": 1234,
  },
  {
    date: "May 25",
    "Compute Costs": 2400,
    "Storage Costs": 1700,
  },
  {
    date: "May 26",
    "Compute Costs": 2405,
    "Storage Costs": 1600,
  },
  {
    date: "May 27",
    "Compute Costs": 4300,
    "Storage Costs": 1800,
  },
  {
    date: "May 28",
    "Compute Costs": 4400,
    "Storage Costs": 1400,
  },
  {
    date: "May 29",
    "Compute Costs": 2345,
    "Storage Costs": 1324,
  },
  {
    date: "May 30",
    "Compute Costs": 3245,
    "Storage Costs": 1200,
  },
];

interface BillingOverviewProps {
  activeOrg: boolean;
  usageOverview: any[];
  usageOverviewLoading: boolean;
}

const BillingOverview: React.FC<BillingOverviewProps> = ({
  activeOrg,
  usageOverview,
  usageOverviewLoading,
}) => {
  const displayData = useMemo(() => {
    const generateLast30Days = () => {
      const dates: Date[] = [];
      for (let i = 29; i >= 0; i--) {
        const date = new Date();
        date.setUTCDate(date.getUTCDate() - i);
        dates.push(date);
      }
      return dates;
    };

    const last30Days = generateLast30Days();

    const dataLookup = usageOverview.reduce((acc, item) => {
      const utcDate = new Date(item.Date);
      const dateString = utcDate.toUTCString().split(" ").slice(0, 4).join(" ");
      acc[dateString] = item;
      return acc;
    }, {});

    return last30Days.map((date) => {
      const dateString = date.toUTCString().split(" ").slice(0, 4).join(" ");
      const item = dataLookup[dateString] || {
        ComputeCost: 0,
        StorageCost: 0,
        InferenceCost: 0,
      };
      return {
        date: `${monthNames[date.getUTCMonth()]} ${date.getUTCDate()}`,
        "Compute Costs": Number(item.ComputeCost || 0),
        "Storage Costs": Number(item.StorageCost || 0),
        "Inference Costs": Number(item.InferenceCost || 0),
      };
    });
  }, [usageOverview]);

  if (activeOrg) {
    return (
      <>
        <FlatCard noBorder={true}>
          <div className="flex flex-col">
            <div className="flex flex-col justify-start">
              <h1 className="text-xl font-medium dark:text-white">
                Usage Overview
              </h1>
              <p className="text-sm text-gray-700 dark:text-secondary">
                Last 30 days
              </p>
            </div>
            <>
              {usageOverviewLoading || usageOverview.length === 0 ? (
                <div className="relative">
                  <div
                    className={classNames(
                      "absolute top-[20%] z-50",
                      usageOverviewLoading ? "left-[48%]" : "left-[40%]"
                    )}
                  >
                    <span className="text-white font-bold">
                      {usageOverviewLoading
                        ? "Loading..."
                        : "No Usage Data Available"}
                    </span>
                  </div>
                  <AreaChart
                    className="opacity-15 h-80"
                    data={chartdata}
                    showTooltip={false}
                    disableHoverDots={true}
                    index="date"
                    categories={["Compute Costs", "Storage Costs"]}
                    valueFormatter={(number: number) =>
                      `$${Intl.NumberFormat("us").format(number).toString()}`
                    }
                    intervalType="preserveStartEnd"
                  />
                </div>
              ) : (
                <>
                  <AreaChart
                    className="h-80"
                    data={displayData}
                    index="date"
                    categories={[
                      "Compute Costs",
                      "Storage Costs",
                      "Inference Costs",
                    ]}
                    valueFormatter={(number: number) =>
                      `$${Intl.NumberFormat("us").format(number).toString()}`
                    }
                    onValueChange={(v) => console.log(v)}
                    intervalType="preserveStartEnd"
                  />
                </>
              )}
            </>
          </div>
        </FlatCard>
      </>
    );
  }
  // when clicking the settings button, reponse time is so fast the seeing loading bars feels off
  return <div />;
};

export default BillingOverview;
