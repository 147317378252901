/* eslint-disable react/no-unused-prop-types */
// they are being used idk they aren't beinf detected by eslint
import React, { useContext, useEffect, useMemo, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import Logs from "./Logs";
import Metrics from "./Metrics";
import { ChevronLeftIcon } from "lucide-react";
import { AreaChart } from "components/Graphs/AreaGraph";
import { useHistory, useLocation, useParams } from "react-router";
import { Button, FlatCard } from "components/UI-lib";
import { Badge } from "components/Graphs/Badge";
import TabNavigator from "./TabNavigator";
import CodeSnippets from "./CodeSnippets";
import { classNames, fallbackCopyTextToClipboard } from "components/utils";
import {
  BoltIcon,
  CubeIcon,
  PlayCircleIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/solid";
import Chip from "components/Environment/Create/chips";
import agent, { Deployment, DeploymentMetrics, DeploymentLog } from "server";
import { DeploymentsContext } from "contexts/DeploymentsContext";
import {
  getDeploymentInstanceTypeByType,
  mapStatusToLabel,
  mapStatusToVariant,
} from "../utils";
import { usePollingEffect } from "components/hooks";
import hfLogo from "assets/img/svg/hf-logo.svg";
import { NotificationContext } from "contexts/NotificationContext";
import { useQuery } from "@tanstack/react-query";
import { Chatbot } from "components/Chatbot";
import nim from "../../../assets/img/png/nim.png";

/*
                               _
              _        ,-.    / )
             ( `.     // /-._/ /
              `\ \   /(_/ / / /
                ; `-`  (_/ / /
                |       (_/ /
                \          /
                 )        /
      Brev.Dev   /       /
*/

const sampleLogs = [
  {
    timestamp: "9/7/2023, 5:03:57 PM",
    statusCode: 202,
    message: "24909 succeeded",
  },
  {
    timestamp: "9/7/2023, 4:04:10 PM",
    statusCode: 200,
    message: "3792 succeeded",
  },
  {
    timestamp: "9/7/2023, 3:54:43 PM",
    statusCode: 400,
    message: "1 failed",
  },
  {
    timestamp: "9/7/2023, 3:43:11 PM",
    statusCode: 200,
    message: "47274 succeeded",
  },
  {
    timestamp: "9/7/2023, 3:30:00 PM",
    statusCode: 499,
    message: "187 cancelled",
  },
];

const deploymentDetails = {
  id: "nvidia/model",
};

interface ParamTypes {
  deploymentId: string;
}

const DeploymentSettings: React.FC = (props) => {
  console.log("Route: /org/:orgId/deployments/:deploymentId");

  const deploymentsContext = useContext(DeploymentsContext);
  const notificationContext = useContext(NotificationContext);
  const orgContext = useContext(OrgContext);
  const history = useHistory();
  const { deploymentId } = useParams<ParamTypes>();
  const [deployment, setDeployment] = useState<Deployment>();
  const [metrics, setMetrics] = useState<DeploymentMetrics>();
  const [loadingMetrics, setLoadingMetrics] = useState<boolean>(false);
  const [logs, setLogs] = useState<DeploymentLog[]>();
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingLogs, setLoadingLogs] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>("");
  const [isLoadingApiKey, setIsLoadingApiKey] = useState<boolean>(false);

  const hash = window?.location?.hash?.slice(1) ?? "";
  const validStates = ["Metrics", "Logs", "Code Snippets"];
  const [currentState, setCurrentState] = useState(
    validStates.includes(hash) ? hash : "Metrics"
  );

  useEffect(() => {
    if (deploymentsContext.deployments) {
      const dep = deploymentsContext.deployments.find(
        (deployment) => deployment.deployment_id === deploymentId
      );
      if (dep) {
        setDeployment(dep);
      }
    }
  }, [deploymentsContext.deployments]);

  const loadDeployment = async () => {
    const res = await agent.Deployments.getDeployment(
      orgContext.activeOrgId,
      deploymentId
    );
    if (res.success && res.data) {
      setDeployment(res.data);
    }
  };

  const getDeploymentMetrics = async () => {
    setLoadingMetrics(true);
    const res = await deploymentsContext.getDeploymentMetrics(
      orgContext.activeOrgId,
      deploymentId
    );
    if (res) {
      setMetrics(res);
    }
    setLoadingMetrics(false);
  };

  const deleteDeployment = async () => {
    setLoadingDelete(true);
    const res = await agent.Deployments.deleteDeployment(
      orgContext.activeOrgId,
      deploymentId
    );
    if (res.success) {
      history.push(`/org/${orgContext.activeOrgId}/deployments`);
    } else {
      notificationContext.showNotification(
        "Error Deleting Deployment",
        res.message,
        "error"
      );
    }
    setLoadingDelete(false);
  };

  useEffect(() => {
    const loadApiKey = async () => {
      setIsLoadingApiKey(true);
      try {
        const res = await agent.InferenceEndpoints.getApiKey({
          orgId: orgContext.activeOrgId,
        });
        if (res.success && res.data.length > 0) {
          setApiKey(res.data[0].value);
        } else {
          console.error("API key not found in response");
        }
      } catch (error) {
        console.error("Failed to load API key:", error);
      } finally {
        setIsLoadingApiKey(false);
      }
    };
    if (orgContext.activeOrgId) {
      loadApiKey();
    }
  }, [orgContext.activeOrgId]);

  const getDeploymentLogs = async () => {
    setLoadingLogs(true);
    const res = await agent.Deployments.getDeploymentLogs(
      orgContext.activeOrgId,
      deploymentId
    );
    if (res.success && res.data) {
      setLogs(res.data);
    } else {
      setLogs([]);
    }
    setLoadingLogs(false);
  };

  useEffect(() => {
    loadDeployment();
  }, [deploymentId]);

  useEffect(() => {
    if (deployment) {
      return;
    }
    getDeploymentMetrics();
    getDeploymentLogs();
  }, [deployment]);

  usePollingEffect(
    async () => {
      if (!deployment) {
        return;
      }
      const res = await agent.Deployments.getDeployment(
        orgContext.activeOrgId,
        deploymentId
      );
      if (res.success && res.data) {
        if (res.data.status !== deployment.status) {
          setDeployment(res.data);
          const otherDeployments = deploymentsContext.deployments.filter(
            (dep) => dep.deployment_id !== deployment.deployment_id
          );
          deploymentsContext.setDeployments([deployment, ...otherDeployments]);
        }
      }
    },
    [deployment],
    { executeImmediately: true }
  );

  useEffect(() => {
    window.location.hash = currentState;
  }, [currentState]);

  const gpuFromInstanceType = useMemo(() => {
    const result = getDeploymentInstanceTypeByType(
      deploymentsContext.instanceTypes || [],
      deployment?.instance_type || ""
    );
    if (result && result.supported_gpus) {
      return result.supported_gpus[0];
    }
    return null;
  }, [deployment, deploymentsContext.instanceTypes]);

  const getUsage = async (orgId: string) => {
    const res = await agent.Organizations.getUsage(orgId);
    if (!res.success || !res.data) {
      throw new Error("Failed to fetch usage data");
    }
    return res.data.Usage;
  };

  const { data: usageData, isLoading: usageLoading } = useQuery({
    queryKey: ["usage", orgContext.activeOrgId],
    queryFn: () => getUsage(orgContext.activeOrgId),
    enabled: !!orgContext.activeOrgId,
  });

  const totalCost = useMemo(() => {
    const deploymentUsage = usageData?.find((usage) => {
      return usage.EnvID === deployment?.model_id;
    });
    if (deploymentUsage) {
      return parseFloat(deploymentUsage.Cost || "0.00");
    } else {
      return 0.0;
    }
  }, [usageData, deployment]);

  return (
    <>
      <div className="min-h-full">
        {/* Page heading */}
        <header className="pt-8 pb-3 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div className="flex-1 min-w-0 pb-3">
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <a
                        onClick={() => {
                          history.push(
                            `/org/${orgContext.activeOrgId}/deployments`
                          );
                        }}
                        className="text-sm font-medium text-gray-500 dark:text-secondary hover:text-gray-700 dark:hover:text-gray-300 cursor-pointer flex"
                      >
                        <ChevronLeftIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />{" "}
                        back to all deployments
                      </a>
                    </div>
                  </li>
                </ol>
              </nav>
              <div className="flex flex-row justify-between items-start mt-2">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center">
                    <h1 className="text-2xl font-bold leading-7 text-gray-900 dark:text-gray-100 sm:text-2xl sm:tracking-tight sm:truncate">
                      {deployment?.name || "Unkown Deployment"}
                    </h1>
                    <div className="text-sm text-secondary ml-3 mt-[5px]">
                      ID: {deployment?.deployment_id || ""}
                    </div>
                    <div className="ml-3">
                      <Badge
                        variant={mapStatusToVariant(deployment?.status || 0)}
                      >
                        {mapStatusToLabel(deployment?.status || 0)}
                      </Badge>
                    </div>
                  </div>
                  <div className="flex flex-row mt-2 items-center">
                    <div className="flex flex-row">
                      <div>
                        <Chip
                          selected={true}
                          chipName={"H100"}
                          manufacturerName={"NVIDIA"}
                          onClick={() => {}}
                          className={classNames("h-[48px]")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col ml-2">
                      <div className="flex flex-row items-center text-secondary">
                        <svg
                          width="20"
                          height="12"
                          viewBox="0 0 249 165"
                          className="mr-1"
                          fill="#76B900"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M25.43 70.902C25.43 70.902 47.934 37.699 92.867 34.264V22.218C43.098 26.215 0 68.367 0 68.367C0 68.367 24.41 138.932 92.867 145.393V132.589C42.63 126.269 25.43 70.902 25.43 70.902ZM92.867 107.125V118.851C54.899 112.082 44.36 72.614 44.36 72.614C44.36 72.614 62.59 52.419 92.867 49.144V62.011C92.844 62.011 92.828 62.004 92.809 62.004C76.918 60.097 64.504 74.942 64.504 74.942C64.504 74.942 71.462 99.933 92.867 107.125ZM92.867 0V22.218C94.328 22.106 95.789 22.011 97.258 21.961C153.84 20.054 190.707 68.367 190.707 68.367C190.707 68.367 148.364 119.855 104.25 119.855C100.207 119.855 96.422 119.48 92.867 118.85V132.589C95.907 132.975 99.059 133.202 102.348 133.202C143.399 133.202 173.086 112.237 201.832 87.424C206.598 91.241 226.11 100.527 230.121 104.592C202.789 127.475 139.09 145.921 102.977 145.921C99.496 145.921 96.153 145.71 92.867 145.393V164.699H248.899V0H92.867ZM92.867 49.144V34.264C94.313 34.163 95.77 34.086 97.258 34.039C137.946 32.761 164.64 69.004 164.64 69.004C164.64 69.004 135.808 109.047 104.894 109.047C100.445 109.047 96.457 108.332 92.867 107.125V62.011C108.707 63.925 111.894 70.922 121.417 86.797L142.597 68.938C142.597 68.938 127.136 48.661 101.073 48.661C98.24 48.66 95.529 48.859 92.867 49.144Z" />
                        </svg>
                        <div className="text-sm text-secondary">NVIDIA</div>
                      </div>
                      <div className="flex flex-row items-center text-secondary">
                        {!!gpuFromInstanceType && (
                          <>
                            <div className="text-sm text-secondary mr-1 font-bold">
                              {gpuFromInstanceType.count}x
                            </div>
                            <div className="text-sm text-secondary">
                              {gpuFromInstanceType.name}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col ml-4">
                      <div className="flex flex-row items-center text-secondary">
                        <BoltIcon className="w-4 h-4 mr-1 text-secondary" />
                        <div className="text-sm text-secondary">
                          Powered by Brev Endpoints
                        </div>
                      </div>
                      <div className="flex flex-row items-center text-secondary">
                        <PlayCircleIcon className="w-4 h-4 mr-1 text-secondary" />
                        <div className="text-sm text-secondary">
                          $0.04/per invocation
                        </div>
                      </div>
                    </div>
                    <div className="flex ml-4">
                      <div className="flex flex-row">
                        <div className="flex flex-col">
                          <div className="flex flex-row items-center text-secondary">
                            <CubeIcon
                              className={`w-5 h-5 mr-1 text-secondary`}
                            />
                            <div className="flex flex-row text-sm">
                              {deployment?.artifact?.model?.model_name}{" "}
                            </div>
                          </div>
                          <div className="flex flex-row items-center">
                            <img
                              src={nim}
                              alt="nim"
                              className="w-5 h-5 mr-1 rounded-full"
                            />
                            <div className="flex flex-row text-sm text-secondary">
                              NVIDIA NIM
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex ml-4">
                      <div className="flex flex-row">
                        <div className="flex flex-col">
                          <div className="flex flex-row items-center text-secondary">
                            <Square3Stack3DIcon
                              className={`w-5 h-5 mr-1 text-secondary`}
                            />
                            <div className="flex flex-row text-sm">
                              Min Instances: {deployment?.min_instances || 0}{" "}
                            </div>
                          </div>
                          <div className="flex flex-row items-center text-secondary">
                            <Square3Stack3DIcon
                              className={`w-5 h-5 mr-1 text-secondary`}
                            />
                            <div className="flex flex-row text-sm">
                              Max Instances: {deployment?.max_instances || 0}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex ml-4">
                      <div className="flex flex-row">
                        <div className="flex flex-col">
                          <div className="flex flex-row items-center text-secondary">
                            <div className="flex flex-row text-sm mr-1">
                              Total Cost:
                            </div>
                            <div className="flex flex-row text-sm">
                              {totalCost.toLocaleString("en-US", {
                                style: "currency",
                                currency: "USD",
                              })}
                            </div>
                          </div>
                          <div className="flex flex-row items-center text-secondary h-5 w-5"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <Button
                    onClick={() => {
                      deleteDeployment();
                    }}
                    label="Delete"
                    type="danger"
                    loading={loadingDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="sm:px-6 lg:px-8 mb-8">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <TabNavigator
              setCurrentState={setCurrentState}
              currentState={currentState}
            />
          </div>
        </div>
        <main className="pb-16 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            {currentState === "Metrics" && (
              <Metrics deploymentMetrics={metrics} isLoading={loadingMetrics} />
            )}
            {currentState === "Logs" && (
              <Logs deploymentLogs={logs} isLoading={loadingLogs} />
            )}
            {currentState === "Code Snippets" && (
              <CodeSnippets
                deployment={deployment}
                apiKey={apiKey}
                isLoadingApiKey={isLoadingApiKey}
              />
            )}
            {currentState === "Chat" && (
              <Chatbot
                apiKey={apiKey}
                modelId={deployment?.model_id || ""}
                isLoadingApiKey={isLoadingApiKey}
                deploymentStatus={deployment?.status || 0}
              />
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default DeploymentSettings;
