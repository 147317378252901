import React from "react";
import { useState, useCallback, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChatInput } from "./ChatInput";
import { MessageBlock } from "./MessageBlock";
import { LoadingIndicator } from "./LoadingIndicator";
import { handleChatCompletion } from "./utils/index";
import type { Block, Message } from "./types";
import { Spinner } from "@kui-react/spinner";
import { Messages } from "./Messages";
import { DeploymentStatus } from "server";

export type ChatbotProps = {
  modelId: string;
  apiKey: string;
  isLoadingApiKey: boolean;
  deploymentStatus: DeploymentStatus;
};
export const Chatbot: React.FC<ChatbotProps> = ({
  modelId,
  apiKey,
  isLoadingApiKey,
  deploymentStatus,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);
  const [previousScrollHeight, setPreviousScrollHeight] = useState<number>(0);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (input.trim()) {
        const newUserMessage: Message = { role: "user", content: input };
        setMessages((prevMessages) => [...prevMessages, newUserMessage]);
        setInput("");
        setIsLoading(true);
        setHasStarted(true);

        await handleChatCompletion(
          modelId,
          apiKey,
          [...messages, newUserMessage],
          setMessages
        );

        setIsLoading(false);
      }
    },
    [input, messages]
  );

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const element = mainRef.current;
    if (!element) return;

    const observer = new ResizeObserver(() => {
      const currentScrollHeight = element.scrollHeight;
      if (currentScrollHeight > previousScrollHeight) {
        scrollToBottom();
      }
      setPreviousScrollHeight(currentScrollHeight);
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [previousScrollHeight]);

  useEffect(() => {
    const element = mainRef.current;
    if (!element) return;

    const currentScrollHeight = element.scrollHeight;
    if (currentScrollHeight > previousScrollHeight) {
      scrollToBottom();
    }
    setPreviousScrollHeight(currentScrollHeight);
  }, [messages, previousScrollHeight]);

  return (
    <div
      className={`flex flex-col h-[calc(100vh-150px)] bg-secondaryBg relative border-zinc-800 border ${
        isLoadingApiKey || deploymentStatus != DeploymentStatus.RUNNING
          ? "opacity-50"
          : ""
      }`}
    >
      <motion.header
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: hasStarted ? 1 : 0, y: hasStarted ? 0 : -50 }}
        transition={{ duration: 0.5 }}
        className="bg-secondaryBg shadow"
      >
        <div className="max-w-7xl mx-auto border-b border-zinc-800 p-4">
          <h1 className="text-lg font-bold text-white">
            Chat with your Deployment
          </h1>
        </div>
      </motion.header>
      <main ref={mainRef} className="flex-grow overflow-auto">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="max-w-7xl mx-auto py-6 sm:px-4 lg:px-8"
        >
          {hasStarted ? (
            <>
              <AnimatePresence>
                <Messages messages={messages} isLoading={isLoading} />
              </AnimatePresence>
              <div ref={messagesEndRef} />
            </>
          ) : (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="flex items-center justify-center h-full"
            >
              {deploymentStatus !== DeploymentStatus.RUNNING ? (
                <div className="flex flex-col items-center justify-center h-full">
                  <Spinner className="w-10 h-10" />
                  <p className="text-white">
                    Waiting for Deployment to be running...
                  </p>
                </div>
              ) : isLoadingApiKey ? (
                <div className="flex flex-col items-center justify-center h-full">
                  <Spinner className="w-10 h-10" />
                  <p className="text-white">Loading...</p>
                </div>
              ) : (
                <h2 className="text-2xl font-bold text-white mb-4">
                  Chat with your Deployment
                </h2>
              )}
            </motion.div>
          )}
        </motion.div>
      </main>
      <motion.div
        initial={{ y: hasStarted ? 0 : "50%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        className={`w-full mb-4 ${hasStarted ? "" : "absolute top-1/3"}`}
      >
        <ChatInput
          input={input}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          disabled={
            isLoadingApiKey || deploymentStatus !== DeploymentStatus.RUNNING
          }
        />
      </motion.div>
    </div>
  );
};
