import React, { useState } from "react";
import FlatCard from "components/UI-lib/FlatCard";
import { useFeatureFlagEnabled } from "posthog-js/react";
import DeploymentCreate from "./DeploymentCreate";
import { Model } from "server";

export const dummyModels: Model[] = [
  {
    model_id: "model-9xKsL2mPq8WvN5yD3BtHr4Fg",
    create_time: {
      seconds: 1737056900,
      nanos: 123456000,
    },
    update_time: {
      seconds: 1737056900,
      nanos: 123456000,
    },
    model_name: "meta/llama-2-70b-chat",
    container: {
      base_image:
        "nvcr.io/0514617533638406/production/meta-llama-2-70b-chat:2.0",
      command_args: ["python", "server.py", "--port", "8000"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/completions",
      inference_port: 8000,
      health_url_path: "/health",
    },
    hardware_requirements: [],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-001",
    },
  },
  {
    model_id: "model-7hJkR4nMt6UvL9wX2CqS8Pb",
    create_time: {
      seconds: 1737057000,
      nanos: 234567000,
    },
    update_time: {
      seconds: 1737057000,
      nanos: 234567000,
    },
    model_name: "anthropic/claude-3-opus-20240229",
    container: {
      base_image:
        "nvcr.io/0514617533638406/production/anthropic-claude-3-opus:1.0",
      command_args: ["sh", "-c", "./start.sh"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/messages",
      inference_port: 8000,
      health_url_path: "/v1/health",
    },
    hardware_requirements: [
      {
        min_vram_gb: "80GB",
        supported_gpus: [
          {
            count: 4,
            memory: "80GB",
            name: "A100",
          },
          {
            count: 2,
            memory: "80GB",
            name: "H100",
          },
        ],
      },
    ],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-002",
    },
  },
  {
    model_id: "model-5gFdQ8pKs3TvH7yN1BmW4Rj",
    create_time: {
      seconds: 1737057100,
      nanos: 345678000,
    },
    update_time: {
      seconds: 1737057100,
      nanos: 345678000,
    },
    model_name: "mistral/mixtral-8x7b-instruct",
    container: {
      base_image:
        "nvcr.io/0514617533638406/production/mistral-mixtral-8x7b:1.1",
      command_args: ["python3", "-m", "server"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/chat/completions",
      inference_port: 8000,
      health_url_path: "/health/ready",
    },
    hardware_requirements: [
      {
        min_vram_gb: "40GB",
        supported_gpus: [
          {
            count: 2,
            memory: "40GB",
            name: "A100",
          },
        ],
      },
    ],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-003",
    },
  },
  {
    model_id: "model-3cXbN6mLw4RvK2yP8QtZ5Hf",
    create_time: {
      seconds: 1737057200,
      nanos: 456789000,
    },
    update_time: {
      seconds: 1737057200,
      nanos: 456789000,
    },
    model_name: "google/gemma-7b-instruct",
    container: {
      base_image: "nvcr.io/0514617533638406/production/google-gemma-7b:1.0",
      command_args: ["./run_server.sh"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/generate",
      inference_port: 8000,
      health_url_path: "/healthz",
    },
    hardware_requirements: [],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-004",
    },
  },
  {
    model_id: "model-2bWaM5kJt8PvN4xR7HsY9Gc",
    create_time: {
      seconds: 1737057300,
      nanos: 567890000,
    },
    update_time: {
      seconds: 1737057300,
      nanos: 567890000,
    },
    model_name: "cohere/command-r",
    container: {
      base_image: "nvcr.io/0514617533638406/production/cohere-command-r:2.1",
      command_args: ["python", "-m", "server.main"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/generate",
      inference_port: 8000,
      health_url_path: "/v1/ready",
    },
    hardware_requirements: [
      {
        min_vram_gb: "80GB",
        supported_gpus: [
          {
            count: 8,
            memory: "80GB",
            name: "A100",
          },
          {
            count: 4,
            memory: "80GB",
            name: "H100",
          },
        ],
      },
    ],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-005",
    },
  },
  {
    model_id: "model-1aVbL4hGq7NvM3wP6FtX8Dk",
    create_time: {
      seconds: 1737057400,
      nanos: 678901000,
    },
    update_time: {
      seconds: 1737057400,
      nanos: 678901000,
    },
    model_name: "stability/stable-diffusion-xl",
    container: {
      base_image: "nvcr.io/0514617533638406/production/stability-sdxl:3.0",
      command_args: ["./start_api.sh"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/generation",
      inference_port: 8000,
      health_url_path: "/health",
    },
    hardware_requirements: [
      {
        min_vram_gb: "24GB",
        supported_gpus: [
          {
            count: 1,
            memory: "24GB",
            name: "A5000",
          },
          {
            count: 1,
            memory: "24GB",
            name: "RTX4090",
          },
        ],
      },
    ],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-006",
    },
  },
  {
    model_id: "model-8eYcK2nRt5UvH9wX4BsZ7Mf",
    create_time: {
      seconds: 1737057500,
      nanos: 789012000,
    },
    update_time: {
      seconds: 1737057500,
      nanos: 789012000,
    },
    model_name: "deepseek/deepseek-coder-33b-instruct",
    container: {
      base_image: "nvcr.io/0514617533638406/production/deepseek-coder:1.5",
      command_args: ["python", "serve.py"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/completions",
      inference_port: 8000,
      health_url_path: "/ready",
    },
    hardware_requirements: [],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-007",
    },
  },
  {
    model_id: "model-4dTgP7mKw2RvN5yB8HqX3Jc",
    create_time: {
      seconds: 1737057600,
      nanos: 890123000,
    },
    update_time: {
      seconds: 1737057600,
      nanos: 890123000,
    },
    model_name: "inflection/inflection-2-70b",
    container: {
      base_image: "nvcr.io/0514617533638406/production/inflection-2:1.3",
      command_args: ["sh", "-c", "./run.sh"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/chat",
      inference_port: 8000,
      health_url_path: "/health/live",
    },
    hardware_requirements: [
      {
        min_vram_gb: "80GB",
        supported_gpus: [
          {
            count: 4,
            memory: "80GB",
            name: "A100",
          },
          {
            count: 2,
            memory: "80GB",
            name: "H100",
          },
        ],
      },
    ],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-008",
    },
  },
  {
    model_id: "model-6fVhS9pLq4WvM2xK7GtY5Nb",
    create_time: {
      seconds: 1737057700,
      nanos: 901234000,
    },
    update_time: {
      seconds: 1737057700,
      nanos: 901234000,
    },
    model_name: "together/yi-34b-chat",
    container: {
      base_image: "nvcr.io/0514617533638406/production/together-yi-34b:1.0",
      command_args: ["python", "-m", "api_server"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/chat/completions",
      inference_port: 8000,
      health_url_path: "/health",
    },
    hardware_requirements: [
      {
        min_vram_gb: "40GB",
        supported_gpus: [
          {
            count: 2,
            memory: "40GB",
            name: "A100",
          },
          {
            count: 1,
            memory: "80GB",
            name: "H100",
          },
        ],
      },
    ],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-009",
    },
  },
  {
    model_id: "model-0zXjQ3nKt6RvH8wP5FmY2Bc",
    create_time: {
      seconds: 1737057800,
      nanos: 12345000,
    },
    update_time: {
      seconds: 1737057800,
      nanos: 12345000,
    },
    model_name: "perplexity/pplx-70b-chat",
    container: {
      base_image: "nvcr.io/0514617533638406/production/perplexity-70b:1.1",
      command_args: ["./start_server.sh"],
      registry: {},
    },
    inference_server: {
      inference_url_path: "/v1/chat",
      inference_port: 8000,
      health_url_path: "/healthcheck",
    },
    hardware_requirements: [],
    labels: {
      DeploymentProviderCredId: "nvcf-cred-id-010",
    },
  },
];

const DeploymentCreateWrapper: React.FC = () => {
  console.log("Route: /deployments/new");

  return (
    <>
      <DeploymentCreate />
    </>
  );
};

export default DeploymentCreateWrapper;
