import {
  AnimatePresence,
  motion,
  useIsPresent,
  HTMLMotionProps,
} from "framer-motion";
import React, { useEffect, useState } from "react";
import { Model } from "server";
import { ModelSelected } from "../DeploymentCreate";
import nim from "../../../../assets/img/png/nim.png";

interface ModelListProps {
  models: Model[];
  onModelSelect: (model: Model) => void;
}

const ModelList: React.FC<ModelListProps> = ({ models, onModelSelect }) => {
  return (
    <div className="w-full">
      <div className="space-y-4">
        <AnimatePresence mode="popLayout">
          {models.map((model) => (
            <ModelItem
              key={model.model_id}
              model={model}
              onClick={() => onModelSelect(model)}
            />
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

interface ModelItemProps {
  model: Model;
  onClick: () => void;
}

const ModelItem: React.FC<ModelItemProps> = ({ model, onClick }) => {
  const isPresent = useIsPresent();

  const animations: HTMLMotionProps<"div"> = {
    initial: { scale: 0.8, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0.8, opacity: 0 },
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 60,
      duration: 0.3,
    },
    layout: true,
    style: {
      position: isPresent ? ("static" as const) : ("absolute" as const),
      width: "100%",
    },
  };

  return (
    <motion.div {...animations}>
      <div
        onClick={onClick}
        className={`
          p-4 rounded-lg border cursor-pointer
          transition-colors duration-200
          border-zinc-800 hover:border-highlight
          bg-primaryBg
        `}
      >
        <div className="flex flex-col space-y-2">
          <h3 className="text-lg font-medium text-white">{model.model_name}</h3>
          <div className="text-sm text-zinc-400">
            {model.hardware_requirements?.map((req, index) => (
              <div key={index} className="flex items-center space-x-2">
                {req.supported_gpus?.length > 0 && (
                  <span>
                    Supported GPUs:{" "}
                    {req.supported_gpus
                      .map((gpu) => `${gpu.count}x${gpu.name}`)
                      .join(", ")}
                  </span>
                )}
              </div>
            ))}
          </div>
          {/* {model.labels && Object.keys(model.labels).length > 0 && (
            <div className="flex flex-wrap gap-2 mt-2">
              {Object.entries(model.labels).map(([key, value]) => (
                <span
                  key={key}
                  className="px-2 py-1 text-xs rounded-full bg-zinc-800 text-zinc-400"
                >
                  {key}: {value}
                </span>
              ))}
            </div>
          )} */}
          <div className="flex items-center space-x-2">
            <span className="px-2 py-1 text-xs rounded-full bg-zinc-800 text-zinc-400 flex items-center">
              <img
                src={nim}
                alt={"nim"}
                className="w-5 h-5 rounded-full mr-1"
              />
              <span className="text-xs font-medium">NVIDIA NIM</span>
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ModelList;
