import React from "react";

const Launchables: React.FC = () => {
  return (
    <div className="flex flex-col min-h-[50vh] pt-5 pl-24 pr-24">
      <div className="">
        <h1 className="text-3xl font-bold mb-2 text-white">Launchables</h1>
      </div>
      <hr className="w-[100%] mb-3 mt-3 dark:border dark:border-zinc-800" />
      <div className="flex flex-col">
        <h1 className="text-3xl font-bold mb-4 text-white">
          🚧 Under Construction 🚧
        </h1>
        <p className="text-secondary">
          This page is currently being built. Please check back later.
        </p>
      </div>
    </div>
  );
};

export default Launchables;
