import React, { useContext, useEffect, useMemo, useState } from "react";
import { deploymentContextDefaultValuese } from "tests/defaultValues";
import agent, {
  Deployment,
  DeploymentInstanceType,
  DeploymentLog,
  DeploymentMetrics,
} from "server";
import { OrgContext } from "./OrgContext";

export interface IDeploymentsContext {
  deployments: Deployment[];
  instanceTypes: DeploymentInstanceType[];
  reloadDeployments: (activeOrgId: string) => Promise<Deployment[] | null>;
  setDeployments: (deployments: Deployment[]) => void;
  getDeploymentInstanceTypes: (activeOrgId: string) => void;
  getDeploymentMetrics: (
    activeOrgId: string,
    deploymentId: string
  ) => Promise<DeploymentMetrics | null>;
  getDeploymentLogs: (
    activeOrgId: string,
    deploymentId: string
  ) => Promise<DeploymentLog[] | null>;
  loadingInstanceTypes: boolean;
  loadingDeployments: boolean;
}

export const DeploymentsContext = React.createContext<IDeploymentsContext>(
  deploymentContextDefaultValuese
);

interface Props {
  children: React.ReactNode;
}
const DeploymentsContextProvider: React.FC<Props> = ({ children }) => {
  const orgContext = useContext(OrgContext);
  const [deployments, setDeployments] = useState<Deployment[]>([]);
  const [loadingDeployments, setLoadingDeployments] = useState<boolean>(false);
  const [instanceTypes, setInstanceTypes] = useState<DeploymentInstanceType[]>(
    []
  );
  const [logsMap, setLogsMap] = useState<Record<string, DeploymentLog[]>>({});

  useEffect(() => {
    if (orgContext.activeOrgId) {
      getDeploymentInstanceTypes(orgContext.activeOrgId);
    }
  }, [orgContext.activeOrgId]);

  const [loadingInstanceTypes, setLoadingInstanceTypes] =
    useState<boolean>(false);
  const [metricsMap, setMetricsMap] =
    useState<Record<string, DeploymentMetrics>>();

  const reloadDeployments = async (
    activeOrgId: string
  ): Promise<Deployment[] | null> => {
    setLoadingDeployments(true);
    const res = await agent.Deployments.getDeployments(activeOrgId);
    if (res.success && res.data) {
      setDeployments(res.data);
      return res.data;
    }
    setLoadingDeployments(false);
    return null;
  };

  const getDeploymentInstanceTypes = async (activeOrgId: string) => {
    try {
      setLoadingInstanceTypes(true);
      const res = await agent.Deployments.getInstanceTypes(activeOrgId);
      if (res.success && res.data) {
        setInstanceTypes(res.data);
      }
    } catch (error) {
      console.error("Error fetching deployment instance types:", error);
    }
    setLoadingInstanceTypes(false);
  };

  const getDeploymentMetrics = async (
    orgId: string,
    deploymentId: string
  ): Promise<DeploymentMetrics | null> => {
    if (metricsMap && metricsMap[deploymentId]) {
      return metricsMap[deploymentId];
    }

    const res = await agent.Deployments.getDeploymentMetrics(
      orgId,
      deploymentId
    );
    if (res && res.data) {
      setMetricsMap((prev) => {
        return {
          ...prev,
          [deploymentId]: res.data,
        };
      });
      return res.data;
    } else {
      return null;
    }
  };

  const getDeploymentLogs = async (
    orgId: string,
    deploymentId: string
  ): Promise<DeploymentLog[] | null> => {
    if (logsMap && logsMap[deploymentId]) {
      return logsMap[deploymentId];
    }

    const res = await agent.Deployments.getDeploymentLogs(orgId, deploymentId);

    if (res && res.data) {
      setLogsMap((prev) => ({
        ...prev,
        [deploymentId]: res.data,
      }));
      return res.data;
    } else {
      return null;
    }
  };

  const providerData = {
    deployments,
    instanceTypes,
    reloadDeployments,
    getDeploymentInstanceTypes,
    getDeploymentMetrics,
    getDeploymentLogs,
    setDeployments,
    loadingInstanceTypes,
    loadingDeployments,
  };

  return (
    <DeploymentsContext.Provider value={providerData}>
      {children}
    </DeploymentsContext.Provider>
  );
};

export default DeploymentsContextProvider;
