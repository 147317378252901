import React, { useContext, useState } from "react";
import { OrgContext } from "contexts/OrgContext";
import FlatCard from "components/UI-lib/FlatCard";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/Graphs/Tabs";
import { allowedImageList } from "components/Environment/Create/utils";
import CustomImageSelector from "./CustomImageSelector";
import AllowedImageListSelector from "./AllowedImageListSelector";
import {
  ContainerSelected,
  parseDockerComposeUrl,
} from "components/Environment/shared/BuildTypes";
import { Button, InputField, Toggle } from "components/UI-lib";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ArrowUpTrayIcon, XCircleIcon } from "@heroicons/react/24/solid";
import agent from "server";
import { Tooltip } from "@mui/material";
import { Badge } from "components/Graphs/Badge";
import { replaceBlobWithRaw } from "components/utils";

export interface DockerComposeUploaderProps {
  containerSelected?: ContainerSelected;
  onSelectContainer: (container: ContainerSelected) => void;
}

interface Port {
  mode: string;
  target: number;
  published: string;
  protocol: string;
}

interface Service {
  name: string;
  image: string;
  ports: Port[];
  restart: string;
  scale: number;
}

interface Project {
  services: Service[];
}

interface ComposeConfig {
  project: Project;
}

const DockerComposeUploader: React.FC<DockerComposeUploaderProps> = ({
  containerSelected,
  onSelectContainer,
}) => {
  const [dockerComposeFile, setDockerComposeFile] = useState<string>("");
  const [forceJupyterInstall, setForceJupyterInstall] =
    useState<boolean>(false);

  const [loadingValidation, setLoadingValidation] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string>("");
  const [validationResponse, setValidationResponse] =
    useState<ComposeConfig | null>(null);

  const vaidateDockerCompose = async (fileUrl: string) => {
    setLoadingValidation(true);
    setValidationError("");
    setValidationResponse(null);
    const res = await agent.Workspaces.validateDockerCompose({
      fileUrl: replaceBlobWithRaw(fileUrl),
    });
    if (res.success && res.data) {
      setValidationResponse(res.data);
    } else {
      setValidationError(res.message);
    }
    setLoadingValidation(false);
  };

  return (
    <>
      <h1 className="text-lg text-white">Docker Compose Link</h1>
      <div className="flex flex-col mb-3">
        {!validationResponse ? (
          <>
            <div className="flex flex-col mb-5">
              <div className="flex flex-row w-full mt-1 items-center">
                <InputField
                  className="min-w-[425px]"
                  value={dockerComposeFile}
                  onChange={(val) => setDockerComposeFile(val)}
                  errorMessage=""
                  label=""
                  placeholder="Enter a Github or Gitlab Link to your docker compose file"
                />
                <Button
                  iconLeft={<ArrowUpTrayIcon className="w-5 h-5 mr-1" />}
                  className="ml-3 h-[38px]"
                  label="Upload and Validate"
                  disabled={!dockerComposeFile}
                  loading={loadingValidation}
                  onClick={() => {
                    vaidateDockerCompose(dockerComposeFile);
                  }}
                />
              </div>
              {validationError && (
                <div className="text-red-500 text-sm mt-1">
                  Error: {validationError}
                </div>
              )}
            </div>
            <Toggle
              checked={forceJupyterInstall}
              onChange={(forceJupyter) => {
                setForceJupyterInstall(forceJupyter);
              }}
              label="Preinstall Jupyter"
            />
          </>
        ) : (
          <>
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                <Tooltip title={dockerComposeFile} placement="top">
                  <h3 className="text-sm text-highlight">
                    {parseDockerComposeUrl(dockerComposeFile)}
                  </h3>
                </Tooltip>

                <Button
                  className="ml-3 h-[38px]"
                  label="Continue and Save"
                  onClick={() => {
                    onSelectContainer({
                      dockerCompose: {
                        fileUrl: replaceBlobWithRaw(dockerComposeFile),
                        jupyterInstall: forceJupyterInstall,
                      },
                    });
                  }}
                />
                <Button
                  className="ml-3 h-[38px]"
                  label="Reset"
                  type="secondary"
                  onClick={() => {
                    setValidationResponse(null);
                    setForceJupyterInstall(false);
                  }}
                />
              </div>
              {forceJupyterInstall && (
                <div className="mt-1">
                  <Badge variant="neutral">PreInstall Jupyter</Badge>
                </div>
              )}
            </div>
            <hr className="w-[100%] my-3 dark:border dark:border-zinc-800" />
            <h1 className="text-lg text-white">Services Preview:</h1>
            <div className="grid grid-cols-3 gap-4 mt-2">
              {validationResponse?.project.services?.map((service) => (
                <FlatCard
                  className="bg-secondaryBg p-4 w-full flex flex-row rounded-md border-2 border-gray-300 dark:border-zinc-800 h-full"
                  key={service.name}
                >
                  <div className="flex flex-col h-full">
                    <div className="flex flex-col">
                      <div className="text-lg">{service.name}</div>
                      <div className="text-xs flex-row flex">
                        <span className="font-bold mr-1">Image:</span>
                        <span className="truncate" title={service.image}>
                          {service.image}
                        </span>
                      </div>
                    </div>
                    {service.ports && service.ports.length > 0 && (
                      <div className="text-xs">
                        <span className="font-bold mr-1">Ports:</span>
                        <span className="">
                          {service.ports
                            .map((port) => port.published)
                            .join(", ")}
                        </span>
                      </div>
                    )}
                  </div>
                </FlatCard>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DockerComposeUploader;
