import React, { useContext, useEffect, useState } from "react";
import Button from "components/UI-lib/Button";
import { OrgContext } from "contexts/OrgContext";
import { UserContext } from "contexts/UserContext";
import DynamicBadge from "components/UI-lib/DynamicBadge";
import InputField from "components/UI-lib/InputField";
import FlatCard from "components/UI-lib/FlatCard";
import { Transition } from "@headlessui/react";
import ComputePicker from "components/Environment/Create/ComputePicker";
import ContainerSelectorWrapper from "../Create/ContainerSelectorWrapper";
import NotebookUploader from "../../Create/NotebookUploader";
import ExposePorts from "../../Create/ExposePorts";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import {
  ContainerSelected,
  isContainerEquals,
} from "components/Environment/shared/BuildTypes";
import agent, {
  CreateLaunchableRequest,
  LaunchableBuildRequest,
  LaunchableCreateWorkspaceRequest,
  LaunchableFileRequest,
  LaunchablePort,
  ModifyLaunchableRequest,
} from "server";
import { classNames, isValidFileUrl } from "components/utils";
import { createFileRequest, transformPortListToMap } from "../utils";
import {
  hasVerbBaseImageContainer,
  usesVerbYaml,
} from "components/Environment/Create/utils";
import { buildDefaultVerbYamlConfig } from "components/Verb/utils";
import Modal from "components/Modals/Modal";
import BuilderView from "../Create/BuilderView";
import { LaunchableContext } from "contexts/LaunchableContext";

interface EditLaunchableModalProps {
  isOpen: boolean;
  onClose: () => void;
  launchableId: string;
  initialData?: {
    name: string;
    instance?: GPUInstanceType;
    storage: string;
    container?: ContainerSelected;
    pythonVersion: string;
    cudaVersion: string;
    file: string;
    ports?: LaunchablePort[];
  };
}

const EditLaunchableModal: React.FC<EditLaunchableModalProps> = ({
  isOpen,
  onClose,
  launchableId,
  initialData,
}) => {
  const orgContext = useContext(OrgContext);
  const launchableContext = useContext(LaunchableContext);

  const [isLoading, setIsLoading] = useState(false);
  // State management
  const [name, setName] = useState(initialData?.name || "");
  const [errorMessage, setErrorMessage] = useState("");

  // Section visibility
  const [activeSection, setActiveSection] = useState<
    "compute" | "container" | "files" | "ports" | null
  >(null);

  // Compute state
  const [instance, setInstance] = useState<GPUInstanceType | undefined>(
    initialData?.instance
  );
  const [storage, setStorage] = useState(initialData?.storage || "500");
  const [savedInstance, setSavedInstance] = useState<
    GPUInstanceType | undefined
  >(initialData?.instance);
  const [savedStorage, setSavedStorage] = useState(
    initialData?.storage || "500"
  );

  // Container state
  const [container, setContainer] = useState<ContainerSelected | undefined>(
    initialData?.container
  );
  const [pythonVersion, setPythonVersion] = useState(
    initialData?.pythonVersion || "3.10"
  );
  const [cudaVersion, setCudaVersion] = useState(
    initialData?.cudaVersion || "12.0.1"
  );
  const [savedContainer, setSavedContainer] = useState<
    ContainerSelected | undefined
  >(initialData?.container);

  // File state
  const [notebookFile, setNotebookFile] = useState(initialData?.file || "");
  const [savedFile, setFile] = useState(initialData?.file || "");
  const [isFileValid, setIsFileValid] = useState(true);

  // Ports state
  const [ports, setPorts] = useState<LaunchablePort[]>(
    initialData?.ports || []
  );
  const [savedPorts, setSavedPorts] = useState<LaunchablePort[]>(
    initialData?.ports || []
  );

  const isValidFile = async (file: string): Promise<boolean> => {
    const errorMessage = await isValidFileUrl(file);
    return !errorMessage;
  };

  const handleSectionChange = (
    section: "compute" | "container" | "files" | "ports" | null
  ) => {
    setActiveSection(section);
  };

  const handleComputeSave = () => {
    if (instance) {
      setSavedInstance(instance);
      setSavedStorage(storage);
    }
    setActiveSection(null);
  };

  const handleContainerSave = () => {
    setSavedContainer(container);
    setActiveSection(null);
  };

  const handlePortSave = () => {
    setSavedPorts(ports);
    setActiveSection(null);
  };

  const handleNotebookSave = async () => {
    const validCheck = await isValidFile(notebookFile);
    if (!validCheck && notebookFile !== "") {
      setIsFileValid(false);
      return;
    }
    setFile(notebookFile);
    setActiveSection(null);
  };

  const makeUpdateLaunchableRequest = (): CreateLaunchableRequest => {
    if (!savedInstance) {
      throw new Error("Instance is required");
    }

    const createWorkspaceRequest: LaunchableCreateWorkspaceRequest = {
      instanceType: savedInstance.type,
      workspaceGroupId: savedInstance.workspace_groups[0].id,
      storage: savedInstance.elastic_root_volume ? savedStorage : "",
    };

    const buildRequest: LaunchableBuildRequest = {
      ports: savedPorts,
    };

    if (!!savedContainer?.vmBuild) {
      buildRequest.vmBuild = {
        forceJupyterInstall: !!savedContainer.vmBuild.forceJupyterInstall,
      };
    }

    let jupyterPort = name.toLowerCase().includes("blueprint") ? 8887 : 8888;

    if (!!savedContainer?.verbBuild) {
      const customYaml = buildDefaultVerbYamlConfig(
        hasVerbBaseImageContainer(savedContainer)
          ? savedContainer.verbBuild.containerUrl
          : "",
        transformPortListToMap(savedPorts),
        cudaVersion,
        pythonVersion,
        jupyterPort,
        true
      );
      buildRequest.verbBuild = {
        verbYaml: customYaml,
      };
    }

    if (savedContainer?.customContainer) {
      buildRequest.containerBuild = {
        containerUrl: savedContainer.customContainer.containerUrl,
        entryPoint: savedContainer.customContainer.entryPoint,
      };
    }

    if (savedContainer?.dockerCompose) {
      buildRequest.dockerCompose = {
        fileUrl: savedContainer.dockerCompose.fileUrl,
        jupyterInstall: savedContainer.dockerCompose.jupyterInstall,
      };
    }

    let launchableFile: LaunchableFileRequest | null = null;
    const fileRequest = createFileRequest(savedFile, savedContainer);
    if (fileRequest) {
      launchableFile = fileRequest[0];
    }

    return {
      name,
      createWorkspaceRequest,
      buildRequest,
      file: launchableFile,
    };
  };

  const handleUpdate = async () => {
    try {
      setErrorMessage("");
      if (!savedInstance) {
        setErrorMessage("An instance is required");
        return;
      }
      const updateRequest = makeUpdateLaunchableRequest();
      const modifyRequest: ModifyLaunchableRequest = {
        id: launchableId,
        createWorkspaceRequest: updateRequest.createWorkspaceRequest,
        buildRequest: updateRequest.buildRequest,
        file: updateRequest.file,
      };

      setIsLoading(true);
      const res = await agent.Launchables.editEnvironmentLaunchable(
        orgContext.activeOrgId,
        modifyRequest
      );
      if (res.success) {
        await launchableContext.refreshActiveLaunchables();
        setIsLoading(false);
        onClose();
      } else {
        setIsLoading(false);
        setErrorMessage("Failed to update launchable. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  return (
    <Modal
      setOpen={onClose}
      isOpen={isOpen}
      onClose={onClose}
      title=""
      width={"max-w-7xl"}
      hideCancel={true}
      hideSuccess={true}
      body={
        <>
          <div className="flex flex-col">
            <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-8">
              Edit Launchable
            </h1>
            <div className="w-full">
              <div className="space-y-4">
                <div>
                  <BuilderView
                    clickedGenerate={false}
                    launchableURL={`https://console.brev.dev/launchable/deploy?launchableID=${launchableId}`}
                    savedInstance={savedInstance}
                    savedStorage={savedStorage}
                    savedContainer={savedContainer}
                    pythonVersion={pythonVersion}
                    cudaVersion={cudaVersion}
                    savedFile={savedFile}
                    savedPorts={savedPorts}
                    animateCompute={false}
                    animateContainer={false}
                    animatePorts={false}
                    animateNotebook={false}
                    animationCompleted={false}
                  />
                </div>

                <div className="flex flex-wrap gap-2">
                  <DynamicBadge
                    text="Compute"
                    isActive={!!savedInstance}
                    pending={activeSection === "compute"}
                    setIsActive={() => handleSectionChange("compute")}
                    className="cursor-pointer"
                  />
                  <DynamicBadge
                    text="Container"
                    isActive={!!savedContainer}
                    pending={activeSection === "container"}
                    setIsActive={() => handleSectionChange("container")}
                    className="cursor-pointer"
                  />
                  <DynamicBadge
                    text="Files"
                    isActive={!!savedFile}
                    pending={activeSection === "files"}
                    setIsActive={() => handleSectionChange("files")}
                    className="cursor-pointer"
                  />
                  <DynamicBadge
                    text="Expose Ports"
                    isActive={savedPorts.length > 0}
                    pending={activeSection === "ports"}
                    setIsActive={() => handleSectionChange("ports")}
                    className="cursor-pointer"
                  />
                </div>

                {activeSection === "compute" && (
                  <FlatCard noBorder>
                    <ComputePicker
                      selectedInstance={instance}
                      setSelectedInstance={setInstance}
                      setStorage={setStorage}
                      storage={storage}
                      disableCpu={true}
                    />
                    <Button
                      onClick={handleComputeSave}
                      disabled={!instance || !storage}
                      className="mt-4"
                      label="Save Compute"
                    />
                  </FlatCard>
                )}

                {activeSection === "container" && (
                  <FlatCard noBorder>
                    <ContainerSelectorWrapper
                      container={container}
                      setContainer={setContainer}
                      cudaVersion={cudaVersion}
                      setCudaVersion={setCudaVersion}
                      pythonVersion={pythonVersion}
                      setPythonVersion={setPythonVersion}
                      hideCustomCredentials={true}
                    />
                    <Button
                      label="Save Container"
                      onClick={handleContainerSave}
                      disabled={isContainerEquals(container, savedContainer)}
                      className="mt-4"
                    />
                  </FlatCard>
                )}

                {activeSection === "files" && (
                  <FlatCard noBorder>
                    <NotebookUploader
                      file={notebookFile}
                      setFile={setNotebookFile}
                      isFileValid={isFileValid}
                      setIsFileValid={setIsFileValid}
                    />
                    <Button
                      onClick={handleNotebookSave}
                      className="mt-4"
                      label="Save File"
                    />
                  </FlatCard>
                )}

                {activeSection === "ports" && (
                  <FlatCard noBorder>
                    <ExposePorts ports={ports} setPorts={setPorts} />
                    <Button
                      label="Save Ports"
                      onClick={handlePortSave}
                      disabled={
                        ports.length === 0 ||
                        ports.some((p) => !p.name || !p.port)
                      }
                      className="mt-4"
                    />
                  </FlatCard>
                )}
              </div>
            </div>
            <div className="flex w-full gap-3 justify-end">
              <Button
                label="Cancel"
                type="secondary"
                disabled={isLoading}
                onClick={() => {
                  onClose();
                }}
              />
              <Button
                label="Save Launchable"
                type="primary"
                loading={isLoading}
                onClick={() => {
                  handleUpdate();
                }}
              />
            </div>
            {errorMessage && (
              <div className="text-red-500 text-sm font-bold mt-3 flex justify-end">
                {errorMessage}
              </div>
            )}
          </div>
        </>
      }
    />
  );
};

export default EditLaunchableModal;
