import { Button, InputField } from "components/UI-lib";
import React, { useState } from "react";
import agent from "server";

const Credits: React.FC = () => {
  const [credits, setCredits] = useState("");
  const [organization, setOrganization] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const addCredits = async () => {
    setLoading(true);
    setError("");
    setSuccess("");
    const res = await agent.Admin.addCreditsToOrganization({
      amountUSD: credits,
      organizationId: organization,
    });
    if (res.success) {
      setSuccess("Credits added successfully");
    } else {
      const errorMessage = res.errorMessage || "An error occurred";
      setError(errorMessage);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col min-h-[50vh] pt-5 pl-24 pr-24">
      <div className="">
        <h1 className="text-3xl font-bold mb-2 text-white">Credits</h1>
      </div>
      <hr className="w-[100%] mb-3 mt-3 dark:border dark:border-zinc-800" />
      <div className="flex flex-col">
        <h2 className="text-xl font-bold mb-4 text-white">
          Add Credits to an Organization
        </h2>
        <div className="flex flex-col">
          <div className="flex flex-row gap-6">
            <InputField
              label="1. Enter a dollar amount"
              placeholder="0.00"
              value={credits}
              onChange={(e) => {
                // Only allow numbers and one decimal point
                const regex = /^\d*\.?\d*$/;
                if (regex.test(e) || e === "") {
                  setCredits(e);
                }
              }}
              errorMessage=""
            />
            <InputField
              label="2. Enter an organization id"
              placeholder=""
              value={organization}
              onChange={(e) => setOrganization(e)}
              errorMessage=""
            />
            <div className="flex flex-row items-end">
              <Button
                label="Add Credits"
                className="h-10"
                onClick={() => addCredits()}
                loading={loading}
                disabled={loading || !credits || !organization}
              />
            </div>
          </div>
          {error && <div className="mt-2 text-red-500 text-sm">{error}</div>}
          {success && (
            <div className="mt-2 text-highlight text-sm">{success}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Credits;
