import { OrgContext } from "contexts/OrgContext";
// import { ToastContext } from "contexts/ToastContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { classNames } from "components/utils";
import Billing from "./Billing";
import Credits from "../Credits";
import { BillingContext } from "contexts/BillingContext";
import { Coupons } from "components/OrgPages/Billing/Legacy-usage-arrears/Coupons";
import useQueryParam from "useQueryParams";
import { ConnectCardWrapper } from "components/OrgPages/Billing/ConnectCard";

interface LocationState {
  segment?: string;
}

const LegacyBilling: React.FC = () => {
  // const [organization, setOrganization] = useState<Organization | null>(null);
  // const [loading, setLoading] = useState(true);
  const { state } = useLocation<LocationState>();

  const orgContext = useContext(OrgContext);
  const billingRef = React.useRef<null | HTMLDivElement>(null);

  const billingContext = useContext(BillingContext);
  const [withCoupon, setWithCoupon] = useQueryParam("coupon", "");

  useEffect(() => {
    document.title = "Organization Billing | Brev.dev";
    if (state?.segment === "billing") {
      billingRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  if (orgContext.activeOrg) {
    return (
      <>
        <div className="mt-3"></div>

        <div className={classNames("w-full")}>
          <ConnectCardWrapper />
        </div>

        <div className="mt-3"></div>
        {billingContext.billingProfile?.billing_type === "credit" && (
          <div
            className={classNames(
              "flex flex-col justify-start items-start w-full"
            )}
          >
            <Credits withCoupon={withCoupon} />
          </div>
        )}
        <div className={classNames("w-full")}>
          {billingContext.billingProfile?.billing_type === "usage-arrears" && (
            <Coupons
              billingProfileLoading={billingContext.billingProfileLoading}
            />
          )}
        </div>
        <div className="mt-3"></div>
        <div
          className={classNames(
            "flex flex-col justify-start items-start w-full"
          )}
          ref={billingRef}
        >
          <Billing />
        </div>
      </>
    );
  }
  // when clicking the settings button, reponse time is so fast the seeing loading bars feels off
  return <div />;
};

export default LegacyBilling;
