import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import LaunchableConfigurationView from "./LaunchableConfigurationView";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { parsePortMappingEntry } from "components/Environment/utils";
import agent from "server";
import { shortenFileName } from "../../utils";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";

interface LaunchableConfigurationViewFromParamsProps {
  instanceType: string | null;
  workspaceGroupId: string | null;
  containerSelected: ContainerSelected | null;
  forceJupyterInstall: boolean | null;
  cuda: string | null;
  python: string | null;
  diskStorage: string | null;
  file: string | null;
  ports: Record<string, string> | null;
  setInstance: (instanceObject: GPUInstanceType) => void;
  disabled?: boolean;
}

const LaunchableConfigurationViewFromParams: React.FC<
  LaunchableConfigurationViewFromParamsProps
> = ({
  instanceType,
  workspaceGroupId,
  containerSelected,
  forceJupyterInstall,
  cuda,
  python,
  diskStorage,
  file,
  ports,
  setInstance,
  disabled,
}) => {
  const history = useHistory();
  const [instanceObject, setInstanceObject] = useState<GPUInstanceType | null>(
    null
  );
  const [loadingInstance, setLoadingInstance] = useState(false);
  const [shortFileName, setShortFileName] = useState("");

  const getInstanceFromType = async (
    instanceType: string,
    workspaceGroupID: string
  ): Promise<GPUInstanceType[] | null> => {
    const res = await agent.Instances.getInstanceByType(
      instanceType,
      workspaceGroupID
    );
    if (res.success && res.data && res.data.length > 0) {
      return res.data;
    }
    return null;
  };

  const getInstanceObject = async (
    Instance: string | null,
    WorkspaceGroupID: string | null
  ) => {
    const parsedInstance = parseInstance(Instance);
    if (!parsedInstance || !WorkspaceGroupID) {
      return null;
    }
    setLoadingInstance(true);
    const instanceTypes = await getInstanceFromType(
      parsedInstance.instanceType,
      WorkspaceGroupID
    );
    setLoadingInstance(false);
    if (instanceTypes) {
      setInstanceObject(instanceTypes[0]);
      setInstance(instanceTypes[0]);
    }
  };

  useEffect(() => {
    getInstanceObject(instanceType, workspaceGroupId);
  }, [instanceType, workspaceGroupId]);

  const parseInstance = (Instance: string | null) => {
    if (!Instance) {
      return null;
    }
    const [chipTypeOrInstanceType, instanceType] = Instance.split("@");
    if (instanceType) {
      return {
        chipType: chipTypeOrInstanceType,
        instanceType: instanceType,
      };
    } else {
      return {
        instanceType: chipTypeOrInstanceType,
      };
    }
  };

  useEffect(() => {
    async function updateShortFileName() {
      if (file) {
        const shortName = await shortenFileName(file || "");
        setShortFileName(shortName);
      } else {
        setShortFileName("");
      }
    }
    updateShortFileName();
  }, [file]);

  return (
    <LaunchableConfigurationView
      instanceObject={instanceObject}
      loadingInstance={loadingInstance}
      diskStorage={diskStorage}
      file={file}
      shortFileName={shortFileName}
      python={python}
      cuda={cuda}
      containerSelected={containerSelected}
      forceJupyterInstall={forceJupyterInstall}
      ports={ports}
      disabled={disabled}
    />
  );
};

export default LaunchableConfigurationViewFromParams;
