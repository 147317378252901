import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { OrgContext } from "contexts/OrgContext";
import { WorkspaceContext } from "contexts/WorkspaceContext";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import {
  areStringListsEqual,
  getPathFromUrl,
  isCheckHealthSame,
  mapValuesToArray,
  replaceBlobWithRaw,
  timeout,
  yamlRawDump,
  validateAndGetFileType,
} from "components/utils";
import IWorkspace, {
  IApplication,
  IFileObject,
  IFileType,
  VerbBuildStatus,
  WorkspaceStatus,
} from "models/Workspace.model";
import agent, {
  AddFileAndBuildVerbContainerReqBody,
  BuildVerbRes,
  LaunchableFileRequest,
} from "server";
import { v4 as uuid } from "uuid";
import { usePollingEffect } from "components/hooks";
import Workspace from "../entities/Workspace.entity";
import {
  VERB_FAILED_PHRASE,
  VERB_FINISHED_PHRASE,
} from "components/Environment/Settings/utils";
import { NotificationContext } from "contexts/NotificationContext";
import { UserContext } from "contexts/UserContext";
import { BillingContext } from "./BillingContext";
import {
  allPublicCloudsName,
  handleIsPaymentSetup,
  hasVerbBaseImageContainer,
  usesVerbYaml,
} from "components/Environment/Create/utils";
import { buildDefaultVerbYamlConfig } from "components/Verb/utils";
import yaml from "js-yaml";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { parsePortMappingEntry } from "components/Environment/utils";
import {
  ProcessedLaunchableData,
  createFileRequest,
} from "components/Environment/Launchables/utils";
import {
  ContainerSelected,
  parseDockerComposeUrl,
} from "components/Environment/shared/BuildTypes";
import { s } from "vite/dist/node/types.d-aGj9QkWt";

export enum DeployState {
  UNSET = "",
  Deploying = "deploying",
  Dependencies = "building",
  Ready = "ready",
}

const jupyterSteps = [
  {
    id: 0,
    name: `Deploying GPU`,
    abbreviatedName: "Deployed GPU",
    abbreviatedName_future: "Deploy GPU",
    state: DeployState.Deploying,
  },
  {
    id: 1,
    name: "Installing Python 3.10 & CUDA 12.2",
    abbreviatedName: "Installed",
    abbreviatedName_future: "Install Software",
    state: DeployState.Dependencies,
  },
  {
    id: 2,
    name: "Ready",
    abbreviatedName: "Access Notebook",
    abbreviatedName_future: "Access Notebook",
    state: DeployState.Ready,
  },
];

const gpuOnlySteps = [
  {
    id: 0,
    name: `Deploying GPU`,
    abbreviatedName: "Deployed GPU",
    abbreviatedName_future: "Deploy GPU",
    state: DeployState.Deploying,
  },
  {
    id: 1,
    name: "Installing Software",
    abbreviatedName: "Installed",
    abbreviatedName_future: "Install Software",
    state: DeployState.Dependencies,
  },
  {
    id: 2,
    name: "Ready",
    abbreviatedName: "Access GPU",
    abbreviatedName_future: "Access GPU",
    state: DeployState.Ready,
  },
];

interface OnClickVerbConfiguration {
  baseImage: string;
  cuda: string;
}

//This is to not duplicate the event properties in the instance create
function pruneAndTransfromExtraEventProperties(
  extraEventProperties: Record<string, any>
): Record<string, any> {
  const eventProperties = { ...extraEventProperties };
  //Prune
  if (eventProperties["workspaceGroupID"])
    delete eventProperties["workspaceGroupID"];
  //Transform
  if (eventProperties["launchableCreatedByOrgID"]) {
    eventProperties["launchableCreatedByOrgId"] =
      eventProperties["launchableCreatedByOrgID"];
    delete eventProperties["launchableCreatedByOrgID"];
  }
  if (eventProperties["launchableCreatedByUserID"]) {
    eventProperties["launchableCreatedByUserId"] =
      eventProperties["launchableCreatedByUserID"];
    delete eventProperties["launchableCreatedByUserID"];
  }
  if (eventProperties["launchableID"]) {
    eventProperties["launchableId"] = eventProperties["launchableID"];
    delete eventProperties["launchableID"];
  }
  if (eventProperties["launchableRawURL"]) {
    eventProperties["launchableRawUrl"] = eventProperties["launchableRawURL"];
    delete eventProperties["launchableRawURL"];
  }

  return eventProperties;
}

const oneClickDeployDefaultValues = {
  activeStep: DeployState.Deploying,
  setActiveStep: (step: DeployState) => null,
  steps: [],
  readyAction: () => null,
  startDeployment: (
    instance,
    storage,
    instanceName,
    file,
    baseImage,
    cuda,
    python,
    ports: Record<string, string>,
    extraEventProperties: Record<string, any>
  ) => null,
  startDeploymentFromLaunchable: (queryParams) => null,
  setIsFinished: (isFinished: boolean) => null,
  isFinished: false,
  reset: () => null,
  setShowDeploying: (isShowing: boolean) => null,
  showDeploying: false,
  showConnectCloudsOrCardModal: false,
  setShowConnectCloudsOrCardModal: (show: boolean) => null,
  noCardOnFile: true,
  setNoCardOnFile: (noCardOnFile: boolean) => null,
  selectedCloudName: allPublicCloudsName,
  setSelectedCloudName: (selectedCloudName: string) => null,
  workspace: undefined,
  instanceTypeObject: undefined,
  requestedDiskStorage: 128,
  initLoading: false,
};

interface OneClickContext {
  activeStep: DeployState;
  setActiveStep: (step: DeployState) => void;
  steps: any[];
  readyAction: () => void;
  startDeployment: (
    instance,
    storage,
    instanceName,
    file,
    baseImage,
    cuda,
    python,
    ports: Record<string, string>,
    extraEventProperties: Record<string, any>
  ) => void;
  startDeploymentFromLaunchable: (
    launchableData: ProcessedLaunchableData | null,
    extraEventProperties
  ) => void;
  setIsFinished: (isFinished: boolean) => void;
  isFinished: boolean;
  reset: () => void;
  setShowDeploying: (isShowing: boolean) => void;
  showDeploying: boolean;
  showConnectCloudsOrCardModal: boolean;
  setShowConnectCloudsOrCardModal: (show: boolean) => void;
  noCardOnFile: boolean;
  setNoCardOnFile: (noCardOnFile: boolean) => void;
  selectedCloudName: string;
  setSelectedCloudName: (selectedCloudName: string) => void;
  workspace: IWorkspace | undefined;
  instanceTypeObject: GPUInstanceType | undefined;
  requestedDiskStorage: number;
  initLoading: boolean;
}

const pollInstanceUntilSuccess = async (
  workspaceID: string,
  interval = 5000,
  timeout = 60000 * 10
) => {
  let elapsedTime = 0;

  while (elapsedTime < timeout) {
    try {
      const res = await agent.Workspaces.get(workspaceID);
      if (
        res.success &&
        res.data &&
        res.data.status === WorkspaceStatus.Running
      ) {
        return res.data;
      } else {
        throw new Error("Workspace not ready yet");
      }
    } catch (error) {
      console.error("Error polling API:", error);

      // Wait for the specified interval before retrying
      await new Promise((resolve) => setTimeout(resolve, interval));
      elapsedTime += interval;
    }
  }

  // If the loop exits due to timeout, throw an error
  throw new Error("Polling timed out");
};

export const OneClickDeployContext = React.createContext<OneClickContext>(
  oneClickDeployDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const OneClickDeployContextProvider: React.FC<Props> = ({ children }) => {
  const orgContext = useContext(OrgContext);
  const workspaceContext = useContext(WorkspaceContext);
  const notificationContext = useContext(NotificationContext);
  const userContext = useContext(UserContext);
  const query = new URLSearchParams(useLocation().search);
  // Flag to determine if the new logic should be used.
  // https://us.posthog.com/project/22059/feature_flags/44359
  const flagUseTunnelStatusForNotebookHealth = useFeatureFlagEnabled(
    "use-tunnel-status-for-notebook-health"
  );

  const [showDeploying, setShowDeploying] = useState(false);
  const [isFinished, setIsFinished] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [logFileContent, setLogFileContent] = useState("");
  const [verbBuildLogFilePath, setVerbBuildLogFilePath] = useState("");
  const billingContext = useContext(BillingContext);
  const [showConnectCloudsOrCardModal, setShowConnectCloudsOrCardModal] =
    useState(false);
  const [noCardOnFile, setNoCardOnFile] = useState(false);
  const [selectedCloudName, setSelectedCloudName] =
    useState(allPublicCloudsName);

  const [name, setName] = useState("");
  const [initLoading, setInitLoading] = useState(false);
  const [steps, setSteps] = useState(jupyterSteps);
  const [isGPUOnly, setIsGPUOnly] = useState(false);
  const history = useHistory();

  const reset = () => {
    setName("");
    setInitLoading(false);
    setActiveStep(DeployState.UNSET);
    setIsFinished(true);
    setJupyterApp(undefined);
    setWorkspace(undefined);
    setLogFileContent("");
    setVerbBuildLogFilePath("");
    setShowDeploying(false);
    setErrorMessage("");
    setIsGPUOnly(false);
  };

  const updateDependencyList = (newName: string, steps: any[]) => {
    setSteps(
      steps.map((item) => (item.id === 1 ? { ...item, name: newName } : item))
    );
  };

  const maybeIncrementName = (baseName: string, workspaces: IWorkspace[]) => {
    let counter = 1;
    let newName = baseName;

    const namesSet = new Set(workspaces.map((obj) => obj.name));
    if (namesSet.has(newName)) {
      while (namesSet.has(newName + `-${counter}`)) {
        counter++;
      }
      newName = `${baseName}-${counter}`;
    }

    return newName;
  };

  const normalizeName = (name: string): string => {
    return name.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
  };

  const [workspace, setWorkspace] = useState<IWorkspace | undefined>(undefined);
  const [activeStep, setActiveStep] = useState(DeployState.UNSET);
  const [jupyterApp, setJupyterApp] = useState<IApplication>();
  const [requestedDiskStorage, setRequestedDiskStorage] = useState(128);
  const [instanceTypeObject, setInstanceTypeObject] =
    useState<GPUInstanceType>();

  useEffect(() => {
    handleIsPaymentSetup(selectedCloudName, orgContext.activeOrgId).then(
      (isPaymentSetUp) => setNoCardOnFile(!isPaymentSetUp)
    );
  }, [orgContext.workspaceGroups, selectedCloudName]);

  const parseInstance = (providedInstance: string | null) => {
    if (!providedInstance) {
      return null;
    }
    const [chipTypeOrInstanceType, instanceType] = providedInstance.split("@");
    if (instanceType) {
      return {
        chipType: chipTypeOrInstanceType,
        instanceType: instanceType,
      };
    } else {
      return {
        instanceType: chipTypeOrInstanceType,
      };
    }
  };

  const transformLaunchableLabels = (extraEventProperties: any) => {
    return {
      launchableId: extraEventProperties.launchableID,
      launchableInstanceType: extraEventProperties.launchableInstanceType,
      workspaceGroupId: extraEventProperties.workspaceGroupID,
      launchableCreatedByUserId: extraEventProperties.launchableCreatedByUserID,
      launchableCreatedByOrgId: extraEventProperties.launchableCreatedByOrgID,
      launchableRawURL: extraEventProperties.launchableRawURL,
    };
  };

  const startDeploymentFromLaunchable = async (
    launchableData: ProcessedLaunchableData | null,
    extraEventProperties
  ) => {
    console.log("Starting deployment from launchable data", launchableData);
    if (!billingContext.billingProfile) {
      onFail(
        "Billing Profile hasn't loaded yet, Please wait a moment and try again"
      );
      reset();
      return;
    }

    setInitLoading(true);
    let billingProfile = billingContext.billingProfile;
    if (launchableData?.couponCode !== "") {
      const res = await billingContext.redeeemCode(
        launchableData?.couponCode || ""
      );
      if (res.status === "error") {
        console.log("Failed to redeem code", res.message);
        // We should continue if there is an error because the code might not be valid because it's already been redeemed
        // onFail(`Failed to auto redeem code.\n${res.message}`);
      } else {
        const billingProf = await billingContext.fetchBillingProfile();
        if (billingProf) {
          billingProfile = billingProf;
        } else {
          onFail(
            "Failed to update billing profile, Refresh and please try again"
          );
          reset();
          return;
        }
      }
    }

    if (
      (billingProfile?.billing_type === "usage-arrears" && noCardOnFile) ||
      (billingProfile?.billing_type === "credit" &&
        (billingProfile?.credit_details?.remaining_credits || 0) <= 0)
    ) {
      setShowConnectCloudsOrCardModal(true);
      reset();
      return;
    }

    const workspaceRes = await workspaceContext.setup(
      orgContext.activeOrgId || "",
      false
    );
    let workspaceName = launchableData?.name || uuid().substring(0, 6);
    if (workspaceRes && workspaceRes.data) {
      const normalizedName = normalizeName(workspaceName);
      workspaceName = maybeIncrementName(normalizedName, workspaceRes.data);
    } else {
      workspaceName += "-" + uuid().substring(0, 6);
    }

    if (launchableData?.diskStorage) {
      setRequestedDiskStorage(parseInt(launchableData?.diskStorage));
    }
    setName(workspaceName);
    setShowDeploying(true);
    setInitLoading(false);
    setActiveStep(DeployState.Deploying);
    setErrorMessage("");
    setIsFinished(false);

    agent.Brevent.track({
      eventName: "One Click Deploy Started",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
        notebookName: workspaceName,
        ...extraEventProperties,
      },
    });

    //Could be multiple so revisit later to still show nav bar if multiple one clicks active
    await startDeployment(
      launchableData?.instanceType,
      launchableData?.diskStorage,
      workspaceName,
      launchableData?.file || "",
      launchableData?.containerSelected,
      launchableData?.cuda,
      launchableData?.python,
      launchableData?.ports || null,
      extraEventProperties
    );
  };

  const startDeployment = async (
    instanceType,
    storage,
    instanceName,
    file = "",
    containerSelected: ContainerSelected = {
      verbBuild: {
        containerUrl: "Default",
      },
    },
    cuda,
    python,
    portMappings: Record<string, string> | null,
    extraEventProperties: Record<string, any> | null
  ) => {
    //This to help backwards compatible with oldInstanceType that uses the format chipType@instanceType
    const parsedInstance = parseInstance(instanceType);

    const selectedInstanceTypeObject = orgContext.allInstancesAvailable.find(
      (instance) => instance.type === parsedInstance?.instanceType
    );
    console.log("Selected Instance Type Object", selectedInstanceTypeObject);
    setInstanceTypeObject(selectedInstanceTypeObject);
    if (!selectedInstanceTypeObject) {
      onFail("No instance type found");
      console.error("No instance type found");
      return;
    }

    const workspaceGroup = selectedInstanceTypeObject?.workspace_groups[0];

    if (
      !workspaceGroup ||
      !workspaceGroup.locations ||
      workspaceGroup.locations.length === 0
    ) {
      onFail("No locations available for this workspace group");
      console.error("No locations available for this workspace group");
      return;
    }

    const region = selectedInstanceTypeObject?.location
      ? workspaceGroup.locations.find(
          (w) => w.name === selectedInstanceTypeObject?.location
        )
      : workspaceGroup.locations[0];

    let jupyterPort = 8888;

    if (
      instanceName.includes("blueprint") ||
      instanceName.includes("Blueprint")
    ) {
      jupyterPort = 8887;
    }

    const customYaml = buildDefaultVerbYamlConfig(
      hasVerbBaseImageContainer(containerSelected)
        ? containerSelected?.verbBuild?.containerUrl
        : "",
      portMappings,
      cuda && cuda !== "undefined" ? cuda : "12.0.1",
      python && python !== "undefined" ? python : "3.10",
      jupyterPort
    );

    let stepsToUse = steps;
    if (
      (containerSelected?.dockerCompose &&
        !containerSelected.dockerCompose.jupyterInstall) ||
      (containerSelected?.vmBuild &&
        !containerSelected.vmBuild.forceJupyterInstall)
    ) {
      setSteps(gpuOnlySteps);
      stepsToUse = gpuOnlySteps;
      setIsGPUOnly(true);
    }

    const fileRequest = createFileRequest(file, containerSelected);

    const res = await workspaceContext.createWorkspace(
      instanceName,
      orgContext.getActiveOrg()?.id || "",
      workspaceGroup.id, //  region?.workspaceGroup.id || "",
      "description",
      {}, // repo
      {}, // execs
      undefined, // ide config
      selectedInstanceTypeObject.type,
      "", // image
      workspaceGroup.platformType === "akash" && region?.name
        ? region.name
        : "", // region?.name || "",
      "",
      storage || "120",
      false, // Spot
      false, // isStoppable
      false, // on container false
      containerSelected?.verbBuild?.containerUrl ? customYaml : "", // verb yaml
      hasVerbBaseImageContainer(containerSelected)
        ? containerSelected?.verbBuild?.containerUrl
        : "", // verb container url if it's not default
      containerSelected?.customContainer
        ? {
            containerUrl: containerSelected?.customContainer?.containerUrl,
            entryPoint: containerSelected?.customContainer?.entryPoint,
            registry: containerSelected?.customContainer?.registry,
          }
        : undefined, // custom container
      !!containerSelected?.vmBuild, // vm build
      portMappings,
      fileRequest,
      transformLaunchableLabels(extraEventProperties),
      {
        isPublic: false,
      },
      "v1",
      !!containerSelected?.vmBuild?.forceJupyterInstall, // force jupyter install for vmMode
      containerSelected?.dockerCompose
    );

    if (res.success) {
      setWorkspace(res.data);
      //Wait a bit for the workspace to be created
      if (res.data && res.data.id) {
        try {
          await pollInstanceUntilSuccess(res.data.id);
        } catch (error) {
          onFail(
            "Failed to create instance, Please try again in a few minutes"
          );
          console.error("Failed to create instance:", error);
          return;
        }
      } else if (!res.data || !res.data.id) {
        onFail(
          "Unknown error creating instance: No ID returned. Please try again in a few minutes"
        );
        console.error("Failed to create instance");
        return;
      }
      agent.Brevent.track({
        eventName: "One Click Deploy Workspace Success",
        userId: userContext.me?.id,
        properties: {
          orgId: orgContext.activeOrgId,
          noteBookName: name,
          ...extraEventProperties,
        },
      });

      if (res.data && res.data.id) {
        setActiveStep(DeployState.Dependencies);
        setLogFileContent("");
        setVerbBuildLogFilePath("/home/ubuntu/.verb-setup.log");

        if (containerSelected?.dockerCompose) {
          updateDependencyList(
            `Installing ${parseDockerComposeUrl(
              containerSelected.dockerCompose.fileUrl
            )}`,
            stepsToUse
          );
        } else if (containerSelected?.vmBuild) {
          updateDependencyList(`Preparing VM Mode`, stepsToUse);
        } else if (containerSelected.customContainer) {
          updateDependencyList(
            `Installing  ${containerSelected.customContainer.containerUrl}`,
            stepsToUse
          );
        } else if (containerSelected.verbBuild?.containerUrl !== "Default") {
          updateDependencyList(
            `Building ${containerSelected?.verbBuild?.containerUrl}`,
            stepsToUse
          );
        } else if (!!res.data.verbYaml) {
          const verbYamlObj = yaml.load(res.data.verbYaml);
          updateDependencyList(
            `Installing Python ${verbYamlObj.build.python_version} & CUDA ${verbYamlObj.build.cuda}`,
            stepsToUse
          );
        }
      }
    } else {
      onFail("Failed to create instance: " + res.message);
      console.error("Failed to create instance");
    }
  };

  const configureDependencies = async (workspace: Workspace, file: string) => {
    setLogFileContent("");
    setVerbBuildLogFilePath("/home/ubuntu/.verb-setup.log");
    if (!!workspace.baseImage) {
      updateDependencyList(`Building ${workspace.baseImage}`, steps);
    } else if (!!workspace.verbYaml) {
      const verbYamlObj = yaml.load(workspace.verbYaml);
      updateDependencyList(
        `Installing Python ${verbYamlObj.build.python_version} & CUDA ${verbYamlObj.build.cuda}`,
        steps
      );
    }
    if (file !== "") {
      const fileObject = await buildFileRequestObject(file);
      const res = await agent.Workspaces.addFile(workspace.id, fileObject);
      if (res.success && res.data) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const buildFileRequestObject = async (url: string) => {
    const fileType = await validateAndGetFileType(url);
    const newFile: IFileObject = {
      url: replaceBlobWithRaw(url),
      path: getPathFromUrl(url, true),
      metadata: { type: fileType },
    };
    return newFile;
  };

  const ReadLogFileFromMachine = async (workspaceID: string) => {
    const res = await agent.Workspaces.readFileFromWorkspace(workspaceID, {
      filePath: verbBuildLogFilePath,
    });
    if (res.success && res.data && res.data.content !== "\n") {
      if (!logFileContent.includes(res.data.content)) {
        setLogFileContent(logFileContent + res.data.content);
      }
    }
    return res;
  };

  usePollingEffect(
    async () => {
      if (!workspace || activeStep === DeployState.Ready) {
        return;
      }
      const res = await agent.Workspaces.get(workspace.id);
      if (res.success && res.data) {
        if (
          res.data.status !== workspace.status ||
          res.data.spot !== workspace.spot ||
          !isCheckHealthSame(
            res.data.healthCheck || [],
            workspace.healthCheck || []
          ) ||
          !!res.data.region !== !!workspace.region ||
          workspace.verbBuildStatus !== res.data.verbBuildStatus ||
          !areStringListsEqual(
            workspace.additionalUsers || [],
            res.data.additionalUsers || []
          ) ||
          workspace.baseImage !== res.data.baseImage ||
          workspace.tunnel?.tunnelStatus !== res.data.tunnel?.tunnelStatus
        ) {
          // update the workspace status
          const updatedWorkspace = new Workspace(res.data);
          setWorkspace(updatedWorkspace);
        }
      }
    },
    [workspace],
    { executeImmediately: true }
  );

  const jupyterHealthcheck =
    workspace &&
    workspace.healthCheck &&
    workspace.healthCheck.find((hc) => {
      return hc.health_check_id == jupyterApp?.healthCheckID;
    });

  // Existing logic that does not use the tunnel status field.
  const isHealthyPolicy1 = (workspace: Workspace): boolean => {
    if (isGPUOnly) {
      return !!(
        workspace &&
        workspace.status === WorkspaceStatus.Running &&
        workspace.healthCheck &&
        workspace.healthCheck.length > 0 &&
        workspace.verbBuildStatus === VerbBuildStatus.Completed
      );
    }

    return !!(
      workspace &&
      workspace.status === WorkspaceStatus.Running &&
      workspace.healthCheck &&
      workspace.healthCheck.length > 0 &&
      workspace.verbBuildStatus === VerbBuildStatus.Completed &&
      jupyterApp != undefined &&
      jupyterHealthcheck?.status === "healthy"
    );
  };

  // new logic that uses the tunnel status field in addition to the existing logic.
  const isHealthyPolicy2 = (workspace: Workspace): boolean => {
    if (isGPUOnly) {
      return !!(
        workspace &&
        workspace.status === WorkspaceStatus.Running &&
        workspace.healthCheck &&
        workspace.healthCheck.length > 0 &&
        workspace.verbBuildStatus === VerbBuildStatus.Completed
      );
    }

    const basicInstanceHealthyStatus = !!(
      workspace &&
      workspace.status === WorkspaceStatus.Running &&
      workspace.healthCheck &&
      workspace.healthCheck.length > 0 &&
      workspace.verbBuildStatus === VerbBuildStatus.Completed
    );
    const jupyterHealthyStatus = !!(
      jupyterApp != undefined &&
      jupyterHealthcheck?.status === "healthy" &&
      workspace &&
      workspace.tunnel &&
      workspace.tunnel.tunnelStatus === "HEALTHY"
    );
    return basicInstanceHealthyStatus && jupyterHealthyStatus;
  };

  //
  const isHealthy = (workspace: Workspace): boolean => {
    return flagUseTunnelStatusForNotebookHealth
      ? isHealthyPolicy2(workspace)
      : isHealthyPolicy1(workspace);
  };

  //Check notebook health
  useEffect(() => {
    if (workspace && isHealthy(workspace as any)) {
      setActiveStep(DeployState.Ready);
    }
    if (workspace && workspace.status === WorkspaceStatus.Failure) {
      onFail("Failed to provision instance");
    }
  }, [workspace, jupyterApp]);

  const onFail = (message: string) => {
    reset();
    notificationContext.showNotification("Instance Failure", message, "error");
    agent.Brevent.track({
      eventName: "One Click Deploy Failed",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
        notebookName: name,
        error: message,
      },
    });
  };
  //Check verb container build status
  useEffect(() => {
    {
      logFileContent.includes(VERB_FAILED_PHRASE) &&
        workspace &&
        workspace.status === WorkspaceStatus.Running &&
        onFail("Failed to build container");
    }
  }, [workspace, logFileContent]);

  useEffect(() => {
    if (workspace?.tunnel?.applications) {
      const jupyterApp = workspace.tunnel.applications.find(
        (app) =>
          app.name.startsWith("jupyter") &&
          app.name.endsWith("-" + workspace.id)
      );
      setJupyterApp(jupyterApp);
    }
  }, [workspace?.tunnel?.applications]);

  const readyAction = async () => {
    if (isGPUOnly) {
      history.push(
        `/org/${orgContext.activeOrgId}/environments/${workspace?.id}#Access`
      );
      return;
    }

    if (!workspace && !jupyterApp) {
      return;
    }
    await agent.Brevent.track({
      eventName: "Opened Notebook (One Click Deploy)",
      userId: userContext.me?.id,
      properties: {
        orgId: orgContext.activeOrgId,
        environmentId: workspace?.id,
        notebookName: name,
      },
    });

    if (workspace?.fileObjects) {
      let fileList = mapValuesToArray(workspace?.fileObjects);
      let file = fileList[0];
      if (file && !workspace.verbYaml?.includes("workingDirectory")) {
        window.open(
          "https://" +
            jupyterApp?.hostname +
            "/lab/tree" +
            (file.path.startsWith("/") ? "" : "/") +
            file.path,
          "_blank"
        );
      } else {
        window.open("https://" + jupyterApp?.hostname, "_blank");
      }
    } else {
      window.open("https://" + jupyterApp?.hostname, "_blank");
    }
  };

  const providerValues = {
    activeStep,
    setActiveStep,
    steps: steps,
    readyAction,
    startDeployment,
    startDeploymentFromLaunchable,
    setIsFinished,
    isFinished,
    reset,
    showDeploying,
    setShowDeploying,
    showConnectCloudsOrCardModal,
    setShowConnectCloudsOrCardModal,
    noCardOnFile,
    setNoCardOnFile,
    selectedCloudName,
    setSelectedCloudName,
    workspace,
    instanceTypeObject,
    requestedDiskStorage,
    initLoading,
  };

  return (
    <OneClickDeployContext.Provider value={providerValues}>
      {children}
    </OneClickDeployContext.Provider>
  );
};

export default OneClickDeployContextProvider;
