import { classNames, displayPermissionHierarchyType } from "components/utils";
import { UserContext } from "contexts/UserContext";
import { PermissionHierarchyType } from "models/Permission.model";
import React, { useContext, useEffect, useState } from "react";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import ReactCardFlip from "react-card-flip";
import {
  BookOpenIcon,
  CircleStackIcon,
  CloudIcon,
  CpuChipIcon,
  RectangleGroupIcon,
  RocketLaunchIcon,
  WrenchScrewdriverIcon,
  InboxStackIcon,
} from "@heroicons/react/24/outline";
import PortsIcon from "assets/img/svg/ports.svg?react";
import WireIcon from "assets/img/svg/wire.svg?react";
import { InstanceAttribute } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/InstanceAttribute";
import { GPUInstanceType } from "components/Environment/Settings/Tabs/Compute/InstanceChanger/GPUTypes";
import { Container } from "components/Environment/Create/ContainerPicker";
import CopyField from "components/UI-lib/CopyField";
import { SimpleContainerOverview } from "components/Environment/shared/ContainerOverview";
import { isEmptyContainer, shortenFileName } from "../utils";
import "./style.scss";
import { formatMemory } from "components/Environment/utils";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";
import { LaunchablePort } from "server";

interface BuilderViewProps {
  clickedGenerate: boolean;
  launchableURL: string;
  savedInstance?: GPUInstanceType;
  savedStorage?: string;
  savedContainer?: ContainerSelected;
  pythonVersion: string;
  cudaVersion: string;
  savedFile: string;
  savedPorts: LaunchablePort[];
  //Animation States
  animateCompute: boolean;
  animateContainer: boolean;
  animatePorts: boolean;
  animateNotebook: boolean;
  animationCompleted: boolean;
}

const BuilderView: React.FC<BuilderViewProps> = ({
  clickedGenerate,
  launchableURL,
  savedInstance,
  savedStorage,
  savedContainer,
  pythonVersion,
  cudaVersion,
  savedFile,
  savedPorts,
  animateCompute,
  animateContainer,
  animatePorts,
  animateNotebook,
  animationCompleted,
}) => {
  const userContext = useContext(UserContext);
  const [shortFileName, setShortFileName] = useState("");

  const isEmptyLaunchable = () => {
    return (
      !savedInstance && !savedContainer && !savedFile && savedPorts.length === 0
    );
  };

  useEffect(() => {
    async function handleFileName() {
      if (savedFile) {
        const shortName = await shortenFileName(savedFile);
        setShortFileName(shortName);
      } else {
        setShortFileName("");
      }
    }
    handleFileName();
  }, [savedFile]);

  return (
    <div className="bg-primaryBg p-4 w-full flex flex-row rounded-md border-2 border-dashed border-gray-300 dark:border-zinc-800 min-h-[125px]">
      {isEmptyLaunchable() ? (
        <>
          <div className="flex flex-row w-full justify-center text-gray-500 dark:text-gray-400 items-center">
            <WrenchScrewdriverIcon className="h-6 w-6 mr-1" />
            <span>Add configurations below to your launchable</span>
          </div>
        </>
      ) : (
        <div className="flex flex-col w-full">
          {!animationCompleted ? (
            <div className="flex flex-row w-full">
              {savedInstance ? (
                <div
                  className={classNames(
                    "flex flex-col  text-sm",
                    animateCompute &&
                      !animateContainer &&
                      !animatePorts &&
                      !animateNotebook
                      ? "text-gray-700 dark:text-cyan-100 bounce"
                      : "text-gray-500 dark:text-white"
                  )}
                >
                  <div className="flex flex-row items-center">
                    <CpuChipIcon className="h-6 w-6 mr-1" />
                    <span>Compute</span>
                  </div>
                  <div className="flex flex-col ml-[2px]">
                    <InstanceAttribute
                      instanceType={savedInstance.type}
                      isGPUInstance={!!savedInstance.supported_gpus}
                      vcpu={savedInstance.vcpu}
                      memory={formatMemory(
                        savedInstance.memory,
                        savedInstance.workspace_groups[0].platformType
                      )}
                      gpuManufacturer={
                        savedInstance.supported_gpus
                          ? savedInstance.supported_gpus[0].manufacturer
                          : ""
                      }
                      gpuName={
                        savedInstance.supported_gpus
                          ? savedInstance.supported_gpus[0].name
                          : ""
                      }
                      gpuMemory={
                        savedInstance.supported_gpus
                          ? savedInstance.supported_gpus[0].memory
                          : ""
                      }
                      gpuCount={
                        savedInstance.supported_gpus
                          ? savedInstance.supported_gpus[0].count
                          : 0
                      }
                    />
                    <div className="flex flex-col mt-1">
                      <div className="flex flex-row">
                        <div className="flex flex-row mb-1 items-center mr-2">
                          <CircleStackIcon className="h-5 w-5 mr-1" />
                          <span className="text-xs">
                            {savedInstance.elastic_root_volume
                              ? savedStorage + "GiB"
                              : savedInstance.supported_storage[0].size ||
                                "0GiB"}
                          </span>
                        </div>
                        <div className="flex flex-row mb-1 items-center">
                          <CloudIcon className="h-5 w-5 mr-1" />
                          <span className="text-xs">
                            {savedInstance.workspace_groups[0].platformType.toUpperCase() ||
                              "Unknown"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center text-rose-500 mr-3">
                  <CpuChipIcon className="h-6 w-6 mr-2" />
                  <span className="text-sm">Compute is Required</span>
                </div>
              )}

              {(savedInstance || savedContainer) && (
                <>
                  <div className="text-white mr-3 items-center flex">
                    <WireIcon width={50} height={50} />
                  </div>
                  <div
                    className={classNames(
                      "flex flex-col  items-center",
                      animateContainer && !animatePorts && !animateNotebook
                        ? "text-gray-700 dark:text-cyan-100 bounce"
                        : "text-gray-500 dark:text-white"
                    )}
                  >
                    <div className="flex flex-col">
                      <div className="flex flex-row items-center text-sm mb-1">
                        <RectangleGroupIcon className="h-6 w-6 mr-1.5" />
                        <span>Container</span>
                      </div>
                      <SimpleContainerOverview
                        containerSelected={savedContainer}
                        pythonVersion={pythonVersion}
                        cudaVersion={cudaVersion}
                        className={classNames(
                          animateContainer && !animatePorts && !animateNotebook
                            ? "border-gray-700 dark:border-cyan-100"
                            : ""
                        )}
                      />
                      {savedContainer?.vmBuild && (
                        <span className="text-xs mt-1">
                          {savedContainer?.vmBuild?.forceJupyterInstall
                            ? "*Preinstalls Jupyter"
                            : ""}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
              {savedFile && (
                <>
                  <div className="text-white mr-3 items-center flex ml-3 mr-3">
                    <WireIcon width={50} height={50} />
                  </div>
                  <div
                    className={classNames(
                      "flex flex-col  items-center text-sm",
                      !animatePorts && animateNotebook
                        ? "text-gray-700 dark:text-cyan-100 bounce"
                        : "text-gray-500 dark:text-white"
                    )}
                  >
                    <div className="flex flex-row items-center w-full">
                      <InboxStackIcon className="h-6 w-6 mr-1" />
                      <span>Files</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-sm">{shortFileName}</span>
                    </div>
                  </div>
                </>
              )}
              {savedPorts.length > 0 && (
                <>
                  <div className="text-white mr-3 items-center flex ml-3 mr-3">
                    <WireIcon width={50} height={50} />
                  </div>
                  <div
                    className={classNames(
                      "flex flex-col  items-center ",
                      animatePorts
                        ? "text-gray-700 dark:text-cyan-100 bounce"
                        : "text-gray-500 dark:text-white"
                    )}
                  >
                    <div className="flex flex-row mt-[2px] items-center">
                      <PortsIcon height={"14px"} width={"32px"} />
                      <div className="flex flex-row">
                        <span className="text-sm ml-1">Exposed Ports</span>
                      </div>
                    </div>
                    <div className="w-full">
                      <span className="text-xs max-w-[250px] mt-1">
                        {savedPorts
                          .map((port) => `${port.name}:${port.port}`)
                          .join(" | ")}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row text-gray-500 dark:text-white items-center">
                  <h1 className="mr-1">Launchable Created</h1>
                  <RocketLaunchIcon className="h-5 w-5" />
                </div>
              </div>
              <div className="flex flex-row">
                <p className="text-sm text-gray-500 dark:text-white">
                  Copy and share this link with others to launch your
                  environment with just 1-click
                </p>
              </div>
            </>
          )}

          {clickedGenerate && (
            <ReactCardFlip
              isFlipped={animationCompleted}
              flipDirection="horizontal"
            >
              <div className="w-full flex justify-start items-center">
                <p
                  className={classNames(
                    "truncate",
                    "text-sm text-gray-700 dark:text-cyan-100 font-mono ml-2 mt-4",
                    animateCompute ? "drop-animation" : ""
                  )}
                ></p>
                {animateContainer && (
                  <p
                    className={classNames(
                      "truncate",
                      "text-sm text-gray-700 dark:text-cyan-100 font-mono ml-2 mt-4",
                      animateContainer ? "drop-animation" : ""
                    )}
                  ></p>
                )}

                {savedFile.length > 0 && animateNotebook && (
                  <p
                    className={classNames(
                      "text-sm text-gray-700 dark:text-cyan-100 font-mono ml-2 mt-4",
                      animateNotebook ? "drop-animation" : ""
                    )}
                  ></p>
                )}
                {savedPorts.length > 0 && animatePorts && (
                  <p
                    className={classNames(
                      "truncate",
                      "text-sm text-gray-700 dark:text-cyan-100 font-mono ml-2 mt-4",
                      animatePorts ? "drop-animation" : ""
                    )}
                  ></p>
                )}
              </div>
              <div className="flex flex-row items-center mt-2">
                <CopyField
                  value={launchableURL}
                  analytics={{
                    userid: userContext.me?.id || "unknown",
                    eventLabel: "Copied Launchable Link",
                  }}
                  className="max-w-[600px] min-w-[600px] "
                />
                <div
                  className="flex flex-row items-center shimmer"
                  onClick={() => {
                    window.open(launchableURL, "_blank", "noopener,noreferrer");
                  }}
                >
                  <span className="text-sm text-highlight cursor-pointer ml-3 flex flex-row">
                    View Launchable
                  </span>
                  <ArrowUpRightIcon className="h-4 w-4 text-highlight ml-1" />
                </div>
              </div>
            </ReactCardFlip>
          )}
        </div>
      )}
    </div>
  );
};

export default BuilderView;
