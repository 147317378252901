import { ArrowLeftIcon, CpuChipIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { AdminUserType } from "components/Admin/types";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import agent from "server";
import { Spinner } from "@kui-react/spinner";
import BillingOverview from "components/OrgPages/Billing/Overview";
import UsageSummary from "components/OrgPages/Billing/UsageSummary";
import WorkspacesTable from "../../WorkspacesTable";

const Organization: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const history = useHistory();
  const [tab, setTab] = useState<
    "Raw Workspace JSON" | "Raw Instance Type JSON"
  >("Raw Workspace JSON");

  const getOrganizationAsAdmin = async () => {
    const res = await agent.Admin.getOrganizationAsAdmin(orgId);
    if (res.success && res.data) {
      return res.data;
    } else {
      throw new Error(res.message);
    }
  };

  const getUsageOverviewAsAdmin = async () => {
    const res = await agent.Organizations.getUsageOverview(orgId);
    if (res.success && res.data) {
      return res.data;
    } else {
      throw new Error(res.message);
    }
  };

  const getBillingProfileAsAdmin = async (orgId: string) => {
    const res = await agent.BillingCredits.getBillingProfile(orgId);
    if (!res.data) {
      throw new Error("Failed to fetch billing profile");
    }
    return res.data;
  };

  const getAllWorkspacesAsAdmin = async (orgId: string) => {
    const res = await agent.Workspaces.getAll(orgId);
    if (!res.success || !res.data) {
      throw new Error("Failed to fetch workspaces");
    }
    return res.data;
  };

  const { data: workspaces, isLoading: isWorkspacesLoading } = useQuery({
    queryKey: ["admin-workspaces", orgId],
    queryFn: () => getAllWorkspacesAsAdmin(orgId),
    enabled: !!orgId,
  });

  const { data: billingProfile, isLoading: isBillingProfileLoading } = useQuery(
    {
      queryKey: ["admin-billing-profile", orgId],
      queryFn: () => getBillingProfileAsAdmin(orgId),
      enabled: !!orgId,
    }
  );

  const {
    data: organization,
    isLoading: isOrganizationLoading,
    error: userError,
  } = useQuery({
    queryKey: ["admin-org", orgId],
    queryFn: getOrganizationAsAdmin,
  });

  const {
    data: usageOverview,
    isLoading: isUsageOverviewLoading,
    error: usageOverviewError,
  } = useQuery({
    queryKey: ["admin-org-usage-overview", orgId],
    queryFn: getUsageOverviewAsAdmin,
  });

  return (
    <div className="flex flex-col min-h-[50vh] pt-5 pl-24 pr-24">
      {isOrganizationLoading ? (
        <div className="flex flex-col items-center justify-center h-full mt-10">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col mb-8">
            <div
              className="flex flex-row items-center cursor-pointer mb-5"
              onClick={() => {
                history.push("/admin/organizations");
              }}
            >
              <ArrowLeftIcon className="w-4 h-4 text-highlight mr-1" />
              <h3 className="text-md font-bold text-highlight">
                Organizations
              </h3>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <h1 className="text-2xl font-bold mb-2 text-white">
                    {organization?.Name}
                  </h1>
                  <p className="text-sm text-secondary">{organization?.id}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-md text-white">
                    Credits:{" "}
                    {(
                      (billingProfile?.credit_details?.remaining_credits || 0) /
                      100
                    ).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </p>
                  <p className="text-sm text-secondary">
                    Top Up Amount:{" "}
                    {billingProfile?.credit_details?.top_up_amount}
                  </p>
                  <p className="text-sm text-secondary">
                    Top Up Threshold:{" "}
                    {billingProfile?.credit_details?.top_up_threshold}
                  </p>
                </div>
              </div>
              <hr className="w-[100%] mb-3 mt-3 dark:border dark:border-zinc-800" />
              <div className="mt-5">
                <BillingOverview
                  activeOrg={!!organization || false}
                  usageOverview={usageOverview?.Usage || []}
                  usageOverviewLoading={isUsageOverviewLoading}
                />
              </div>
              <div className="mt-5">
                <UsageSummary activeOrgId={organization?.id || ""} />
              </div>
              <div className="mt-10">
                <div>
                  <div className="flex flex-row items-center mb-2 text-white">
                    <CpuChipIcon className="w-4 h-4 mr-1" />
                    <h3 className="text-white text-md font-semibold">
                      Current Instances
                    </h3>
                  </div>
                  <div className="mt-2">
                    <WorkspacesTable
                      workspaces={workspaces || []}
                      isLoading={isWorkspacesLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Organization;
