import config from "config";
import { TEMPLATE_ID } from "constants/index";
import Project from "../entities/Project.entity";
import Workspace from "../entities/Workspace.entity";
import { IExec } from "models/Exec.model";
import { IRepo } from "models/Repo.model";
import { IdeConfig } from "models/User.model";
import IWorkspace, { IFileObject } from "models/Workspace.model";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import agent, {
  UpdateWorkspaceReqBody,
  CreateWorkspaceReqBody,
  WorkspaceRes,
  WorkspacesRes,
  CloneWorkspaceReqBody,
  EnvEvent,
  EnvEventArgs,
  EnvSaving,
  LaunchableFileRequest,
} from "server";
import { workspaceContextDefaultValues } from "tests/defaultValues";
import { OrgContext } from "./OrgContext";
import { mapValuesToArray, replaceBlobWithRaw } from "components/utils";
import {
  CustomContainer,
  DockerCompose,
} from "components/Environment/shared/BuildTypes";

export interface WorkspaceContextType {
  isLoading: boolean;
  workspaces: Workspace[];
  setWorkspaces: (ws: Workspace[]) => void;
  projects: () => Project[];
  setup: (orgId: string, cache?: boolean) => Promise<WorkspacesRes>;
  clear: () => void;
  reloadWorkspaces: (activeOrgId: string) => Promise<Workspace[]>;
  getWorkspace: (id: string) => Promise<Workspace | null>;
  envSavings: (id: string, args: EnvEventArgs) => Promise<EnvSaving | null>;
  getProject: (workspaceId: string) => Project | null;
  createWorkspace: (
    name: string,
    orgId: string,
    activeWorkspaceGroupId: string,
    description?: string,
    repos?: { [key: string]: IRepo },
    execs?: { [key: string]: IExec },
    ideConfig?: IdeConfig,
    instanceType?: string,
    image?: string,
    region?: string,
    architecture?: string,
    diskStorage?: string,
    spot?: boolean,
    isStoppable?: boolean,
    onContainer?: boolean,
    verbYaml?: string,
    baseImage?: string,
    customContainer?: CustomContainer,
    vmOnlyMode?: boolean,
    portMappings?: Record<string, string> | null,
    files?: LaunchableFileRequest[] | null,
    labels?: Record<string, string> | null,
    templateConfig?: {
      isPublic: boolean;
    },
    workspaceVersion?: "v1" | "v0",
    launchJupyterOnStart?: boolean,
    dockerCompose?: DockerCompose
  ) => Promise<WorkspaceRes>;
  updateWorkspace: (
    wsid: string,
    args: UpdateWorkspaceReqBody
  ) => Promise<WorkspaceRes>;
  stopWorkspace: (id: string) => Promise<WorkspaceRes>;
  startWorkspace: (id: string) => Promise<WorkspaceRes>;
  deleteWorkspace: (id: string) => Promise<WorkspaceRes>;
  resetWorkspace: (id: string) => Promise<WorkspaceRes>;
  hardResetWorkspace: (id: string) => Promise<WorkspaceRes>;
  cloneWorkspace: (id: string, newName: string) => Promise<WorkspaceRes>;
  changeIsStoppable: (
    id: string,
    newIsStoppable: boolean
  ) => Promise<WorkspaceRes>;
  updateVSCodeExtensionsToWorkspaceTemplate: (
    id: string,
    ideConfig: IdeConfig
  ) => Promise<WorkspaceRes>;
  updateRepoConfigToWorkspaceTemplate: (
    id: string,
    repos: { [key: string]: IRepo }
  ) => Promise<WorkspaceRes>;
  updateExecConfigToWorkspaceTemplate: (
    id: string,
    execs: { [key: string]: IExec }
  ) => Promise<WorkspaceRes>;
  updateStartupScriptPath: (
    id: string,
    startupScriptPath: string
  ) => Promise<WorkspaceRes>;
  addNewPortToWorkspace: (
    id: string,
    newPortUserWantsToExpose: string
  ) => Promise<WorkspaceRes>;
  revokePortFromWorkspace: (
    id: string,
    portUserWantsToRevoke: string
  ) => Promise<WorkspaceRes>;
  shareWorkspace: (
    id: string,
    addUsers: string[],
    organizationID: string
  ) => Promise<WorkspaceRes>;
}

export const WorkspaceContext = createContext<WorkspaceContextType>(
  workspaceContextDefaultValues
);

interface Props {
  children: React.ReactNode;
}
const WorkspaceContextProvider: React.FC<Props> = ({ children }) => {
  const orgContext = useContext(OrgContext);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const projects = useCallback(() => {
    const chunkedWorkspaces: Array<Workspace[]> = workspaces.reduce(
      (chunkedWorkspaces: Array<Workspace[]>, ws: IWorkspace) => {
        if (!ws.gitRepo) {
          // add current item to new sub array
          chunkedWorkspaces.push([new Workspace(ws)]);
        } else {
          // add ws to appropriate sub repo
          const sortedGitRepoLinks = chunkedWorkspaces.map((wsChunks) => {
            if (wsChunks.length > 0) return wsChunks[0].gitRepo;
          });
          const subarrayIndex = sortedGitRepoLinks.findIndex(
            (gitRepo) => gitRepo === ws.gitRepo
          );
          if (subarrayIndex === -1) {
            chunkedWorkspaces.push([new Workspace(ws)]);
          } else {
            chunkedWorkspaces[subarrayIndex].push(new Workspace(ws));
          }
        }
        return chunkedWorkspaces;
      },
      []
    );
    return chunkedWorkspaces.map(
      (wsChunks) =>
        new Project(wsChunks, wsChunks[0].organizationId, wsChunks[0].gitRepo)
    );
  }, [workspaces]);

  const getProject = useCallback(
    (workspaceId: string): Project | null =>
      projects().find((p) => p.hasWorkspace(workspaceId)) || null,
    [projects]
  );

  const setup = async (orgId: string, cache = true): Promise<WorkspacesRes> => {
    const orgIds = workspaces.map((w) => w.organizationId);
    if (cache && orgIds.length > 0 && orgIds.every((id) => id === orgId)) {
      return { success: true };
    }
    setIsLoading(true);
    const res = await agent.Workspaces.getAll(orgId);
    setIsLoading(false);
    if (res.success && res.data) {
      const workspaces = res.data.map((w) => new Workspace(w));
      setWorkspaces(workspaces);
    }
    return res;
  };

  const reloadWorkspaces = async (
    activeOrgId: string
  ): Promise<Workspace[]> => {
    const res = await agent.Workspaces.getAll(activeOrgId);
    if (res.success && res.data) {
      const workspaces = res.data.map((w) => new Workspace(w));
      setWorkspaces(workspaces);
      return workspaces;
    } else {
      throw new Error("Failed to reload workspaces");
    }
  };

  const clear = (): void => {
    setWorkspaces([]);
  };

  const getWorkspace = async (id: string): Promise<Workspace | null> => {
    // if there are no workspace, we're going to run getAll in the background and fetch your workspace
    if (workspaces.length === 0) {
      setup(orgContext.activeOrgId, false);
      const res = await agent.Workspaces.get(id);
      if (res.success && res.data) return new Workspace(res.data);
      return null;
    }
    // otherwise search for the workspace and return
    const w = workspaces.find((w) => w.id === id);
    if (w) return w;
    // if it isn't in workspaces, fetch it and add it to the list
    const res = await agent.Workspaces.get(id);
    if (res.success && res.data) {
      const newWorkspace = new Workspace(res.data);
      setWorkspaces([newWorkspace, ...workspaces]);
      return newWorkspace;
    }
    return null;
  };

  const envSavings = async (
    id: string,
    args: EnvEventArgs
  ): Promise<EnvSaving | null> => {
    const res = await agent.Workspaces.envSavings(id, args);
    if (res.success && res.data) {
      return res.data;
    }
    return null;
  };

  const createWorkspace = async (
    name: string,
    orgId: string,
    activeWorkspaceGroupId: string,
    description?: string,
    repos?: { [key: string]: IRepo },
    execs?: { [key: string]: IExec },
    ideConfig?: IdeConfig,
    instanceType?: string,
    image?: string,
    region?: string,
    architecture?: string,
    diskStorage?: string,
    spot?: boolean,
    isStoppable?: boolean,
    onContainer?: boolean,
    verbYaml?: string,
    baseImage?: string,
    customContainer?: CustomContainer,
    vmOnlyMode?: boolean,
    portMappings?: Record<string, string> | null,
    files?: LaunchableFileRequest[] | null,
    labels?: Record<string, string> | null,
    templateConfig?: {
      isPublic: boolean;
    },
    workspaceVersion?: "v1" | "v0",
    launchJupyterOnStart?: boolean,
    dockerCompose?: DockerCompose
  ) => {
    const workspaceTemplateId = TEMPLATE_ID;
    const args: CreateWorkspaceReqBody = {
      name,
      workspaceGroupId: activeWorkspaceGroupId, // config.workspaceGroupID,
      workspaceTemplateId,
      description,
      isStoppable,
      ...(repos ? { reposV1: repos } : {}),
      ...(execs ? { execsV1: execs } : {}),
      ...(ideConfig ? { ideConfig } : {}),
      instanceType,
      ...(image ? { image } : {}),
      ...(region ? { region } : {}),
      ...(architecture ? { architecture } : {}),
      ...(diskStorage ? { diskStorage: `${diskStorage}Gi` } : {}),
      ...(spot ? { spot } : {}),
      ...(onContainer ? { onContainer } : {}),
      ...(verbYaml ? { verbYaml } : {}),
      baseImage,
      customContainer,
      vmOnlyMode,
      portMappings,
      files,
      labels,
      workspaceVersion,
      launchJupyterOnStart,
      dockerCompose,
    };
    const res = await agent.Workspaces.create(orgId, args);
    if (res.success && res.data) {
      const newWorkspace = new Workspace(res.data);
      if (workspaces.length > 0) {
        setWorkspaces([newWorkspace, ...workspaces]);
      } else {
        // fetch all if there are no workspaces
        setup(orgContext.activeOrgId, false);
      }

      // TODO(tmc): remove this
      // This impacts:
      // * the billing page, due to some nil deref in the backend
      agent.Organizations.createTemplate(orgId, {
        isPublic: !!templateConfig?.isPublic,
        workspaceRequest: args,
      });
    }
    return res;
  };

  const updateWorkspace = async (
    wsid: string,
    args: UpdateWorkspaceReqBody
  ) => {
    // const workspaceTemplateId = TEMPLATE_ID;
    // const args = {
    //   name,
    //   workspaceGroupId: config.workspaceGroupID,
    //   workspaceTemplateId,
    //   workspaceClassId,
    //   gitRepo,
    //   isStoppable: true,
    //   ...(startupScript ? { startupScript } : {}),
    //   ...(repos ? { reposV1: repos } : {}),
    //   ...(execs ? { execsV1: execs } : {}),
    //   ...(startupScriptPath ? { startupScriptPath } : {}),
    //   ...(ideConfig ? { ideConfig } : {}),
    // };
    const res = await agent.Workspaces.update(wsid, args);
    if (res.success && res.data) {
      setup(orgContext.activeOrgId, false);
    }
    return res;
  };

  const stopWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.stop(id);
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const startWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.start(id);
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const deleteWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.delete(id);
    if (res.success && res.data) {
      const workspace = res.data;
      setWorkspaces(workspaces.filter((ws) => ws.id !== workspace.id));
    }
    return res;
  };

  const resetWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.reset(id);
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const hardResetWorkspace = async (id: string): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.hardReset(id);
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const cloneWorkspace = async (
    id: string,
    newName: string
  ): Promise<WorkspaceRes> => {
    const args: CloneWorkspaceReqBody = {
      name: newName,
    };
    const res = await agent.Workspaces.clone(id, args);
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const changeIsStoppable = async (
    id: string,
    isStoppable: boolean
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { isStoppable });
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const updateVSCodeExtensionsToWorkspaceTemplate = async (
    id: string,
    ideConfig: IdeConfig
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { ideConfig });
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const updateRepoConfigToWorkspaceTemplate = async (
    id: string,
    repos: { [key: string]: IRepo }
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { reposV1: repos });
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const updateExecConfigToWorkspaceTemplate = async (
    id: string,
    execs: { [key: string]: IExec }
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.update(id, { execsV1: execs });
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const updateStartupScriptPath = async (
    id: string,
    startupScriptPath: string
  ) => {
    const res = await agent.Workspaces.update(id, { startupScriptPath });
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const addNewPortToWorkspace = async (
    id: string,
    newPortUserWantsToExpose: string
  ) => {
    const res = await agent.Workspaces.update(id, {
      addPort: newPortUserWantsToExpose,
    });
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const revokePortFromWorkspace = async (
    id: string,
    portUserWantsToRevoke: string
  ) => {
    const res = await agent.Workspaces.update(id, {
      revokePort: portUserWantsToRevoke.toString(),
    });
    if (res.success && res.data) {
      const updatedWorkspace = new Workspace(res.data);
      const otherWorkspaces = workspaces.filter(
        (ws) => ws.id !== updatedWorkspace.id
      );
      setWorkspaces([updatedWorkspace, ...otherWorkspaces]);
    }
    return res;
  };

  const shareWorkspace = async (
    id: string,
    addUsers: string[],
    organizationID: string
  ): Promise<WorkspaceRes> => {
    const res = await agent.Workspaces.shareWorkspace(id, {
      addUsers,
      organizationID,
    });
    return res;
  };

  const providerData = {
    isLoading,
    workspaces,
    setWorkspaces,
    projects,
    setup,
    reloadWorkspaces,
    getWorkspace,
    clear,
    stopWorkspace,
    startWorkspace,
    deleteWorkspace,
    resetWorkspace,
    hardResetWorkspace,
    cloneWorkspace,
    createWorkspace,
    updateWorkspace,
    changeIsStoppable,
    getProject,
    updateVSCodeExtensionsToWorkspaceTemplate,
    updateRepoConfigToWorkspaceTemplate,
    updateExecConfigToWorkspaceTemplate,
    updateStartupScriptPath,
    addNewPortToWorkspace,
    revokePortFromWorkspace,
    shareWorkspace,
    envSavings,
  };

  return (
    <WorkspaceContext.Provider value={providerData}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceContextProvider;
