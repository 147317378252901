import { Message } from "./types";
import React from "react";
import { memo } from "react";
import { AnimatePresence } from "framer-motion";
import { LoadingIndicator } from "./LoadingIndicator";
import { ChatMessage } from "./ChatMessage";
interface MessagesProps {
  messages: Message[];
  isLoading: boolean;
}

function PureMessages({ messages, isLoading }: MessagesProps) {
  return (
    <div className="flex flex-col min-w-0 gap-6 flex-1 overflow-y-auto pt-4">
      <AnimatePresence>
        {messages.map((message, index) => (
          <ChatMessage
            key={index}
            message={message}
            isLoading={isLoading && index === messages.length - 1}
          />
        ))}
      </AnimatePresence>

      {isLoading &&
        messages.length > 0 &&
        messages[messages.length - 1].role === "user" && <LoadingIndicator />}
    </div>
  );
}

export const Messages = memo(PureMessages);
