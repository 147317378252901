import { classNames } from "components/utils";
import React, { useContext, useEffect, useState } from "react";

export interface FlatCardProps {
  children?: React.ReactNode;
  className?: string;
  noBorder?: boolean;
  isLoading?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  // Add new style prop
  style?: React.CSSProperties;
}

const FlatCard: React.FC<FlatCardProps> = (props) => (
  <div
    style={props.style}
    className={classNames(
      props.className ? props.className : "",
      props.noBorder
        ? `bg-primaryBg dark:text-secondary rounded-md ${
            props.className?.includes("px") && props.className?.includes("py")
              ? ""
              : "px-4 py-4"
          } w-full`
        : `bg-primaryBg border border-zinc-800 dark:text-secondary rounded-md ${
            props.className?.includes("px") && props.className?.includes("py")
              ? ""
              : "px-4 py-4"
          } w-full`,
      props.isLoading ? "animated-border-div on" : ""
    )}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    onClick={props.onClick}
  >
    <div className="w-full">{props.children}</div>
  </div>
);

export default FlatCard;
