import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className={"text-center"}>
      <div
        className={
          "mx-auto mb-[10px] mt-[30px] flex flex-row flex-wrap justify-center gap-x-[20px] text-ms font-normal text-secondary"
        }
        style={{
          maxWidth: "70%",
        }}
      >
        <a
          className="hover:text-primary"
          href={
            "https://assets.ngc.nvidia.com/products/api-catalog/legal/NVIDIA_Technology_Access_TOU.pdf"
          }
        >
          Terms of Use
        </a>
        <p> | </p>
        <a
          className="hover:text-primary"
          href="https://www.nvidia.com/en-us/about-nvidia/privacy-policy/"
        >
          Privacy Policy
        </a>
        <p> | </p>
        <a
          className="hover:text-primary"
          href="https://www.nvidia.com/en-us/about-nvidia/privacy-center/"
        >
          Manage My Privacy
        </a>
        <p> | </p>
        <a
          className="hover:text-primary"
          href="https://developer.nvidia.com/contact"
        >
          Contact
        </a>
      </div>
      <p className={"mb-[30px] text-xs font-light text-manitoulinLightGray"}>
        Copyright &copy; {new Date().getFullYear()} NVIDIA Corporation
      </p>
    </footer>
  );
};

export default Footer;
