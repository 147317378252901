import { Message } from "../types";
// "nvcf:nvidia/llama-3.1-nemotron-70b-instruct:dep-2s0v6PPVBhfBochVopJOwUDobTK",

export async function handleChatCompletion(
  model: string,
  apiKey: string,
  messages: Message[],
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
): Promise<void> {
  try {
    const response = await fetch("https://api.brev.dev/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: model,
        messages: messages.map(({ role, content }) => ({ role, content })),
        temperature: 0.2,
        top_p: 0.7,
        max_tokens: 1024,
        stream: true,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let assistantResponse = "";

    // Add initial empty assistant message
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "assistant", content: "" },
    ]);

    if (reader) {
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split("\n");
        for (const line of lines) {
          if (line.startsWith("data: ")) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.choices && data.choices[0].delta.content) {
                assistantResponse += data.choices[0].delta.content;
                // Update the last message with the accumulated response
                setMessages((prevMessages) => {
                  const newMessages = [...prevMessages];
                  newMessages[newMessages.length - 1] = {
                    role: "assistant",
                    content: assistantResponse,
                  };
                  return newMessages;
                });
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
            }
          }
        }
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
}
