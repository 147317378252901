import React from "react";
import User from "../../entities/User.entity";
import agent from "server";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/Graphs/Dropdown";

import { useFeatureFlagEnabled } from "posthog-js/react";
import { UserContextType } from "contexts/UserContext";
import { NotificationContextType } from "contexts/NotificationContext";
import shaka from "assets/img/png/shaka.png";
import DevToggle from "components/DevToggle";
import BetaFeaturesModal from "components/UI-lib/BetaFeaturesModal";
import { createDefaultAnalyticsObject } from "components/Analytics";

const userNavigation = [
  { name: "Your Profile", href: "/profile" },
  { name: "Support / Help Center", href: "/help" },
  { name: "Sign out", href: "/logout" },
];

interface ProfileDropDownProps {
  me: User | null;
  history: any;
  darkMode: boolean;
  toggleDarkMode: () => void;
  userContext: UserContextType;
  notificationContext: NotificationContextType;
  showBetaFeaturesModal: boolean;
  setShowBetaFeaturesModal: (show: boolean) => void;
}

export const ProfileDropDown: React.FC<ProfileDropDownProps> = (props) => {
  const flagAllowEarlyAccessFeatureManagement = useFeatureFlagEnabled(
    "allow-early-access-feature-management"
  );

  return (
    <div className="ml-3 relative">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="max-w-xs bg-white flex items-center text-sm rounded-full cursor-pointer">
            <span className="sr-only">Open user menu</span>
            <img
              className="h-8 w-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-zinc-900 focus:ring-highlightLighter "
              src={props.me?.profilePhotoUrl || shaka}
              alt=""
            />
          </div>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-48">
          <DropdownMenuLabel>Proile Settings</DropdownMenuLabel>
          <DropdownMenuSeparator />

          <DropdownMenuGroup>
            {userNavigation.map((item) => (
              <DropdownMenuItem
                key={item.name}
                onClick={() => props.history.push(item.href)}
              >
                {item.name}
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>

          {flagAllowEarlyAccessFeatureManagement && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => props.setShowBetaFeaturesModal(true)}
              >
                ✨ Beta Features
              </DropdownMenuItem>
            </>
          )}

          <DevToggle>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              onClick={async () => {
                props.userContext.setOnboardingObjectFunc({
                  inviteAccepted: ``,
                });
                const res = await agent.Users.update(
                  props.userContext.me?.id || "",
                  createDefaultAnalyticsObject(props.userContext.me, {
                    inviteAccepted: ``,
                  })
                );
                if (res.success) {
                  props.userContext.getMe();
                  props.notificationContext.showNotification(
                    "Reset onboarding status",
                    "Please refresh the page.",
                    "success"
                  );
                }
              }}
            >
              Click to reset onboarding
            </DropdownMenuItem>
          </DevToggle>
        </DropdownMenuContent>
      </DropdownMenu>

      {props.showBetaFeaturesModal && (
        <BetaFeaturesModal
          isOpen={props.showBetaFeaturesModal}
          onClose={() => props.setShowBetaFeaturesModal(false)}
        />
      )}
    </div>
  );
};
