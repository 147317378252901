import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useQueries, useQuery } from "@tanstack/react-query";
import { AdminUserType } from "components/Admin/types";
import React, { useState, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import agent from "server";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/Graphs/Tabs";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import { Button } from "components/UI-lib";
import { Spinner } from "@kui-react/spinner";
import { CpuChipIcon } from "@heroicons/react/24/solid";
import WorkspacesTable from "../../WorkspacesTable";
import IWorkspace from "models/Workspace.model";

interface OrgWorkspaces {
  orgId: string;
  orgName: string;
  workspaces: IWorkspace[];
}

const User: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const history = useHistory();
  const [tab, setTab] = useState<"Organizations" | "Instances" | "Raw JSON">(
    "Organizations"
  );

  const getUserAsAdmin = async () => {
    const res = await agent.Admin.getUserAsAdmin(userId);
    if (res.success) {
      return res.data;
    } else {
      throw new Error(res.message);
    }
  };

  const getUserOrganizations = async () => {
    const res = await agent.Admin.getUserOrganizations(userId);
    if (res.success) {
      return res.data;
    } else {
      throw new Error(res.message);
    }
  };

  const getWorkspacesForOrg = async (orgId: string) => {
    const res = await agent.Workspaces.getAll(orgId);
    if (res.success && res.data) {
      return res.data;
    }
    return [];
  };

  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useQuery({
    queryKey: ["admin-user", userId],
    queryFn: getUserAsAdmin,
  });

  const {
    data: organizations,
    isLoading: isOrganizationsLoading,
    error: organizationsError,
  } = useQuery({
    queryKey: ["admin-organizations", userId],
    queryFn: getUserOrganizations,
  });

  const orgWorkspaceQueries = useQueries({
    queries:
      organizations?.map((org) => ({
        queryKey: ["admin-workspaces", org.id],
        queryFn: () => getWorkspacesForOrg(org.id),
        enabled: !!org.id,
      })) ?? [],
  });

  // Create a map of org ID to workspaces
  const orgWorkspacesMap = useMemo(() => {
    const map = new Map<string, IWorkspace[]>();

    if (organizations && !orgWorkspaceQueries.some((q) => q.isLoading)) {
      organizations.forEach((org, index) => {
        map.set(org.id, orgWorkspaceQueries[index]?.data ?? []);
      });
    }

    return map;
  }, [organizations, orgWorkspaceQueries]);

  const sortedOrganizations = useMemo(() => {
    if (
      !organizations ||
      orgWorkspaceQueries.some((query) => query.isLoading) ||
      orgWorkspaceQueries.length === 0
    ) {
      return organizations;
    }

    return [...organizations].sort((a, b) => {
      const aWorkspaces =
        orgWorkspacesMap.get(a.id)?.filter((w) => w.createdByUserId === userId)
          .length ?? 0;

      const bWorkspaces =
        orgWorkspacesMap.get(b.id)?.filter((w) => w.createdByUserId === userId)
          .length ?? 0;

      return bWorkspaces - aWorkspaces;
    });
  }, [organizations, orgWorkspacesMap, userId]);

  return (
    <div className="flex flex-col min-h-[50vh] pt-5 pl-24 pr-24">
      {isUserLoading ? (
        <div className="flex flex-col items-center justify-center h-full mt-10">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex flex-col mb-8">
            <div
              className="flex flex-row items-center cursor-pointer mb-5"
              onClick={() => {
                history.push("/admin/users");
              }}
            >
              <ArrowLeftIcon className="w-4 h-4 text-highlight mr-1" />
              <h3 className="text-md font-bold text-highlight">User</h3>
            </div>
            <h1 className="text-2xl font-bold mb-2 text-white">{user?.Name}</h1>
            <p className="text-sm text-secondary">{user?.Email}</p>
            <p className="text-sm text-secondary">{user?.id}</p>
          </div>

          <div className="flex flex-col">
            <Tabs
              value={tab}
              onValueChange={(val) => {
                setTab(val as "Organizations" | "Instances" | "Raw JSON");
              }}
            >
              <TabsList variant="line">
                <TabsTrigger value="Organizations">Organizations</TabsTrigger>
                <TabsTrigger value="Instances">Instances</TabsTrigger>
                <TabsTrigger value="Raw Object">Raw JSON</TabsTrigger>
              </TabsList>
              <div className="mt-8">
                <TabsContent value="Organizations">
                  {isOrganizationsLoading ? (
                    <div className="flex flex-col items-center justify-center h-full mt-10">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <div>
                        <TableRoot>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableHeaderCell>ID</TableHeaderCell>
                                <TableHeaderCell>Name</TableHeaderCell>
                                <TableHeaderCell>Members</TableHeaderCell>
                                <TableHeaderCell>Instances</TableHeaderCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sortedOrganizations?.map(
                                (organization, index) => (
                                  <TableRow
                                    key={index}
                                    onClick={() => {
                                      history.push(
                                        `/admin/organizations/id/${organization.id}`
                                      );
                                    }}
                                    className="cursor-pointer hover:bg-zinc-800"
                                  >
                                    <TableCell>
                                      <span className="text-sm text-gray-500 dark:text-secondary ">
                                        {organization.id}
                                      </span>
                                    </TableCell>
                                    <TableCell>
                                      <span className="text-sm text-gray-500 dark:text-secondary">
                                        {organization.Name}
                                      </span>
                                    </TableCell>
                                    <TableCell>
                                      <span
                                        className="text-sm text-highlight underline cursor-pointer hover:text-highlightLighter"
                                        onClick={() => {
                                          console.log("Viewing members");
                                        }}
                                      >
                                        View Members
                                      </span>
                                    </TableCell>
                                    <TableCell>
                                      <span
                                        className="text-sm text-highlight underline cursor-pointer hover:text-highlightLighter"
                                        onClick={() => {
                                          console.log("Viewing Instances");
                                        }}
                                      >
                                        View Instances
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableRoot>
                      </div>
                    </>
                  )}
                </TabsContent>
                <TabsContent value="Instances">
                  <div className="mt-8 space-y-8">
                    {isOrganizationsLoading ? (
                      <div className="flex flex-col items-center justify-center h-full mt-10">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        {sortedOrganizations?.map((org) => (
                          <div
                            key={org.id}
                            className="border border-zinc-800 rounded-lg p-4"
                          >
                            <div className="mb-4">
                              <h4 className="text-lg font-semibold text-white">
                                {org.Name}
                              </h4>
                              <p
                                className="text-sm text-secondary cursor-pointer hover:text-highlight"
                                onClick={() => {
                                  history.push(
                                    `/admin/organizations/id/${org.id}`
                                  );
                                }}
                              >
                                {org.id}
                              </p>
                            </div>
                            <WorkspacesTable
                              workspaces={
                                orgWorkspacesMap
                                  .get(org.id)
                                  ?.filter(
                                    (value) => value.createdByUserId === userId
                                  ) || []
                              }
                              isLoading={
                                orgWorkspaceQueries[
                                  organizations?.findIndex(
                                    (o) => o.id === org.id
                                  ) ?? -1
                                ]?.isLoading || false
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </TabsContent>
                <TabsContent value="Raw Object">
                  <div className="mt-10">
                    <div className="max-w-[72vw] border border-zinc-800 rounded-md p-4">
                      <div className="overflow-x-auto">
                        <pre className="text-xs text-secondary">
                          {JSON.stringify(user, null, 2)}
                        </pre>
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </div>
            </Tabs>
          </div>
        </>
      )}
    </div>
  );
};

export default User;
