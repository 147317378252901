import React, { useContext, useEffect, useState } from "react";
import { StripeCardElement } from "@stripe/stripe-js";
import Button from "components/UI-lib/Button";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { OrgContext } from "contexts/OrgContext";
import agent from "server";
import FlatCard from "components/UI-lib/FlatCard";
import Spinner from "components/UI-lib/Spinner";
import InputField from "components/UI-lib/InputField";
import { NotificationContext } from "contexts/NotificationContext";
import poof from "../../../../confetti";
import server from "server";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface FreeTierProps {
  billingProfileLoading: boolean;
}

function calc(num) {
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const Coupons: React.FC<FreeTierProps> = ({ billingProfileLoading }) => {
  const orgContext = useContext(OrgContext);
  const [redeemables, setRedeemables] = useState<Array<any>>([]);
  const [flipped, setFlipped] = React.useState(false);

  const { data: credits, isLoading: isCreditsLoading } = useQuery({
    queryKey: ["org-legacy-credits", orgContext.activeOrgId],
    queryFn: async () => {
      const res = await agent.Organizations.getCredits(orgContext.activeOrgId);
      if (!res.success) {
        throw new Error("Failed to fetch credits");
      }
      return Number(res.data?.balance_usd || 0);
    },
    enabled: !!orgContext.activeOrgId,
  });

  const fetchRedeemables = async () => {
    const redeemablesRes = await agent.Organizations.getRedeemedCredits(
      orgContext.activeOrgId
    );
    if (redeemablesRes.success) {
      setRedeemables(redeemablesRes.data);
    }
  };
  useEffect(() => {
    fetchRedeemables();
  }, [orgContext.activeOrgId]);

  const transformDate = (timestamp: string) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  return (
    <FlatCard className="mb-3 mt-3">
      <div className="flex flex-col justify-start items-start w-full">
        {billingProfileLoading || isCreditsLoading ? (
          <div className="flex flex-col justify-center items-center w-full">
            <div className="w-full">
              <FlatCard className="" isLoading={true}>
                <p className="text-sm text-gray-700 dark:text-secondary">
                  Fetching coupons or credits
                </p>
              </FlatCard>
            </div>
          </div>
        ) : (
          <>
            <div className="text-xl font-medium mb-3 dark:text-white">
              Coupons
            </div>
            <RedeemCode />
            <div className="flex flex-col w-full mt-3">
              <div className="flex flex-row">
                <h1 className="text-lg font-medium dark:text-secondary">
                  Coupon Balance:
                </h1>
                <h1 className="text-lg font-medium text-cyan-600 dark:text-cyan-300 ml-3">
                  {(credits || 0) * 100}
                </h1>
              </div>
              <hr className="mt-1 mb-1 dark:border dark:border-zinc-800" />
              <>
                <ul role="list" className="">
                  {redeemables.map((redeemable, redeemableItemIdx) => (
                    <li
                      key={redeemableItemIdx}
                      className="relative flex gap-x-4"
                    >
                      <div className="absolute left-0 top-0 flex justify-center" />
                      <>
                        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white dark:bg-zinc-900">
                          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 dark:bg-cyan-100 ring-1 ring-gray-300 dark:ring-cyan-100" />
                        </div>
                        <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 dark:text-secondary">
                          <span className="font-medium text-gray-900 dark:text-secondary">
                            {redeemable.user.name}
                          </span>{" "}
                          applied {redeemable.maxRedemptionAmountUsd * 100}{" "}
                          credits from code {redeemable.shortCode}
                        </p>
                        <div className="flex-none py-0.5 text-xs leading-5 text-gray-500 dark:text-secondary">
                          {transformDate(redeemable.createdTime)}
                        </div>
                      </>
                    </li>
                  ))}
                </ul>
              </>
            </div>
            <div className="flex flex-row justify-between items-center mt-2">
              <p className="text-gray-500 dark:text-secondary text-xs mt-1 ml-auto">
                *Each coupon credit is worth $0.01
              </p>
            </div>
          </>
        )}
      </div>
    </FlatCard>
  );
};

export const RedeemCode: React.FC = () => {
  const [code, setCode] = useState("");
  const { activeOrgId } = useContext(OrgContext);
  const { showNotification } = useContext(NotificationContext);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const queryClient = useQueryClient();

  const redeeemCode = async () => {
    setLoading(true);
    setErrorMessage("");
    const res = await server.Organizations.redeemCode(activeOrgId, code);
    if (res.success) {
      poof(window.innerWidth, window.innerHeight);
      queryClient.invalidateQueries(["org-credits", activeOrgId]);
      showNotification(
        `Redeemed code ${code}`,
        `Redeemed code ${code} for $${res.data?.transaction.amount_usd}`,
        "success"
      );
    } else {
      setErrorMessage(
        "Failed to Apply Code: " +
          `${
            res.message.split("rpc error: code = Internal desc =")[1] ||
            res.message
          }`
      );
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row">
          <InputField
            key={2}
            label="Enter Code"
            // helperText="Enter the full URL of the Github repo"
            value={code}
            errorMessage=""
            placeholder="Enter Code"
            onChange={setCode}
            className="mr-3"
            hideLabel
          />
          <Button
            label="Redeem"
            type="secondary"
            className=""
            loading={isLoading}
            disabled={isLoading}
            onClick={async () => redeeemCode()}
          />
        </div>
        {errorMessage && (
          <div className="flex-col mt-1">
            <span className="text-red-600 text-sm">{errorMessage}</span>
          </div>
        )}
      </div>
    </>
  );
};
