import React from "react";
import { motion } from "framer-motion";
import { memo } from "react";
import ReactMarkdown from "react-markdown";
import { SparklesIcon } from "@heroicons/react/24/solid";
import { Message } from "./types";
import nim from "../../assets/img/png/nim.png";

interface ChatMessageProps {
  message: Message;
  isLoading: boolean;
}

const PureChatMessage = ({ message, isLoading }: ChatMessageProps) => {
  return (
    <motion.div
      initial={{ y: 5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0, y: -5 }}
      className="w-full mx-auto max-w-3xl px-4 group/message"
      data-role={message.role}
    >
      <div
        className={`flex gap-4 w-full group-data-[role=user]/message:ml-auto group-data-[role=user]/message:max-w-2xl ${
          message.role === "user" ? "justify-end" : "justify-start"
        }`}
      >
        {message.role === "assistant" && (
          <div className="w-8 h-8 flex items-center rounded-full justify-center ring-1 shrink-0 ring-zinc-800 bg-white">
            <img src={nim} className="w-6 h-6 rounded-full" />
          </div>
        )}

        <div className="flex flex-col gap-2 w-full text-white">
          <div
            className={`flex flex-row gap-2 ${
              message.role === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`flex flex-col gap-4 ${
                message.role === "user"
                  ? "bg-white text-black px-3 py-2 rounded-xl"
                  : ""
              }`}
            >
              <ReactMarkdown
                className="whitespace-pre-wrap"
                components={{
                  p: ({ children }) => <p className="">{children}</p>,
                  pre: ({ children }) => (
                    <pre className="bg-zinc-800 p-4 rounded-lg my-2 overflow-x-auto">
                      {children}
                    </pre>
                  ),
                  code: ({ children }) => <code>{children}</code>,
                }}
              >
                {message.content as string}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const ChatMessage = memo(PureChatMessage);
