export interface Registry {
  username: string;
  password: string;
  url: string;
}

export interface CustomContainer {
  containerUrl: string;
  entryPoint: string;
  registry?: Registry;
}

export interface DockerCompose {
  fileUrl: string;
  yamlString?: string;
  jupyterInstall: boolean;
  environmentVariables?: { [key: string]: string };
}

export interface VMBuild {
  forceJupyterInstall: boolean;
}

export interface VerbBuild {
  containerUrl: string;
}

export interface ContainerSelected {
  verbBuild?: VerbBuild;
  customContainer?: CustomContainer;
  vmBuild?: VMBuild;
  dockerCompose?: DockerCompose;
}

export function isContainerEquals(
  a?: ContainerSelected,
  b?: ContainerSelected
): boolean {
  if (!a && !b) return true;
  if (!a || !b) return false;

  // Compare VerbBuild
  if (a.verbBuild !== b.verbBuild) {
    if (!a.verbBuild || !b.verbBuild) return false;
    if (a.verbBuild.containerUrl !== b.verbBuild.containerUrl) return false;
  }

  // Compare CustomContainer
  if (a.customContainer !== b.customContainer) {
    if (!a.customContainer || !b.customContainer) return false;
    if (a.customContainer.containerUrl !== b.customContainer.containerUrl)
      return false;
    if (a.customContainer.entryPoint !== b.customContainer.entryPoint)
      return false;

    // Compare Registry
    if (a.customContainer.registry !== b.customContainer.registry) {
      if (!a.customContainer.registry || !b.customContainer.registry)
        return false;
      if (
        a.customContainer.registry.username !==
        b.customContainer.registry.username
      )
        return false;
      if (
        a.customContainer.registry.password !==
        b.customContainer.registry.password
      )
        return false;
      if (a.customContainer.registry.url !== b.customContainer.registry.url)
        return false;
    }
  }

  // Compare VMBuild
  if (a.vmBuild !== b.vmBuild) {
    if (!a.vmBuild || !b.vmBuild) return false;
    if (a.vmBuild.forceJupyterInstall !== b.vmBuild.forceJupyterInstall)
      return false;
  }

  // Compare DockerCompose
  if (a.dockerCompose !== b.dockerCompose) {
    if (!a.dockerCompose || !b.dockerCompose) return false;
    if (a.dockerCompose.fileUrl !== b.dockerCompose.fileUrl) return false;
    if (a.dockerCompose.yamlString !== b.dockerCompose.yamlString) return false;
    if (a.dockerCompose.jupyterInstall !== b.dockerCompose.jupyterInstall)
      return false;

    // Compare environment variables
    const aEnvKeys = Object.keys(a.dockerCompose.environmentVariables || {});
    const bEnvKeys = Object.keys(b.dockerCompose.environmentVariables || {});
    if (aEnvKeys.length !== bEnvKeys.length) return false;

    for (const key of aEnvKeys) {
      if (
        a.dockerCompose.environmentVariables?.[key] !==
        b.dockerCompose.environmentVariables?.[key]
      ) {
        return false;
      }
    }
  }

  return true;
}

interface URLParserOptions {
  maxLength: number;
  ellipsis?: string;
}

/**
 * Parses a URL and shortens it if it exceeds the maximum length.
 * Shows the hostname and as many characters from the end as possible,
 * connecting them with an ellipsis.
 *
 * @param url - The URL to parse and potentially shorten
 * @param options - Configuration options for parsing
 * @returns The parsed and potentially shortened URL
 * @throws URLParsingError if the URL is invalid
 */
export function parseDockerComposeUrl(
  url: string,
  options?: URLParserOptions
): string {
  // Default options
  const defaultOptions: URLParserOptions = {
    maxLength: 40,
    ellipsis: "...",
  };

  const config = { ...defaultOptions, ...options };
  const { maxLength, ellipsis } = config;

  // Validate inputs
  if (maxLength < 1) {
    return "Error Max Length";
  }

  if (!url) {
    return "Error No URL";
  }

  // Return original URL if it's shorter than maxLength
  if (url.length <= maxLength) {
    return url;
  }

  try {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;

    // Calculate how many characters we can take from the end
    // accounting for hostname length and ellipsis
    const endCharsLength =
      maxLength - hostname.length - (ellipsis || "").length;

    if (endCharsLength < 1) {
      // If we can't fit enough end characters, just return hostname + ellipsis
      return `${hostname}${ellipsis}`;
    }

    // Get the path and parameters part of the URL
    const pathAndParams = url.substring(
      url.indexOf(hostname) + hostname.length
    );

    // Take characters from the end
    const endPart = pathAndParams.slice(-endCharsLength);

    return `${hostname}${ellipsis}${endPart}`;
  } catch (e) {
    // If URL parsing fails, do a simple string truncation
    return `${url.slice(0, maxLength - (ellipsis || "").length)}${ellipsis}`;
  }
}
