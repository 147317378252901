import React from "react";
import FlatCard from "components/UI-lib/FlatCard";
import { CubeIcon } from "@heroicons/react/24/outline";
import { validCudaVersions, validPythonVersions } from "components/Verb/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/Graphs/Select";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";

interface Image {
  Image: string;
  DisplayName: string;
  Description?: string;
}

interface ImageSectionProps {
  title: string;
  images: Image[];
  setContainer: (container: ContainerSelected) => void;
  iconColor: string;
}

const ImageSection: React.FC<ImageSectionProps> = ({
  title,
  images,
  setContainer,
  iconColor,
}) => (
  <>
    <h2 className="col-span-full text-lg font-semibold text-gray-900 dark:text-white mt-4 mb-2">
      {title}
    </h2>
    {images.map((image) => (
      <div
        key={image.Image}
        onClick={() =>
          setContainer({
            verbBuild: {
              containerUrl: image.Image,
            },
          })
        }
      >
        <div className="bg-secondaryBg p-4 w-full h-[90px] rounded-md border-2 border-dashed border-gray-300 dark:border-zinc-800 cursor-pointer hover:border-highlight dark:hover:border-highlight">
          <div className="flex items-start h-full">
            <CubeIcon className={`h-8 w-8 mr-4 flex-shrink-0 ${iconColor}`} />
            <div className="flex-grow min-w-0 overflow-hidden">
              <div className="text-md text-gray-900 dark:text-white font-medium truncate">
                {image.DisplayName}
              </div>
              <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
                {image.Image}
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

interface AllowedImageListSelectorProps {
  allowedImageList: Image[];
  setContainer: (container: ContainerSelected) => void;
  pythonVersion: string;
  setPythonVersion: (pythonVersion: string) => void;
  cudaVersion: string;
  setCudaVersion: (cudaVersion: string) => void;
}

const AllowedImageListSelector: React.FC<AllowedImageListSelectorProps> = ({
  allowedImageList,
  setContainer,
  setCudaVersion,
  setPythonVersion,
  pythonVersion,
  cudaVersion,
}) => {
  const categorizeImages = (images: Image[]) => {
    const categories: {
      mlFrameworks: Image[];
      nvidia: Image[];
    } = {
      mlFrameworks: [],
      nvidia: [],
    };

    images.forEach((image) => {
      if (
        image.Image.includes("pytorch") ||
        image.Image.includes("tensorflow")
      ) {
        categories.mlFrameworks.push(image);
      } else if (image.Image.includes("nvcr.io/nvidia")) {
        categories.nvidia.push(image);
      }
    });

    return categories;
  };
  const filteredList = allowedImageList.filter((val) => val.Image !== "None");
  const categories = categorizeImages(filteredList);

  return (
    <>
      <>
        <h2 className="col-span-full text-lg font-semibold text-gray-900 dark:text-white mt-4 mb-2">
          Brev
        </h2>
        <div key={"Default"}>
          <div
            className="flex w-fit"
            onClick={() =>
              setContainer({
                verbBuild: {
                  containerUrl: "Default",
                },
              })
            }
          >
            <div
              className={`bg-secondaryBg w-full p-4 h-[100px] rounded-md border-2 border-dashed border-gray-300 dark:border-zinc-800 cursor-pointer hover:border-highlight dark:hover:border-highlight`}
            >
              <div className="flex items-start h-full">
                <CubeIcon
                  className={`h-8 w-8 mr-4 flex-shrink-0 text-highlight`}
                />
                <div className="flex flex-col">
                  <div className="text-md text-gray-900 dark:text-white font-medium truncate">
                    Default
                  </div>
                  <div className="text-xs text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
                    This is our standard container which has Python and CUDA
                  </div>
                </div>
                <div className="flex ml-3">
                  <div className="flex flex-row text-white">
                    <div className="w-[100px] mr-3">
                      <div>
                        <div className="text-xs">Python Version</div>
                      </div>
                      <Select
                        value={pythonVersion}
                        onValueChange={(value) => {
                          setPythonVersion(value);
                        }}
                      >
                        <SelectTrigger id="size" className="mt-2">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {validPythonVersions.map((item) => (
                            <SelectItem key={item} value={item}>
                              {item}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="w-[100px]">
                      <div>
                        <div className="text-xs">Cuda Version</div>
                      </div>
                      <Select
                        value={cudaVersion}
                        onValueChange={(value) => {
                          setCudaVersion(value);
                        }}
                      >
                        <SelectTrigger id="size" className="mt-2">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {validCudaVersions.map((item) => (
                            <SelectItem key={item} value={item}>
                              {item}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-3">
        <ImageSection
          title="ML Frameworks"
          images={categories.mlFrameworks}
          setContainer={setContainer}
          iconColor="text-orange-500"
        />
        <ImageSection
          title="NVIDIA"
          images={categories.nvidia}
          setContainer={setContainer}
          iconColor="text-green-500"
        />
      </div>
    </>
  );
};

export default AllowedImageListSelector;
