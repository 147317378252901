import { cx, focusRing } from "components/Graphs/utils";
import { Route, Switch, useLocation } from "react-router-dom";
import React, { useContext } from "react";
import Sidebar from "./Sidebar";
import Overview from "./Overview";
import Users from "./Users";
import Organizations from "./Organizations";
import Instances from "./Instances";
import Launchables from "./Launchables";
import Credits from "./Credits";
import { UserContext } from "contexts/UserContext";
import Search from "./Search";
import User from "./Users/User";
import Instance from "./Instances/Instance";
import Organization from "./Organizations/Organization";

const Admin: React.FC = () => {
  const { pathname } = useLocation();
  const userContext = useContext(UserContext);

  const isAdmin = userContext.me?.platformRole === "admin";

  if (!isAdmin) {
    return (
      <div className="text-white mt-5">
        You are not authorized to access this page
      </div>
    );
  }

  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-1 sm:ml-72 lg:ml-72">
        <main className="h-full w-full pb-10">
          <div className="flex flex-col">
            <Search />
          </div>
          <div className="">
            <Switch>
              <Route exact path="/admin" component={Overview} />
              <Route exact path="/admin/users" component={Users} />
              <Route exact path="/admin/users/id/:userId" component={User} />
              <Route
                exact
                path="/admin/organizations"
                component={Organizations}
              />
              <Route
                exact
                path="/admin/organizations/id/:orgId"
                component={Organization}
              />
              <Route exact path="/admin/instances" component={Instances} />
              <Route
                exact
                path="/admin/instances/id/:instanceId"
                component={Instance}
              />
              <Route exact path="/admin/launchables" component={Launchables} />
              <Route exact path="/admin/credits" component={Credits} />
            </Switch>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Admin;
