import React, { useContext, useEffect, useMemo, useState } from "react";
import FilterCard from "./FilterCard";
import { CloudIcon } from "@heroicons/react/24/outline";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Graphs/Popover";
import {
  RadioCardGroup,
  RadioCardIndicator,
  RadioCardItem,
} from "components/Graphs/RadioCardGroup";
import { OrgContext } from "contexts/OrgContext";
import { ContainerSelected } from "components/Environment/shared/BuildTypes";
import { displayPlatformType } from "components/Environment/utils";

interface CloudFilterProps {
  selectedCloud: string;
  setSelectedCloud: (cloud: string) => void;
  selectedProvider: string;
  setSelectedProvider: (provider: string) => void;
  containerSelected: ContainerSelected;
}

const CloudFilter: React.FC<CloudFilterProps> = ({
  selectedCloud,
  setSelectedCloud,
  selectedProvider,
  setSelectedProvider,
  containerSelected,
}) => {
  const orgContext = useContext(OrgContext);

  const providerList = useMemo(() => {
    return orgContext.workspaceGroups
      .filter((workspaceGroups) => {
        return workspaceGroups.tenantType === "shared";
      })
      .map((group) => group.platformType);
  }, [orgContext.workspaceGroups]);

  const cloudList = useMemo(() => {
    return orgContext.workspaceGroups.filter((workspaceGroups) => {
      return workspaceGroups.tenantType === "isolated";
    });
  }, [orgContext.workspaceGroups]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="mr-2 ml-2 flex">
          <FilterCard
            icon={<CloudIcon className="w-6 h-6 text-white mr-1" />}
            className="min-w-[220px]"
            title="Cloud"
            titleClass="mt-[2px]"
            description={
              selectedCloud === "Brev Cloud"
                ? "Brev Cloud" + " - " + selectedProvider
                : displayPlatformType(
                    orgContext.workspaceGroups
                      .find((workspaceGroup) => {
                        return workspaceGroup.id === selectedCloud;
                      })
                      ?.platformType.toUpperCase() || ""
                  ) +
                  " | " +
                  selectedCloud
            }
            descriptionClass="mt-[3px]"
          />
        </div>
      </PopoverTrigger>
      <PopoverContent className="p-4">
        <div className="flex flex-col min-w-[500px] mb-4">
          <div className="flex flex-row text-sm font-medium text-gray-700 dark:text-white">
            <div className="flex flex-col mr-5">
              <h1 className="mb-4">Cloud</h1>
              <RadioCardGroup
                className="flex flex-row w-full grid grid-cols-1 gap-2 sm:grid-cols-3"
                value={selectedCloud}
                onValueChange={(value) => {
                  setSelectedCloud(value);
                }}
              >
                <RadioCardItem value="Brev Cloud">
                  <div className="flex items-center gap-3 w-max">
                    <RadioCardIndicator className="mt-1" />
                    <div className="flex flex-row relative">
                      <span className="text-xs text-white">Brev Cloud</span>
                    </div>
                  </div>
                </RadioCardItem>
                {cloudList.map((cloud) => (
                  <RadioCardItem value={cloud.id} key={cloud.id}>
                    <div className="flex items-center gap-3 w-max">
                      <RadioCardIndicator className="mt-1" />
                      <div className="flex flex-col relative">
                        <span className="text-xs text-white">
                          {displayPlatformType(
                            cloud.platformType.toUpperCase()
                          )}
                          {cloud.tenantType === "isolated"
                            ? ` | ${cloud.id}`
                            : ""}
                        </span>
                        <span className="text-xs text-white">
                          {cloud.tenantType === "isolated"
                            ? `(${cloud.name})`
                            : ""}
                        </span>
                      </div>
                    </div>
                  </RadioCardItem>
                ))}
              </RadioCardGroup>
            </div>
          </div>
          <div className="flex flex-col mt-5">
            <div className="flex flex-row">
              <h1 className="mb-4">Provider</h1>
              {selectedCloud !== "Brev Cloud" && (
                <div className="ml-2 text-xs text-gray-400 mt-[3px]">
                  (Disabled when selecting a private cloud)
                </div>
              )}
            </div>
            <div className="flex flex-col w-full">
              <RadioCardGroup
                className="flex flex-row w-full grid grid-cols-1 gap-2 sm:grid-cols-3"
                value={selectedProvider}
                onValueChange={(value) => {
                  setSelectedProvider(value);
                }}
                disabled={selectedCloud !== "Brev Cloud"}
              >
                <RadioCardItem value="any">
                  <div className="flex items-center gap-3">
                    <RadioCardIndicator className="mt-1" />
                    <div className="flex flex-row relative">
                      <span className="text-xs text-white">Any</span>
                    </div>
                  </div>
                </RadioCardItem>
                {providerList.map((provider) => (
                  <RadioCardItem
                    key={provider}
                    value={provider}
                    disabled={
                      (containerSelected?.vmBuild ||
                        containerSelected.customContainer) &&
                      provider === "akash"
                        ? true
                        : false
                    }
                  >
                    <div className="flex items-center gap-3">
                      <RadioCardIndicator className="mt-1" />
                      <div className="flex flex-col relative">
                        <span className="text-xs text-white">
                          {provider.toUpperCase()}{" "}
                        </span>
                        <span className="text-[10px] text-white">
                          {(containerSelected?.vmBuild ||
                            containerSelected.customContainer) &&
                          provider === "akash"
                            ? "(Unavailable for custom containers and vm mode)"
                            : ""}
                        </span>
                      </div>
                    </div>
                  </RadioCardItem>
                ))}
              </RadioCardGroup>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default CloudFilter;
