import React from "react";
import { useHistory } from "react-router";
import { Spinner } from "@kui-react/spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRoot,
  TableRow,
} from "components/Graphs/Table";
import IWorkspace from "models/Workspace.model";

interface Workspace {
  id: string;
  name: string;
  instanceType: string;
  baseImage?: string;
  customContainer?: {
    containerUrl: string;
  };
  vmOnlyMode?: boolean;
  status: string;
  verbBuildStatus: string;
}

interface WorkspacesTableProps {
  workspaces?: IWorkspace[];
  isLoading: boolean;
}

const WorkspacesTable: React.FC<WorkspacesTableProps> = ({
  workspaces,
  isLoading,
}) => {
  const history = useHistory();

  return (
    <TableRoot>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Compute</TableHeaderCell>
            <TableHeaderCell>Container</TableHeaderCell>
            <TableHeaderCell>Compute Status</TableHeaderCell>
            <TableHeaderCell>Container Status</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Spinner />
              </TableCell>
            </TableRow>
          ) : !workspaces?.length ? (
            <TableRow>
              <TableCell colSpan={6}>
                <p className="text-sm text-gray-500 dark:text-secondary text-center">
                  No workspaces found
                </p>
              </TableCell>
            </TableRow>
          ) : (
            workspaces.map((instance, index) => (
              <TableRow
                key={index}
                className="cursor-pointer hover:bg-zinc-800"
                onClick={() => {
                  history.push(`/admin/instances/id/${instance.id}`);
                }}
              >
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {instance.id}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {instance.name}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {instance.instanceType}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {instance.baseImage ||
                      instance.customContainer?.containerUrl ||
                      (instance.vmOnlyMode ? "VM" : "Verb")}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {instance.status}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="text-sm text-gray-500 dark:text-secondary">
                    {instance.verbBuildStatus}
                  </span>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableRoot>
  );
};

export default WorkspacesTable;
